import React, { useEffect, useState } from "react"
import { Link, navigate } from "gatsby"

import { AppBar, Toolbar, Button, Grid, Menu, MenuItem } from '@material-ui/core'
import headerStyle from "./headerStyle.jsx"
import withStyles from "@material-ui/core/styles/withStyles"
import useScrollTrigger from '@material-ui/core/useScrollTrigger'
import PropTypes from 'prop-types';

import logo from "../../images/svg/logo_abitareco.svg"
import whiteLogo from "../../images/svg/logo_abitareco_bianco.svg"

import copy from '../../constants/copy.json'
import MenuIcon from '../../images/icons/MenuIcon'
import {RENT_WEBSITE_URL} from '../../constants/links'
import {

  haylardDisplay,
} from "../../style/style.jsx"
const Header = ({ home, classes, staticPage }) => {

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 100
  });

  const [pathName, setPathName] = useState('');

  useEffect(() => {
    setPathName(window.location.pathname);
  }, []);

  const [anchorEl, setAnchorEl] = React.useState(null);
  
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleContactClick = () => {
    navigate('/contatti-uffici')
  }


  const [anchorElProjects, setAnchorElProjects] = React.useState(null);
  const open = Boolean(anchorElProjects);

  const handleClickProjects = (event) => {
    setAnchorElProjects(event.currentTarget);
  };

  const handleCloseProjects = () => {
    setAnchorElProjects(null);
  };

  return (
    <AppBar classes={{ root: home && !trigger ? classes.appBarStyle : classes.appBarrStyleScroll }} position="fixed">
      <Toolbar>
        <Grid container item xs={12} alignItems='center' justify='space-between'>
          <Grid container item xs={6} md={2} justify="flex-start">
            <Link to="/" className={classes.linkStyle}>
              <img className={classes.logo} src={home && !trigger ? whiteLogo : logo} alt="" />
            </Link>
          </Grid>
          {!staticPage &&
            <Grid container item xs={8} alignContent="center" justify="center" className={classes.headerUppercase + ' ' + classes.mobileHidden}>
              <Grid container item xs={3} justify="center" alignContent="center">
             
              <Button
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClickProjects}
                style={{borderRadius: 0, minHeight: 0, minWidth: 0, padding: 0, letterSpacing: '2px !important', display: 'flex', height: '100%', backgroundColor: 'transparent'}}
                className={`${classes.linkStyle} ${classes.headerUppercase} ${home && !trigger ? classes.linkStyleBorderWhite : classes.linkStyleBorder} ${pathName.includes("/nuove-costruzioni") ? classes.linkSelected : ''}`}
              >
                {copy.home.header.header1}
              </Button>

              <Menu
                id="basic-menu"
                anchorEl={anchorElProjects}
                open={open}
                onClose={handleCloseProjects}
                s
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
                style={{marginTop: '30px'}}
                
              >
                <MenuItem onClick={() => {navigate(`/nuove-costruzioni-milano`);}} className={`${classes.linkStyle} ${home && !trigger ? classes.linkStyleBorder : classes.linkStyleBorder} ${pathName.includes("/nuove-costruzioni") ? classes.linkSelected : ''}`}>{copy.home.header.header1sales}</MenuItem>
                <MenuItem onClick={() => {window.open(RENT_WEBSITE_URL).focus();}} className={`${classes.linkStyle} ${home && !trigger ? classes.linkStyleBorder : classes.linkStyleBorder}}`}>{copy.home.header.header1rent}</MenuItem>

              </Menu>

              </Grid>
              <Grid container item xs={3} justify="center" style={{ cursor: 'pointer' }}>
                <Link to="/real-estate-milano" className={`${classes.linkStyle} ${home && !trigger ? classes.linkStyleBorderWhite : classes.linkStyleBorder} ${(pathName.includes("/scopri-abitare-co") || pathName.includes("/real-estate-milano")) ? classes.linkSelected : ''}`}>{copy.home.header.header2}</Link>
              </Grid>
              <Grid container item xs={3} justify="center" style={{ cursor: 'pointer' }}>
                <Link to="/news" className={`${classes.linkStyle} ${home && !trigger ? classes.linkStyleBorderWhite : classes.linkStyleBorder} ${pathName.includes("/news") ? classes.linkSelected : ''}`}>{copy.home.header.header3}</Link>
              </Grid>
            </Grid>}
          {!staticPage &&
            <Grid container item xs={2} justify="flex-end" alignItems="center" className={classes.mobileHidden}>
              <Button classes={{ root: classes.greyButton, focusVisible: classes.greyButtonHover }} onClick={handleContactClick}>
                {copy.home.header.contact}
              </Button>
            </Grid>}
          {!staticPage &&
            <Grid className={classes.mobileShow}>
              <Grid container direction='column'>
                <Grid container item>
                  <Button aria-controls="menu" aria-haspopup="true" onClick={handleClick} style={{ height: '40px', padding: '0px' }}>
                    <MenuIcon color={home && !trigger ? 'white' : 'black'} />
                  </Button>
                </Grid>
                <Grid item className={`${classes.headerUppercase} ${classes.menu} ${(!home || trigger) && classes.blackTitle}`}>
                  Menù
              </Grid>
                <Menu
                  id="menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                  classes={{
                    paper: classes.menuStyle
                  }}
                >
                  <MenuItem onClick={handleClose} classes={{ root: classes.headerUppercase + ' ' + classes.noSpacing }}>
                    <Link to="/nuove-costruzioni-milano" className={`${classes.linkStyle}`}>PROGETTI - {copy.home.header.header1sales}</Link>
                  </MenuItem>
                  <MenuItem onClick={() => {window.open(RENT_WEBSITE_URL, '_blank').focus();}} classes={{ root: classes.headerUppercase + ' ' + classes.noSpacing }}>
                    <Link className={`${classes.linkStyle}`}>PROGETTI - {copy.home.header.header1rent}</Link>
                  </MenuItem>
                  <MenuItem onClick={handleClose} classes={{ root: classes.headerUppercase + ' ' + classes.noSpacing }}>
                    <Link to="/real-estate-milano" className={`${classes.linkStyle}`}>{copy.home.header.header2}</Link>
                  </MenuItem>
                  <MenuItem onClick={handleClose} classes={{ root: classes.headerUppercase + ' ' + classes.noSpacing }}>
                    <Link to="/news" className={`${classes.linkStyle}`}>{copy.home.header.header3}</Link>
                  </MenuItem>
                  <MenuItem onClick={handleContactClick} classes={{ root: classes.headerUppercase + ' ' + classes.noSpacing }}>
                    <Grid className={`${classes.linkStyle}`}>{copy.home.header.contact}</Grid>
                  </MenuItem>
                </Menu>
              </Grid>
            </Grid>}
        </Grid>
      </Toolbar>
    </AppBar>
  );
}

Header.propTypes = {
  home: PropTypes.bool
};

Header.defaultProps = {
  home: false
};

export default withStyles(headerStyle)(Header)
