import homeStyle from '../Home/homeStyle';
import colors from "../../style/colors.scss";

const CarouselStyle = theme => ({
    ...homeStyle(theme),
    slider: {
        width: '100%',
        height: '100%',
        display: 'flex',
        border: 'none',
        outline: 'none',
        marginTop: '30px',
        marginBottom: '30px',
        [theme.breakpoints.down('sm')] : {
            height: '720px'
        }
    },
    sliderGallery: {
        width: '100%',
        height: '100%',
        display: 'flex',
        border: 'none',
        outline: 'none',
        '& > .slick-dots': {
            bottom: '10px'
        },
        '& > .slick-dots li':{
            margin: '0px'
        }
    },
    sliderGalleryFixedWidth: {
        height: '650px',
        [theme.breakpoints.down('md')] : {
            height: '500px',
        },
        [theme.breakpoints.down('sm')] : {
            height: '400px',
        }
    },
    sliderPlan: {
        margin: 'auto',
        width: '60%',
        height: '100%',
        display: 'flex',
        border: 'none',
        outline: 'none',
        [theme.breakpoints.down('sm')] : {
            width: '100%',

            '& > .slick-dots': {
                bottom: '-45px',

                '& > li':{

                    '& > div': {
                        border: `1px solid ${colors.darkGrey}`,
                        boxShadow: 'none',
                    },

                    '&.slick-active > div':{
                        background: colors.darkGrey
                    }
                }
            },
        }
    }, 
    sliderFullInfo: {
        height: '650px',
        [theme.breakpoints.down('md')] : {
            height: '500px',
        },

        [theme.breakpoints.down('sm')] : {
            height: '100%',
            maxHeight: '630px',

            '& > .slick-dots': {
                bottom: '-50px'
            },
        }
    },
    customDots: {
        width: "10px",
        height: "10px",
        border: "1px solid",
        borderColor: colors.darkGrey,
        margin: "5px"
    },
    activeDots:{
        width: "10px",
        height: "10px",
        border: "1px solid",
        borderColor: colors.darkGrey,
        margin: "5px",
        background: colors.darkGrey
    },   
    sliderContent: {
        paddingLeft: '30px',
        paddingRight: '30px',
        paddingBottom: '30px',
        border: 'none',
        outline: 'none'
    },
    sliderSingleContent: {
        '& .slick-list': {
            width: '100%',
        },
        '& .slick-track': {
            display: 'flex',
            justifyContent: 'center'
        }
    }
});

export default CarouselStyle;