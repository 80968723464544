import React, { useEffect } from "react"
import privacyStyle from "./privacyStyle.jsx"
import withStyles from "@material-ui/core/styles/withStyles"
import Header from "../Header/Header"
import Footer from '../Footer/Footer'
import Contact from '../Contact/Contact'
import { Grid } from '@material-ui/core'


const Condizioni = ({ classes }) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      window.scrollTo(0, 0)
    }, 500)
    return () => clearTimeout(timer)
  }, [])
  return (
    <div style={{ height: "100%" }}>
      <Header staticPage={true} />
      <Grid container justify="center" className={classes.privacyContainer}>
        <Grid
          container
          item
          xs={12}
          sm={10}
          md={6}
          direction="column"
          className={classes.privacySection}
        >
          <Grid className={classes.title}>Condizioni d'uso</Grid>
          <Grid className={classes.subTitle}>In sintesi</Grid>
          <Grid className={classes.description}>
          Questo sito e' di proprieta' di Abitare Co. Srl, di seguito "Societa'", con sede legale Presso Via Monte Napoleone N. 8 - 20121, Milano.
          <br/><br/>
          Tutte le pagine riassumono in modo conciso numerose informazioni e riportano molte immagini rappresentative. Ragion per cui, onde evitare un'errata comprensione dei contenuti si rimanda a mirati approfondimenti con un responsabile dedicato.
          </Grid>
          <Grid className={classes.subTitle}>
            Approfondimenti
          </Grid>
          <Grid className={classes.subTitle}>Approfondimenti</Grid>
          <Grid className={classes.description}>
            Si consiglia di leggere attentamente quanto di seguito riportato se
            si desidera accedere al sito web stesso, poiche' la navigazione
            comporta l'accettazione di tutte le condizioni elencate:
            <br />
            <br />- I servizi offerti dal sito web sono accessibili in generale
            senza registrazione degli Utenti. Ove richiesta la registrazione
            verra' opportunamente segnalato.
            <br />- La SOCIETA' e' la legittima titolare del sito web, delle
            pagine che comprende, le informazioni, disegni, grafica, database,
            loghi, marchi, nomi commerciali o altri segni protetti da diritti di
            proprieta' intellettuale o industriale.
            <br />- La SOCIETA' non garantisce la continuita' di accesso ne' la
            corretta visualizzazione e il download di contenuti che possono
            essere ostacolati e/o interrotti da fattori al di fuori del loro
            controllo.
            <br />- La SOCIETA' non e' responsabile per le informazioni e i
            contenuti di siti di terzi accessibili dal presente sito web.
            L'utente potra' comunque segnalare eventuali link illegali e/o
            sospetti inviando una segnalazione contattandoci via email.
            <br />- La SOCIETA' si riserva il diritto di apportare modifiche che
            ritiene opportune a contenuti e servizi presenti nel sito web in
            qualunque momento.
            <br />- Il presente sito web e' soggetto a Copyright e tutti i
            diritti sono riservati. E' vietata la riproduzione, anche parziale,
            di testi ed immagini, senza autorizzazione.
            <br />- I dati personali degli utenti saranno trattati in
            conformita' alla normativa vigente. Per ulteriori informazioni, vi
            preghiamo di prendere visione del regolamento nell'apposita sezione
            sulla Privacy.
          </Grid>
          <Grid className={classes.subTitle}>
            NOTE SPECIFICHE SUI CONTENUTI, TESTI E IMMAGINI
          </Grid>
          <Grid className={classes.description}>
            Tutta la documentazione tecnica e grafica presente sul sito web,
            modelli 3D, proposte di recupero e di arredo, sono puramente
            indicative e rappresentano esclusivamente una base illustrativa, non
            costituiscono quindi elemento contrattuale. Una parte delle immagini
            riportate nel sito web e' sviluppata con tecniche di computer
            grafica (rendering fotorealistico), con riferimento a particolari di
            facciata e prospetti, parti comuni, parti private ed interni degli
            appartamenti, al solo fine di prospettare un'ipotesi di futura
            realizzazione e/o potenzialita' delle diverse soluzioni a seguito di
            personalizzazione degli utenti. Ragion per cui tutte le
            informazioni, descrizioni e immagini sono puramente suggestive e
            rappresentative, quindi soggette a variazioni in corso d'opera.
          </Grid>
        </Grid>
        <Contact />
      </Grid>
      <Footer />
    </div>
  )
}

export default withStyles(privacyStyle)(Condizioni)
