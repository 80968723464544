import React, { useState } from "react"
import { Grid } from "@material-ui/core"

import contactPageStyle from "./contactPageStyle.jsx"
import Header from "../Header/Header"
import withStyles from "@material-ui/core/styles/withStyles"
import Contact from "../Contact/Contact"
import Footer from "../Footer/Footer"
import ContactCard from "./ContactCard.js"
import copy from "../../constants/copy.json"
import FindUsCard from "./FindUsCard.js"

const ContactPage = ({ classes }) => {
  const [zoomOnMarker, setZoomOnMarker] = useState(() => () => {})
  
  return (
    <div style={{ height: "100%", width: "100%" }}>
      <Header home={false} />
      <Grid
        container
        direction="row"
        justify="center"
        alignContent="center"
        className={classes.container}
      >
        <Grid
          item
          xs={12}
          md={6}
          align="center"
          className={classes.contactCardContainer}
        >
          <ContactCard content={copy.contattiUffici.contactCard} zoomOnMarker={zoomOnMarker} />
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          align="center"
          className={classes.findUsCardContainer}
        >
         <FindUsCard content={copy.contattiUffici.findUsCard} setZoomOnMarker={setZoomOnMarker}/>
        </Grid>
      </Grid>
      <Contact classes={{ contactContainer: classes.contactContainer }} />
      <Footer />
    </div>
  )
}

export default withStyles(contactPageStyle)(ContactPage)
