import zone1 from "../images/cercamappa_milano.jpg"
import zone2 from "../images/cercamappa_hinterland.jpg"
import zone3 from "../images/cercamappa_italia.jpg"

import copy from './copy.json'

const zoneCards = [
    {
        id: 0,
        zoneCode: "milano",
        zoneUrl: "milano",
        zone: zone1,
        description: copy.home.cercaCasa.milano.title,
        cta: copy.home.cercaCasa.milano.cta
    },
    {
        id: 1,
        zoneCode: "hinterland",
        zoneUrl: "milano-hinterland",
        zone: zone2,
        description: copy.home.cercaCasa.hinterland.title,
        cta: copy.home.cercaCasa.hinterland.cta
    },
    {
        id: 2,
        zoneCode: "italia",
        zoneUrl: "fuori-milano",
        zone: zone3,
        description: copy.home.cercaCasa.italia.title,
        cta: copy.home.cercaCasa.italia.cta
    }
];

export {
    zoneCards
};