import homeStyle from '../Home/homeStyle';  

import {   
    italicSubTitle,
    title,
    haylardDisplay,
    garamond
  } from "../../style/style.jsx";
  
import colors from "../../style/colors.scss";


const footerStyle = theme => ({
    ...homeStyle(theme),
    footerContainer: {
        backgroundColor: colors.darkGrey,
        padding: '80px 150px',
        color: 'white',
        [theme.breakpoints.down('md')] : {
            padding: '80px 20px',
        },
        [theme.breakpoints.down('sm')] : {
            padding: '50px 20px',
        }
    },
    formContainer: {
        [theme.breakpoints.up('md')] : {
            padding: '0 8%'
        }, 
        '@media (min-width: 1600px)':{
            padding: '0 15%'
        }
    },
    linkSection: {
        borderBottom: '2px solid white',
        paddingBottom: '30px',
        marginBottom: '30px'
    },
    linkSectionMobile: {
        borderBottom: '2px solid white',
        paddingBottom: '30px'
    },
    checkBoxLabel: {
        ...haylardDisplay,
        fontSize: '10px',
        lineHeight: '1.8',
        marginTop: '5px',
        paddingLeft: '10px',
        color: 'white !important',
        [theme.breakpoints.down('sm')] : {
            textAlign: 'initial',
            paddingLeft: '5px',
        }
    },
    inputPropsRoot:{
        ...garamond,
        background: 'white',               
        fontSize: '20px',
        borderRadius: '0px',
        [theme.breakpoints.down('sm')] : {
            fontSize: '16px',
        },
        [theme.breakpoints.up('md')] : {
            '& > input': {
                padding: '12px 15px'
            },
        }
    },
    subTitleFooter: {
        ...italicSubTitle,
        fontSize : '18px',
        [theme.breakpoints.down('sm')] : {
            fontSize: '16px',
        }
    },
    checkedIconLight: {
        border: '2px solid',
        borderColor: colors.lightGrey4,
        background: colors.lightGrey4,
        width: '20px', 
        height: '20px'
    },
    iconLight: {
        border: '2px solid',
        borderColor: colors.lightGrey4,
        width: '20px', 
        height: '20px'
    },
    footerCompanyData: {
        ...italicSubTitle,
        fontSize: '14px',
        fontWeight: 'bold',
        textAlign: 'center',
        marginTop: '60px',
        [theme.breakpoints.down('sm')] : {
            fontSize: '12px',
        }
    },
    instagramContainer: {
        padding: '0px 50px 50px 50px',
        [theme.breakpoints.down('sm')] : {
            padding: '10px',
        },
        '& > div > instagram-widget::part(content-fetch-error)' : {
            display: 'none',
        }
    },
    titleIg: {
        ...title,
        fontWeight: 'normal',
        fontSize: '14px'
    },
    title : {
        ...title,
        margin: '5px',
        textAlign: 'center',
        [theme.breakpoints.down('sm')] : {
            fontSize: '16px'
        }
    },
    mobileMargin: {
        [theme.breakpoints.down('sm')] : {
            marginBottom: '45px',
        }
    },
    socialIconWithHover: {
        width:'40px', height: '40px', margin: '10px', cursor: 'pointer', color: 'white',
        '&:hover': {
            color: 'black'
        }
    },
    facebookHover: {
        '&:hover': {
            color: '#4267B2'
        }
    },
    instagramHover: {
        '&:hover': {
            color: '#F00075'
        }
    },
    linkedinHover: {
        '&:hover': {
            color: '#0077B7'
        }
    },
    youtubeHover: {
        '&:hover': {
            color: '#FF0000'
        }
    }
});

export default footerStyle;