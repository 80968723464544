import React from "react"
import Grid from '@material-ui/core/Grid'
import homeStyle from "./homeStyle"
import withStyles from "@material-ui/core/styles/withStyles"

import copy from '../../constants/copy.json'

const Introduction = ({classes}) => {

  return (
    <Grid className={classes.introductionContainer}>
      <Grid container item style={{height: '100%', alignContent: 'flex-end'}}>
        <Grid container item xs={12} md={8} lg={6} alignItems="center" direction="column" justify="center" className={classes.introduction}>
          <Grid item className={classes.subTitle}>
            <div dangerouslySetInnerHTML={{__html: copy.home.introduction.subTitle}}/>
          </Grid>
          <Grid container item className={classes.title + ' ' +  classes.titleMaxWidth}>
            <div dangerouslySetInnerHTML={{__html: copy.home.introduction.title}}/>
          </Grid>
          <Grid className={classes.border100}/>
          <Grid className={classes.description}>
            <div dangerouslySetInnerHTML={{__html: copy.home.introduction.description}}/>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default withStyles(homeStyle)(Introduction)
