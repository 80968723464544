import {
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from "@material-ui/core"
import Grid from "@material-ui/core/Grid"
import withStyles from "@material-ui/core/styles/withStyles"
import React, { useEffect, useState } from "react"
import copy from "../../constants/copy.json"
import {
  advSource,
  appartment,
  requestInfo
} from "../../constants/endpoints.jsx"
import { subjectOptions } from "../../constants/subjectOptions"
import CheckboxIcon from "../../images/icons/Checkbox"
import logo from "../../images/svg/logo_abitareco.svg"
import homeStyle from "../Home/homeStyle"
import Loader from "../Loader/Loader"
import { PrivacyPdf } from "../../constants/links"

const Contact = ({ classes, residenza }) => {
  const [source, setSource] = useState([])
  const [appartmentType, setAppartmentType] = useState([])
  const [loading, setLoading] = useState(false)

  const UrlParametersToSend = {}

  useEffect(() => {
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    const entries = urlParams.entries()
    for (const entry of entries) {
      UrlParametersToSend[entry[0]] = entry[1]
    }
  }, [UrlParametersToSend])

  useEffect(() => {
    if (residenza) {
      fetch(advSource, {
        method: "get",
        headers: {
          Accept: "*/*",
          "Content-Type": "application/json",
        },
      })
        .then(results => {
          return results.json()
        })
        .then(response => {
          var responseCode = response.statusCode
          if (responseCode !== 200 && responseCode !== 401) {
            console.error(
              "Error calling " +
              advSource +
              ": " +
              response.error.type +
              "\n\n" +
              response.error.description
            )
          } else {
            setSource(response.data)
          }
        })

      fetch(appartment, {
        method: "get",
        headers: {
          Accept: "*/*",
          "Content-Type": "application/json",
        },
      })
        .then(results => {
          return results.json()
        })
        .then(response => {
          var responseCode = response.statusCode
          if (responseCode !== 200 && responseCode !== 401) {
            console.error(
              "Error calling " +
              appartment +
              ": " +
              response.error.type +
              "\n\n" +
              response.error.description
            )
          } else {
            setAppartmentType(response.data)
          }
        })
    }
  }, [residenza])

  const [error, setError] = useState(false)
  const [success, setSuccess] = useState(false)

  const [data, setData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    consent1: false,
    consent2: false,
    consent4: false,
    subject: "",
    body: "",
    advertisingSource: "",
    city: "",
  })


  const handleChangeData = (param, e) => {
    if (param === "consent1" || param === "consent2" || param === "consent4") {
      setData({ ...data, [param]: e.target.checked })
    } else {
      setData({ ...data, [param]: e.target.value })
    }
  }

  const handleSubmit = event => {
    event.preventDefault()
    setError(false)
    setSuccess(false)
    setLoading(true)

    const selectedAptType = appartmentType.find((type) => type.code === data.subject);

    const body = {
      lead: {
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        phone: data.phone,
        consent1: data.consent1,
        consent2: data.consent2,
        consent4: data.consent4,
        advertisingSource: data.advertisingSource,
        city: data.city,
        constructionSiteId: residenza?.acfResidenza?.buildingId,
        projectId: residenza?.acfResidenza?.projectId,
        numberOfRoom: residenza ? data.subject : "1",
      },
      constructionSite: residenza?.acfResidenza?.name,
      source: "www.abitareco.it",
      subject: selectedAptType
        ? selectedAptType.name
        : data.subject,
      body: data.body,
    }

    if (UrlParametersToSend) {
      body["utm"] = UrlParametersToSend
    }

    fetch(requestInfo, {
      method: "post",
      headers: {
        Accept: "*/*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then(results => {
        return results.json()
      })
      .then(response => {
        var responseCode = response.statusCode
        if (responseCode !== 200 && responseCode !== 401) {
          console.error(
            "Error calling " +
            requestInfo +
            ": " +
            response.error.type +
            "\n\n" +
            response.error.description
          )
          setError(true)
          setLoading(false)
        } else {
          const emptyData = {
            firstName: null,
            lastName: null,
            email: null,
            phone: null,
            consent1: false,
            consent2: false,
            consent4: false,
            subject: null,
            body: null,
            advertisingSource: null,
            city: null,
          }
          setData(emptyData)
          setSuccess(true)
          setLoading(false)
        }
      })
  }

  return (
    <Grid
      container
      direction="column"
      justify="center"
      alignItems="center"
      className={classes.contactContainer}
    >
      <Grid
        item
        justify="center"
        align="center"
        className={classes.logoContainer}
      >
        <img
          className={classes.logo}
          src={
            residenza && residenza.acfResidenza.logo
              ? residenza.acfResidenza.logo.sourceUrl
              : logo
          }
          alt=""
        />
      </Grid>
      <Grid item className={classes.subTitle + " " + classes.titleDark}>
        <div
          dangerouslySetInnerHTML={{
            __html: residenza ? copy.home.form.titleProj : copy.home.form.title,
          }}
        />
      </Grid>
      {loading && <Loader color="dark" />}
      {error && !loading && (
        <Grid item className={classes.subTitle + " " + classes.error}>
          {copy.home.form.error}
        </Grid>
      )}
      {success && !loading && (
        <Grid
          container
          className={classes.subTitle + " " + classes.titleDark}
          direction="row"
          justify="center"
        >
          <Grid item>
            <CheckboxIcon dimension="30px" color="#353535" />
          </Grid>
          <Grid
            item
            style={{
              alignSelf: "flex-end",
              fontSize: "30px",
              fontWeight: "bold",
            }}
          >
            {copy.home.form.success}
          </Grid>
        </Grid>
      )}
      {!loading && (
        <form onSubmit={handleSubmit} className={classes.contactForm}>
          <Grid container>
            <FormControl classes={{ root: classes.formControlRoot }}>
              <Grid container direction="row">
                <Grid
                  container
                  item
                  xs={12}
                  sm={6}
                  direction="column"
                  className={classes.contactSection1}
                >
                  <TextField
                    label={copy.home.form.nome}
                    onChange={e => handleChangeData("firstName", e)}
                    value={data.firstName ? data.firstName : ""}
                    required
                    classes={{ root: classes.textFieldRoot }}
                    InputLabelProps={{
                      classes: {
                        root: classes.inputContactPropsRoot,
                      },
                    }}
                    InputProps={{
                      classes: {
                        root: classes.inputContactPropsRoot,
                        underline: classes.contactTextFieldFocused,
                        input: classes.textFieldInputStyle,
                      },
                    }}
                  />
                  <TextField
                    label={copy.home.form.cognome}
                    onChange={e => handleChangeData("lastName", e)}
                    value={data.lastName ? data.lastName : ""}
                    required
                    classes={{ root: classes.textFieldRoot }}
                    InputLabelProps={{
                      classes: {
                        root: classes.inputContactPropsRoot,
                      },
                    }}
                    InputProps={{
                      classes: {
                        root: classes.inputContactPropsRoot,
                        underline: classes.contactTextFieldFocused,
                        input: classes.textFieldInputStyle,
                      },
                    }}
                  />
                  <TextField
                    label={copy.home.form.email}
                    type="email"
                    onChange={e => handleChangeData("email", e)}
                    value={data.email ? data.email : ""}
                    required
                    classes={{ root: classes.textFieldRoot }}
                    InputLabelProps={{
                      classes: {
                        root: classes.inputContactPropsRoot,
                      },
                    }}
                    InputProps={{
                      classes: {
                        root: classes.inputContactPropsRoot,
                        underline: classes.contactTextFieldFocused,
                        input: classes.textFieldInputStyle,
                      },
                    }}
                  />
                  <TextField
                    label={copy.home.form.tel}
                    value={data.phone ? data.phone : ""}
                    type="tel"
                    onChange={e => handleChangeData("phone", e)}
                    required
                    classes={{ root: classes.textFieldRoot }}
                    InputLabelProps={{
                      classes: {
                        root: classes.inputContactPropsRoot,
                      },
                    }}
                    inputProps={{
                      pattern: "^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-/0-9]*$",
                    }}
                    InputProps={{
                      classes: {
                        root: classes.inputContactPropsRoot,
                        underline: classes.contactTextFieldFocused,
                        input: classes.textFieldInputStyle,
                      },
                    }}
                  />
                  {residenza && (
                    <TextField
                      label={copy.home.form.comune}
                      onChange={e => handleChangeData("city", e)}
                      value={data.city ? data.city : ""}
                      required
                      classes={{ root: classes.textFieldRoot }}
                      InputLabelProps={{
                        classes: {
                          root: classes.inputContactPropsRoot,
                        },
                      }}
                      InputProps={{
                        classes: {
                          root: classes.inputContactPropsRoot,
                          underline: classes.contactTextFieldFocused,
                          input: classes.textFieldInputStyle,
                        },
                      }}
                    />
                  )}
                </Grid>
                <Grid
                  container
                  item
                  xs={12}
                  sm={6}
                  direction="column"
                  className={classes.contactSection2}
                >
                  {
                    residenza ? (
                      <FormControl required>
                        <InputLabel
                          id="subjectLabel"
                          classes={{
                            root: classes.inputContactPropsRoot,
                            formControl: classes.formControl,
                          }}
                        >
                          {copy.home.form.interested}
                        </InputLabel>
                        <Select
                          id="subject"
                          key="subject"
                          name="subject"
                          labelId="subjectLabel"
                          onChange={e => handleChangeData("subject", e)}
                          value={data.subject ? data.subject : ""}
                          required
                          className={
                            classes.select + " " + classes.underlinedInput
                          }
                          inputProps={{
                            classes: {
                              root: classes.inputContactPropsRoot,
                            },
                          }}
                        >
                          {appartmentType &&
                            appartmentType.map((item, idx) => (
                              <MenuItem
                                key={idx}
                                className={classes.inputContactPropsRoot}
                                value={item.code}
                              >
                                {item.name}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    ) : (
                      <FormControl required>
                        <InputLabel
                          id="subjectLabel"
                          classes={{
                            root: classes.inputContactPropsRoot,
                            formControl: classes.formControl,
                          }}
                        >
                          {copy.home.form.oggetto}
                        </InputLabel>
                        <Select
                          id="subject"
                          key="subject"
                          name="subject"
                          labelId="subjectLabel"
                          onChange={e => handleChangeData("subject", e)}
                          value={data.subject ? data.subject : ""}
                          required
                          className={
                            classes.select + " " + classes.underlinedInput
                          }
                          inputProps={{
                            classes: {
                              root: classes.inputContactPropsRoot,
                            },
                          }}
                        >
                          {subjectOptions &&
                            subjectOptions.map((item, idx) => (
                              <MenuItem
                                key={idx}
                                className={classes.inputContactPropsRoot}
                                value={item.code}
                              >
                                {item.name}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    )
                    /*<TextField
                      label={copy.home.form.oggetto}
                      onChange={(e) => handleChangeData('subject', e)}
                      value={data.subject ? data.subject : ''}
                      required
                      classes={{root: classes.textFieldRoot}}
                      InputLabelProps={{
                        classes: {
                          root: classes.inputContactPropsRoot
                        }
                      }}
                      InputProps={{
                        classes: {
                          root: classes.inputContactPropsRoot,
                          underline: classes.contactTextFieldFocused,
                          input: classes.textFieldInputStyle
                        }
                      }}/>*/
                  }
                  {residenza && (
                    <FormControl required>
                      <InputLabel
                        id="advSourceLabel"
                        classes={{
                          root: classes.inputContactPropsRoot,
                          formControl: classes.formControl,
                        }}
                      >
                        {copy.home.form.source}
                      </InputLabel>
                      <Select
                        id="advSource"
                        name="advSource"
                        key="advSource"
                        labelId="advSourceLabel"
                        onChange={e => handleChangeData("advertisingSource", e)}
                        value={
                          data.advertisingSource ? data.advertisingSource : ""
                        }
                        required
                        className={
                          classes.select + " " + classes.underlinedInput
                        }
                        inputProps={{
                          classes: {
                            root: classes.inputContactPropsRoot,
                          },
                        }}
                      >
                        {source &&
                          source.map((item, idx) => (
                            <MenuItem
                              key={idx}
                              className={classes.inputContactPropsRoot}
                              value={item.code}
                            >
                              {item.name}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  )}
                  <TextField
                    label={copy.home.form.messaggio}
                    onChange={e => handleChangeData("body", e)}
                    value={data.body ? data.body : ""}
                    required
                    multiline
                    rows={8}
                    classes={{ root: classes.textFieldRoot }}
                    InputLabelProps={{
                      classes: {
                        root: classes.inputContactPropsRoot,
                      },
                    }}
                    InputProps={{
                      classes: {
                        root: classes.inputContactPropsRoot,
                        underline: classes.contactTextFieldFocused,
                        input: classes.textFieldInputStyle,
                      },
                    }}
                  />
                </Grid>
              </Grid>
              <Grid className={classes.checkBoxContainer}>
                <Grid container direction="row" spacing={1}>
                  <Grid item xs={12} className={classes.checkBoxLabel}>
                    {copy.home.form.privacy} <a className={classes.checkBoxLabel + ' ' + classes.checkBoxLabelLink} href={PrivacyPdf} target="_blank" rel="noreferrer">{copy.home.form.privacyLink}</a>
                  </Grid>
                  <Grid item>
                    <Checkbox
                      required={true}
                      name="consent1"
                      onChange={e => handleChangeData("consent1", e)}
                      value={data.consent1 || false}
                      checkedIcon={
                        <div className={classes.checkedIcon}>
                          <CheckboxIcon
                            dimension="13px"
                            color="white"
                            top="-5px"
                          />
                        </div>
                      }
                      icon={<div className={classes.icon} />}
                      classes={{
                        root: classes.checkBoxRoot,
                      }}
                    />
                  </Grid>
                  <Grid item xs={10} sm={11} className={classes.checkBoxLabel}>
                    {copy.home.form.check1}
                  </Grid>
                </Grid>
                <Grid container direction="row" spacing={1}>
                  <Grid item>
                    <Checkbox
                      name="consent2"
                      onChange={e => handleChangeData("consent2", e)}
                      value={data.consent2 || false}
                      checkedIcon={
                        <div className={classes.checkedIcon}>
                          <CheckboxIcon
                            dimension="13px"
                            color="white"
                            top="-5px"
                          />
                        </div>
                      }
                      icon={<div className={classes.icon} />}
                      classes={{
                        root: classes.checkBoxRoot,
                      }}
                    />
                  </Grid>
                  <Grid item xs={10} sm={11} className={classes.checkBoxLabel}>
                    {copy.home.form.check2}
                  </Grid>
                </Grid>
                <Grid container direction="row" spacing={1}>
                  <Grid item>
                    <Checkbox
                      name="consent4"
                      onChange={e => handleChangeData("consent4", e)}
                      value={data.consent4 || false}
                      checkedIcon={
                        <div className={classes.checkedIcon}>
                          <CheckboxIcon
                            dimension="13px"
                            color="white"
                            top="-5px"
                          />
                        </div>
                      }
                      icon={<div className={classes.icon} />}
                      classes={{
                        root: classes.checkBoxRoot,
                      }}
                    />
                  </Grid>
                  <Grid item xs={10} sm={11} className={classes.checkBoxLabel}>
                    {copy.home.form.check3}
                  </Grid>
                </Grid>
              </Grid>
            </FormControl>
          </Grid>
          <Grid container justify="center">
            <Button type="submit" id="gatsby-contact-form" classes={{ root: classes.greyButton }}>
              <span id="gatsby-contact-form-label">{copy.home.form.submit}</span>
            </Button>
          </Grid>
        </form>
      )}
    </Grid>
  )
}

export default withStyles(homeStyle)(Contact)
