
const subjectOptions = [
    {
        code: "Commerciale",
        name: "Commerciale"
    },
    {
        code: "Investitori",
        name: "Investitori"
    },
    {
        code: "Marketing e pubblicità",
        name: "Marketing e pubblicità"
    },
    {
        code: "Amministrazione",
        name: "Amministrazione"
    },
    {
        code: "Segreteria",
        name: "Segreteria"
    },
    {
        code: "Informazione generica",
        name: "Informazione generica"
    },
    {
        code: "Lavora con noi",
        name: "Lavora con noi"
    }
];

export {
    subjectOptions
};