import React, { useState } from "react"
import { navigate } from "gatsby"

import withStyles from "@material-ui/core/styles/withStyles"
import Grid from '@material-ui/core/Grid'
import Slider from "react-slick"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import CercaZona from '../../../images/icons/CercaZona'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import CarouselStyle from './carouselStyle'
import zones from '../../../constants/Zone'

import copy from '../../../constants/copy.json'

const LifeStyleCarousel = ({classes}) => {

    const [currentSlide, setCurrentSlide] = useState(0);
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('sm'));

    const carouselCards = zones && zones.map((item, idx) => {
        return (
            <div className={classes.sliderContent} key={idx}>
                <Grid container className={classes.clickable} onClick={() => navigate('nuove-costruzioni-zone-milano')}>
                    <Grid item xs={12} md={3} style={{
                        backgroundImage : `url(${item.image})`, 
                        height:'350px',
                        backgroundSize: 'cover',
                        backgroundPosition: 'center'}}>
                    </Grid>
                    <Grid item xs={12} md={6} className={classes.carouselContent}>
                        <Grid item className={classes.subTitle}>
                            {copy.home.lifestyle.subTitle}
                        </Grid>
                        <Grid item className={classes.titleMedium}>
                            {copy.home.lifestyle.title}
                        </Grid>
                        <Grid container item className={classes.linkCta} direction='column'>
                            {copy.home.lifestyle.cta}
                            <Grid item className={classes.linkCtaBorder}/>
                        </Grid>
                    </Grid>
                    <Grid container item xs={12} md={3} justify='center' className={classes.carouselImage}>               
                        <CercaZona color='grey' zoneCode={item.code} dimension={matches && '50px'}/>
                    </Grid>
                </Grid>
            </div>
        )
    });

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        autoplaySpeed: 5000,
        autoplay: true,
        fade: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        pauseOnDotsHover: true,
        beforeChange: (prev, next) => {            
            setTimeout( () => { setCurrentSlide(next); }, 10 );			
        },
        dotsClass: classes.verticalDots,
        customPaging: index => {
            return (
              <div className={index === currentSlide ? classes.activeDots : classes.customDots}/>
            )
        }
    };

    return (
        <Grid className={classes.lifestyleCarousel}>
            <Slider {...settings} className={classes.slider}>
                {carouselCards}
            </Slider>
        </Grid>

    );
}

export default withStyles(CarouselStyle)(LifeStyleCarousel)
