import image1 from "../images/cercazone_hero1.jpg"
import image2 from "../images/cercazone_hero2.jpg"
import image3 from "../images/cercazone_hero3.jpg"
import image4 from "../images/cercazone_hero4.jpg"
import image5 from "../images/cercazone_hero5.jpg"

const bannerImages = [
    {
        image: image1,
        mobile: true,
        height: '40%'
    },
    {
        image: image2,
        mobile: true,
        height: '40%'
    },
    {
        image: image3,
        mobile: false
    },
    {
        image: image4,
        mobile: true,
        height: '59%'
    },
    {
        image: image5,
        mobile: true,
        height: '59%'
    },
];

export {
    bannerImages
};