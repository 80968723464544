import React, {useState} from 'react'

import { Grid, RootRef, Select, MenuItem, Button } from '@material-ui/core'

import projectsStyleZone from "./projectsStyle.jsx"
import withStyles from "@material-ui/core/styles/withStyles"
import Header from "../Header/Header"
import Footer from '../Footer/Footer'
import Contact from '../Contact/Contact'
import ZoneSection from './ZoneSection'
import ProjectCard from '../Projects/ProjectCard'
import ZoneCardSelect from './ZoneCardSelect'
import Pagination from '../Pagination/Pagination'

import zones from '../../constants/Zone'
import {bannerImages} from '../../constants/ZoneBanner'

import backgroundImage from '../../images/cercazone_none.jpg'

import ZoneCard from './ZoneCard'
import CercaZona from '../../images/icons/CercaZona'
import Arrow from '../../images/icons/Arrow'
import copy from '../../constants/copy.json'

import { StickyContainer, Sticky } from 'react-sticky'

const ProjectsZone = ({classes, ...props}) => {

  const zoneDef = zones.find((z) => props.pageContext  && z.label === props.pageContext.zone);

  const [selectedTab, setSelectedTab] = useState(zoneDef ? zoneDef : '');

  const resRef = React.useRef();
  const zoneRef = React.useRef();

  const residenze = props.pageContext && props.pageContext.residenze;

  const residenzeFiltered = selectedTab !== '' && residenze ? residenze.filter((item) => {
    if(item.acfResidenza.soldout === 'soldOut'){
      return false;
    }
    return selectedTab.zone && selectedTab.zone.includes(item.acfResidenza.zone);
  }) : [];

  residenzeFiltered.sort((a, b) => {
    if(a.acfResidenza.rating === b.acfResidenza.rating){
      return b.acfResidenza.price - a.acfResidenza.price;
    }
    return a.acfResidenza.rating - b.acfResidenza.rating;
  });

  const zoneCards = zones && zones.map((item, idx) => {
    return (
      <ZoneCard card={item} key={idx} selectedTab={selectedTab}/>
    );
  });

  const populateResCards = (visibleElements) => {
    const list = residenzeFiltered.map((item, idx) => {
      return (
        <ProjectCard 
          item={item} 
          key={idx} 
          zoneTab={true}
          last={visibleElements % 2 !== 0 ? idx === visibleElements-1 : idx >= visibleElements-2}/>
      );
    });
    return list.slice(0, visibleElements);
  };

  const zoneCardsSelect = zones.map((item, idx) => {
    return (
      <MenuItem className={classes.inputContactPropsRoot} value={item.code} key={idx}>
        <ZoneCardSelect item={item}/>
      </MenuItem>
    )
  });

  const pageContent = (
    <>
      <RootRef rootRef={zoneRef}>
      <Grid container className={classes.zoneTabContainer} direction='row' justify='space-between' id='zone'>
        {zoneCards}
        {selectedTab === '' ?
        <>
          <Grid container item xs={12} justify='center' className={classes.mobileHidden}>
            <img src={backgroundImage} alt=''/>
            <Grid container item className={classes.descriptionZone + ' ' +  classes.absolute} justify='center'>
              {copy.zone.description2}
              <br/>
              {copy.zone.description3}
            </Grid>
          </Grid>
          <Grid container item xs={12} justify='center' className={classes.backgroundImageZone + ' ' + classes.mobileShow} style={{backgroundImage : `url(${backgroundImage})`}}>
            <Grid container item className={classes.descriptionZone} justify='center'>
              {copy.zone.description2}
              <br/>
              {copy.zone.description3}
            </Grid>
          </Grid>
        </>
        :
        <ZoneSection zone={selectedTab} setSelectedTab={setSelectedTab} resRef={resRef}/>}
      </Grid>
      </RootRef>
      {selectedTab !== '' &&
      <RootRef rootRef={resRef}>
        <Grid container item  justify='center' >
          <Grid container justify='center' alignContent='center' style={{marginBottom: '30px'}}>
            <CercaZona color='grey' dimension='50px' zoneCode={selectedTab.code}/>
          </Grid> 
          <Grid container alignContent='center' alignItems='center' justify='center' className={classes.subTitle}>
            {copy.zone.scopri}
          </Grid>
          <Grid container alignContent='center' alignItems='center' justify='center' className={classes.sectionTitleUnderline}>
            Milano {selectedTab.label}
          </Grid>
          {residenzeFiltered.length > 0 ?
          <Grid container className={classes.resCardsContainer}>
            <Pagination 
              pagination={4} 
              cta={copy.progetti.otherResCta}
              elementNumber={residenzeFiltered.length}
              elements={residenzeFiltered}
              populatePage={(visibleElements) => populateResCards(visibleElements)}/>
          </Grid>
          :
          <Grid container alignContent='center' alignItems='center' justify='center'>
            <Grid item className={classes.subTitleNoRes}>
              {copy.progetti.projectPage.noRes}
            </Grid>
            <Grid container item justify='center' style={{marginBottom: '50px'}}>
              <Button classes={{root: classes.greyButton}}  onClick={() => zoneRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })}>
                {copy.zone.cta2}
              </Button>
            </Grid>
          </Grid>}
        </Grid>
      </RootRef>}
    </>
  );

  return (
    <div style={{height: "100%"}}>
      <Header home={false} />
      <div className={classes.heroContainer}>
        <Grid container direction='row' justify='space-between' className={`${classes.mobileHidden}`} style={{overflow: 'hidden', height: '100%'}}>
          {bannerImages.map((item, idx) => {  
            return(
              <Grid key={idx} className={`${classes.bannerImageContainer}`}>
                <Grid className={classes.bannerImage} style={{backgroundImage : `url(${item.image})`}}/>
              </Grid> 
              );
            })}
        </Grid>
        <Grid container direction='row' justify='space-between' className={`${classes.mobileShow}`} style={{overflow: 'hidden', height: '100%'}}>
          <Grid container item className={`${classes.bannerImageContainer}`} justify='space-between'>
            <Grid item className={classes.bannerImage} style={{backgroundImage : `url(${bannerImages[0].image})`, height: bannerImages[0].height}}/>
            <Grid item className={classes.bannerImage} style={{backgroundImage : `url(${bannerImages[3].image})`, height: bannerImages[3].height}}/>
          </Grid> 
          <Grid container item className={`${classes.bannerImageContainer}`} justify='space-between'>
            <Grid item className={classes.bannerImage} style={{backgroundImage : `url(${bannerImages[4].image})`, height: bannerImages[4].height}}/>
            <Grid item className={classes.bannerImage} style={{backgroundImage : `url(${bannerImages[1].image})`, height: bannerImages[1].height}}/>
          </Grid> 
        </Grid>
      </div>
      <div className={classes.projectZoneContainer}>       
        <Grid container item  justify='center'>
          <Grid container alignContent='center' alignItems='center' justify='center' className={classes.subTitle}>
            {copy.zone.subtitle}
          </Grid>
          <Grid container alignContent='center' alignItems='center' justify='center' className={classes.sectionTitleUnderline}>
            <div dangerouslySetInnerHTML={{__html: copy.zone.title}}/>
          </Grid>
          <Grid container item alignContent='center' alignItems='center' justify='center' className={classes.description}>
            {copy.zone.description}
          </Grid>
          <Grid container justify='center' style={{marginTop: '50px'}}>
            <Arrow direction='down' dimension='20px' color='black'/>
          </Grid>
          <Grid container alignContent='center' alignItems='center' justify='center' className={classes.subTitle}>
            {copy.zone.scegli}
          </Grid>
        </Grid>
        <StickyContainer>
          <Sticky>
            {({style, isSticky}) => (
              <div style={{...style, top: '65px', zIndex: '1'}}>
                <Grid container item justify='center'>
                  <Select 
                      id="subject"
                      key="subject"
                      name="subject"
                      labelId="subjectLabel"
                      value={selectedTab.code ? selectedTab.code : 'MI'}
                      className={classes.selectMap + ' ' + classes.mobileShow}
                      inputProps={{
                        classes: {
                          root: classes.inputContactPropsRoot
                        }
                      }}
                      >
                        <MenuItem className={classes.inputContactPropsRoot} value="MI" disabled>
                          <ZoneCardSelect item={{label: 'Milano', code: 'MI'}}/>
                        </MenuItem>
                        {zoneCardsSelect}
                  </Select> 
                </Grid>
              </div>
            )}
          </Sticky>
          {pageContent}
        </StickyContainer>
        <Contact /> 
      </div>
      <Footer />
    </div>
  )
}

export default withStyles(projectsStyleZone)(ProjectsZone)
