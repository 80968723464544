import React from 'react'
import { Grid } from '@material-ui/core'

import mapStyle from './mapStyle'
import withStyles from "@material-ui/core/styles/withStyles"

const MapMarkerCluster = ({classes, pointCount, length, lat, lng, mapRef, cluster, supercluster}) => {

  return (
    <Grid
        className={classes.markerContainer}
        onClick={() => {
            const expansionZoom = Math.min(
              supercluster.getClusterExpansionZoom(cluster.id),
              20
            );
            mapRef.current.setZoom(expansionZoom);
            mapRef.current.panTo({ lat: lat, lng: lng });
        }}
        onKeyDown={() => {}}
    >
        <div className={classes.markerCluster}>
            {pointCount}          
        </div>
    </Grid>
  )
};

export default withStyles(mapStyle)(MapMarkerCluster)