import React from "react"
import {
  Card,
  CardContent,
  withStyles,
} from "@material-ui/core"
import findUsCardStyle from "./findUsCardStyle"
import GoogleMapReact from "google-map-react"
import gMapStyle from "../../style/gMapStyle"
import { UFFICIO_MILANO, UFFICIO_ROMA } from "../../constants/Uffici"

const buildCustomMarker = (title, position, map, maps) => {
  const marker = new maps.Marker({
    title,
    position,
    map
  });
  marker.addListener("click", () => {
    map.setZoom(15);
    map.setCenter(marker.getPosition());
  });
  return marker;
}

const FindUsCard = ({ classes, content, setZoomOnMarker }) => {

  const renderMarkers = (map, maps) => {
    const markerUfficioMilano = buildCustomMarker(UFFICIO_MILANO.address, UFFICIO_MILANO.coordinates, map, maps );
    const markerUfficioRoma = buildCustomMarker(UFFICIO_ROMA.address,  UFFICIO_ROMA.coordinates, map, maps)
    setZoomOnMarker(() => (markerId) => maps.event.trigger(markerId === UFFICIO_MILANO.markerId ? markerUfficioMilano : markerUfficioRoma, 'click'))
    return [markerUfficioMilano, markerUfficioRoma]
  };
  return (
    <Card className={classes.card}>
      <CardContent className={classes.cardContent}>
        <h1 className={classes.title}>{content.title}</h1>
        <div className={classes.mapContainer}>
          <GoogleMapReact
            bootstrapURLKeys={{ key: "AIzaSyAk3K2wgZc3M7n3RWSqWnIv0u5fM1n7HWc" }}
            options={{  
              styles: gMapStyle,
              fullscreenControl: false,
              zoomControl: false
            }}
            defaultCenter={{lng: (UFFICIO_MILANO.coordinates.lng + UFFICIO_ROMA.coordinates.lng) / 2, lat:  (UFFICIO_MILANO.coordinates.lat + UFFICIO_ROMA.coordinates.lat) / 2}}
            defaultZoom={5}
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={({ map, maps }) => renderMarkers(map, maps)}
          ></GoogleMapReact>
        </div>
      </CardContent>
    </Card>
  )
}

export default withStyles(findUsCardStyle)(FindUsCard)
