import React from 'react'
import { navigate } from "gatsby"

import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import { Grid } from '@material-ui/core'

import projectsStyle from "./projectsStyle.jsx"
import withStyles from "@material-ui/core/styles/withStyles"
import CercaZona from '../../images/icons/CercaZona'


const ZoneCard = ({ classes, card, selectedTab, section }) => {

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Grid container item onClick={() => navigate(`/nuove-costruzioni-milano-${card.label}#zone`)} style={{ margin: section && 'auto' }} className={`${classes.zoneTabSection} ${classes.mobileHidden} ${selectedTab.code === card.code && classes.zoneTabSectionSelected} ${section && classes.sectionZoneCard}`} justify='center' alignContent='space-between'>
      <Grid container justify='center' alignContent='center' className={`${!section && classes.zoneTabSectionCircle} ${selectedTab.code === card.code && !section && classes.zoneTabSectionSelectedCircle}`}>
        <CercaZona color='black' dimension={matches ? '80px' : '100px'} zoneCode={card.code} />
      </Grid>
      {section ?
        <Grid container>
          <h1>
            <Grid container alignContent='center' alignItems='center' justify='center' className={classes.subTitle}>
              Nuove costruzioni
            </Grid>
            <Grid container alignContent='center' alignItems='center' justify='center' className={classes.subTitle}>
              Milano
            </Grid>
            <Grid container alignContent='center' alignItems='center' justify='center' className={classes.title}>
              {card.label}
            </Grid>
          </h1>
        </Grid>
        :
        <Grid container>
          <Grid container alignContent='center' alignItems='center' justify='center' className={classes.subTitle}>
            Milano
          </Grid>
          <Grid container alignContent='center' alignItems='center' justify='center' className={classes.title}>
            {card.label}
          </Grid>
        </Grid>}
    </Grid>
  );
}

export default withStyles(projectsStyle)(ZoneCard)
