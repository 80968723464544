import React from "react"
import withStyles from "@material-ui/core/styles/withStyles"
import Grid from '@material-ui/core/Grid'

import ComingSoonCarouselStyle from './comingSoonCarouselStyle.jsx'
import copy from '../../constants/copy.json'

const ComingSoonCard = ({classes, item, isCurrentSlide, isPreview, contactRef}) => {

    return (
        <div className={`${classes.comingSoonCard} ${!isCurrentSlide ? classes.sliderContentCloned : classes.sliderContent} ${isPreview && classes.sliderContentPreview}`}>
            <Grid container alignContent='center' alignItems='center' justify='center' className={isCurrentSlide ? classes.comingSoonTitle : classes.comingSoonTitleHidden}>
                {isCurrentSlide ? copy.home.comingSoon.title : ""} 
            </Grid>
            <Grid container className={`${classes.sliderContentCard} ${classes.clickable}`} onClick={() => contactRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })}>
                <Grid item xs={12} md={5} className={classes.backgroundImage} style={{backgroundImage : `url(${item.image && item.image.sourceUrl})`}}>
                </Grid>
                <Grid container item xs={12} md={7} className={classes.carouselContent} direction='column'>
                    <Grid item className={classes.subTitle}>
                        {item.place}
                    </Grid>
                    <Grid item className={classes.title}>
                        {item.title}
                    </Grid>
                    <Grid item className={classes.description}>
                        {item.description}
                    </Grid>
                    <Grid container item className={classes.linkCta} direction='column' onClick={() => contactRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })}>
                        {copy.home.comingSoon.cta}
                        <Grid item className={`${classes.linkCtaBorder} ${classes.linkCtaBorderDouble}`}/>
                    </Grid>
                </Grid>
            </Grid>
        </div>   
    );
}

export default withStyles(ComingSoonCarouselStyle)(ComingSoonCard)