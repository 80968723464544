import React from "react"
import { Helmet } from "react-helmet"

import "../style/style.scss"
import "../style/font.css"
import MessengerIcon from '../components/Home/MessengerIcon'
import Grid from '@material-ui/core/Grid'
import ProjectPage from '../components/Projects/ProjectPage/ProjectPage'

const projectPage = (props) => {
    return (

    <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>{props.pageContext.seoTitle}</title>
            <meta name="description" content={props.pageContext.seoDescription} />
        </Helmet>
        <Grid style={{ position: 'fixed', zIndex: '1000', width:'60px', right: '0px', bottom: '15px' }}>
            <Grid container  className={`iconContainer`} alignContent='center' justify='center'>
            <MessengerIcon type='whatsapp' />
            </Grid>
            <Grid container  className={`iconContainer`} alignContent='center' justify='center'>
            <MessengerIcon type='tel' />
            </Grid>
        </Grid>
        <ProjectPage {...props} />    
    </>
)}

export default projectPage
