import colors from "./colors.scss";

const haylardDisplay = {
    fontFamily: 'HalyardDisplay',
    letterSpacing: '1px'
};

const haylardDisplayBold = {
    ...haylardDisplay,
    fontWeight: 'bold'
};

const garamond = {
    fontFamily: 'GaramondPremier',
    letterSpacing: '1px'
};

const cormorantGaramond = {
    fontFamily: 'CormorantGaramond',
    letterSpacing: '2px'
};

const greyButton = {
    background : colors.darkGrey,
    borderRadius: "0px",
    color: "white", 
    fontSize: "12px",
    minWidth: "150px",
    height: '40px',
    paddingLeft: '20px',
    paddingRight: '20px',
    textTransform: 'uppercase',
    ...haylardDisplayBold
};

const greyButtonDisabled = {
    ...greyButton,
    background: colors.grey
}

const greyButtonHover = {
    ...greyButton,
    background : "white",
    color: colors.darkGrey,
    border: '2px solid ' + colors.darkGrey
};

const greyButtonHoverDark = {
    ...greyButton,
    background : colors.grey,
};

const greyButtonBorder = {
   ...greyButton,
   border: '2px solid white'
};

const greyButtonBorderHover = {
    ...greyButtonBorder,
    background : colors.grey
};

const whiteButton = {
    ...greyButton,
    background: colors.lightGrey4,
    color: colors.darkGrey
};

const whiteButtonHover = {
    ...whiteButton,
    background: colors.lightGrey1,
};

const whiteButtonBorder = {
    ...whiteButton,
    border: '2px solid black'
};

const whiteButtonBorderHover = {
    ...whiteButtonBorder,
    background : colors.lightGrey1
};

const italicSubTitleMedium = {
    ...cormorantGaramond,
    fontStyle: 'italic',
    fontSize: '26px'
};

const italicSubTitle = {
    ...cormorantGaramond,
    fontStyle: 'italic',
    fontSize: '22px'
};

const title = {
    ...haylardDisplayBold,
    fontSize: '20px',
    textTransform: 'uppercase'
};

const titleMedium = {
    ...haylardDisplayBold,
    fontSize: '24px',
    textTransform: 'uppercase'
};

const titleBig = {
    ...haylardDisplayBold,
    fontSize: '30px',
    textTransform: 'uppercase'
};

const titleWhite = {
    color: 'white'
};

const titleLight = {
    color: colors.grey
};

const titleDark = {
    color: colors.darkGrey
};

const error = {
    color: 'red'
};

export {
    greyButton,
    greyButtonHover,
    greyButtonBorder,
    greyButtonHoverDark,
    greyButtonBorderHover,
    greyButtonDisabled,
    whiteButtonBorderHover,
    whiteButton,
    whiteButtonHover,
    whiteButtonBorder,
    haylardDisplay,
    haylardDisplayBold,
    garamond,
    cormorantGaramond,
    italicSubTitleMedium,
    italicSubTitle,
    titleWhite,
    titleLight,
    titleDark,
    titleBig,
    titleMedium,
    title,
    error
};