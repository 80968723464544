import React from "react"
import MessengerIcon from '../components/Home/MessengerIcon'
import Grid from '@material-ui/core/Grid'
import RassegnaStampaPage from '../components/News/RassegnaStampa/RassegnaStampaPage'
import { Helmet } from "react-helmet"

import "../style/style.scss"
import "../style/font.css"

const rassegnaStampaArticle = (props) => (
    <>
    <Helmet>
        <meta charSet="utf-8" />
        <title>{props.pageContext.seoTitle || `${props.pageContext.title} - Abitare Co.`}</title>
        <meta name="description" content={props.pageContext.seoDescription || props.pageContext.title} />
    </Helmet>
    <RassegnaStampaPage {...props} />  
    </>
)

export default rassegnaStampaArticle
