// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contatti-uffici-js": () => import("./../src/pages/contatti-uffici.js" /* webpackChunkName: "component---src-pages-contatti-uffici-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lavora-con-noi-js": () => import("./../src/pages/lavora-con-noi.js" /* webpackChunkName: "component---src-pages-lavora-con-noi-js" */),
  "component---src-pages-note-legali-js": () => import("./../src/pages/note-legali.js" /* webpackChunkName: "component---src-pages-note-legali-js" */),
  "component---src-pages-nuove-costruzioni-zone-milano-js": () => import("./../src/pages/nuove-costruzioni-zone-milano.js" /* webpackChunkName: "component---src-pages-nuove-costruzioni-zone-milano-js" */),
  "component---src-pages-preview-js": () => import("./../src/pages/preview.js" /* webpackChunkName: "component---src-pages-preview-js" */),
  "component---src-pages-privacy-cookie-policy-js": () => import("./../src/pages/privacy-cookie-policy.js" /* webpackChunkName: "component---src-pages-privacy-cookie-policy-js" */),
  "component---src-pages-real-estate-milano-js": () => import("./../src/pages/real-estate-milano.js" /* webpackChunkName: "component---src-pages-real-estate-milano-js" */),
  "component---src-templates-article-js": () => import("./../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-news-js": () => import("./../src/templates/news.js" /* webpackChunkName: "component---src-templates-news-js" */),
  "component---src-templates-nuove-costruzioni-js": () => import("./../src/templates/nuoveCostruzioni.js" /* webpackChunkName: "component---src-templates-nuove-costruzioni-js" */),
  "component---src-templates-project-page-js": () => import("./../src/templates/projectPage.js" /* webpackChunkName: "component---src-templates-project-page-js" */),
  "component---src-templates-rassegna-stampa-article-js": () => import("./../src/templates/rassegnaStampaArticle.js" /* webpackChunkName: "component---src-templates-rassegna-stampa-article-js" */),
  "component---src-templates-rassegna-stampa-js": () => import("./../src/templates/rassegnaStampa.js" /* webpackChunkName: "component---src-templates-rassegna-stampa-js" */)
}

