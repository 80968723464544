import React from "react"
import { navigate } from "gatsby"

import Grid from '@material-ui/core/Grid'
import homeStyle from "./homeStyle"
import withStyles from "@material-ui/core/styles/withStyles"
import { Button } from '@material-ui/core'

import searchIcon from '../../images/svg/icon_button_cerca.svg'
import searchIconLight from '../../images/svg/icon_button_cerca_light.svg'
import Copy from '../../constants/copy.json'
import {RENT_WEBSITE_URL} from '../../constants/links'

const Hero = ({ classes }) => {

  const goToSearch = (zone) => {
    navigate(`/nuove-costruzioni-milano`);
  }

  const openInNewTab = (url) => {
    window.open(url, '_blank').focus();
  }

  return (
    <Grid direction="column" style={{height: '100%' , display:'flex'}}>
      <div className={classes.heroContainer}>
        <Grid container alignItems="center" direction="column" classes={{ root: classes.heroSection }}>
          <Grid className={classes.subTitleHero}>
            {Copy.home.hero.subTitle}
          </Grid>
          <Grid className={classes.titleHero}>
            {Copy.home.hero.title}
          </Grid>
          <Grid  style={{ width: '40px', borderBottom: '3px solid white', marginTop: '10px', marginBottom: '30px' }} />
          <Grid container justify='center' alignContent='center' className={classes.mobileHidden}>
            <Button style={{ margin: '2px !important'}} classes={{ root: classes.whiteButton }} onClick={() => goToSearch()}>
              {Copy.home.hero.ctaSell}
            </Button>
            <div style={{ width: '4px !important', height: '4px !important'}}>&nbsp;&nbsp;</div>
            <Button style={{ margin: '2px !important'}} classes={{ root: classes.whiteButton }} onClick={() => openInNewTab(RENT_WEBSITE_URL)}>
              {Copy.home.hero.ctaRent}
            </Button>
          </Grid>
        </Grid>
      </div>
      <Grid container justify='center' alignContent='center' className={classes.mobileShow} style={{minHeight:'60px', height: '10%'}}>
            <Button classes={{ root: classes.greyButton }} onClick={() => goToSearch()}>
              {Copy.home.hero.ctaSell}
            </Button>
            <div style={{ width: '4px !important', height: '4px !important'}}>&nbsp;&nbsp;</div>
            <Button classes={{ root: classes.whiteButton }} onClick={() => openInNewTab(RENT_WEBSITE_URL)}>
              {Copy.home.hero.ctaRent}
            </Button>
      </Grid>
    </Grid>
  )
}

export default withStyles(homeStyle)(Hero)
