import React from "react"
import { navigate } from "gatsby"

import { Grid, Button } from '@material-ui/core'
import newsStyle from "./newsStyle"
import withStyles from "@material-ui/core/styles/withStyles"

import logo from "../../images/logo_MYPR.png"
import copy from '../../constants/copy.json'

const UfficioStampa = ({classes, rassegnaStampa}) => {

  return (      
    <Grid container className={`${classes.ufficioStampaContainer}`} justify='center'>
        <Grid container direction='column' alignItems='center'>
          <Grid item className={classes.subTitle2}>
            Abitare Co.
          </Grid>
          <Grid item className={classes.sectionTitleUnderline}>
            UFFICIO STAMPA
          </Grid>
        </Grid>
        {/*
        <Grid container item xs={12} alignItems='center' className={classes.ufficioStampaContent}>
            <Grid container item xs={12} sm={6} className={classes.border}>
                <Grid container item direction='column' alignItems='flex-end' className={classes.ufficioStampaSection}>
                    <Grid item style={{fontWeight: 'bold'}}>
                    {copy.news.ufficioStampa.section1.name}
                    </Grid>
                    <Grid item>
                    {copy.news.ufficioStampa.section1.address}
                    </Grid>
                    <Grid item>
                    {copy.news.ufficioStampa.section1.phone}
                    </Grid>
                </Grid>
                <Grid container item direction='column' alignItems='flex-end' className={classes.ufficioStampaSection}>
                    <img src={logo} alt=''/>
                </Grid>
            </Grid>
            <Grid container item xs={12} sm={6} className={classes.contactContainer}>
                <Grid container item direction='column' className={classes.ufficioStampaSection}>
                    <Grid item style={{fontWeight: 'bold'}}>
                    {copy.news.ufficioStampa.section2.name}
                    </Grid>
                    <Grid item>
                    {copy.news.ufficioStampa.section2.address}
                    </Grid>
                    <Grid item>
                    {copy.news.ufficioStampa.section2.phone}
                    </Grid>
                </Grid>
                <Grid container item direction='column' className={classes.ufficioStampaSection}>
                    <Grid item style={{fontWeight: 'bold'}}>
                    {copy.news.ufficioStampa.section3.name}
                    </Grid>
                    <Grid item>
                    {copy.news.ufficioStampa.section3.address}
                    </Grid>
                    <Grid item>
                    {copy.news.ufficioStampa.section3.phone}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
        */}
        {!rassegnaStampa && 
        <Grid container justify='center'>
            <Button classes={{root: classes.greyButton}} onClick={()=> navigate(`/rassegna-stampa`)}>
                {copy.news.ufficioStampa.cta}
            </Button>
        </Grid>}          
    </Grid>
  )
}

export default withStyles(newsStyle)(UfficioStampa)
