import React from "react"
import { Helmet } from "react-helmet"

import ScopriAbitareCo from "../components/ScopriAbitareCo/ScopriAbitareCo"
import "../style/style.scss"
import "../style/font.css"
import MessengerIcon from '../components/Home/MessengerIcon'
import Grid from '@material-ui/core/Grid'

const ScopriPage = () => (
    <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>Real estate Milano - Abitare Co.</title>
            <meta name="description" content="Abitare Co real estate è il partner ideale di imprese edili e fondi immobiliari per la commercializzazione di immobili di prestigio a Milano e provincia" />
        </Helmet>
        <Grid style={{ position: 'fixed', zIndex: '1000', width:'60px', right: '0px', bottom: '15px' }}>
            <Grid container  className={`iconContainer`} alignContent='center' justify='center'>
            <MessengerIcon type='whatsapp' />
            </Grid>
            <Grid container  className={`iconContainer`} alignContent='center' justify='center'>
            <MessengerIcon type='tel' />
            </Grid>
        </Grid>
        <ScopriAbitareCo />
        
    </>
)

export default ScopriPage
