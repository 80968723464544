import React from "react"
import { Helmet } from "react-helmet"
import Home from "../components/Home/Home"
import "../style/layout.css" // necessario per applicare lo style utilizzato durante lo sviluppo
import "../style/style.scss"
import "../style/font.css"

const IndexPage = () => (
    <>
        <Helmet>
            <title>Nuove costruzioni a Milano - Abitare Co.</title>
            <meta name="description" content="Abitare Co commercializza nuove costruzioni a Milano e nell'hinterland. Scopri tutti i cantieri e le soluzioni abitative della nostra Agenzia immobiliare." />
        </Helmet>
        <Home />
    </>
)

export default IndexPage
