import React from "react"
import { Helmet } from "react-helmet"

import Projects from "../components/Projects/Projects"
import "../style/style.scss"
import "../style/font.css"
import MessengerIcon from '../components/Home/MessengerIcon'
import Grid from '@material-ui/core/Grid'
const nuoveCostruzioni = (props) => (
    <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>{`Nuove costruzioni ${props.pageContext && props.pageContext.itZone ? props.pageContext.itZone.name : ""} - Abitare Co.`}</title>
            <meta name="description" content={props.pageContext.description} />
        </Helmet>
        <Grid style={{ position: 'fixed', zIndex: '1000', width:'60px', right: '0px', bottom: '15px' }}>
            <Grid container  className={`iconContainer`} alignContent='center' justify='center'>
            <MessengerIcon type='whatsapp' />
            </Grid>
            <Grid container  className={`iconContainer`} alignContent='center' justify='center'>
            <MessengerIcon type='tel' />
            </Grid>
        </Grid>
        <Projects {...props} />
    </>
)

export default nuoveCostruzioni
