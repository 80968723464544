import colors from "../../style/colors.scss"
import { garamond } from "../../style/style.jsx"

const findUsCardStyle = theme => ({
  card: {
    minWidth: "40vw",
    width: "40vw",
    minHeight: "70vh",
    height: "70vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    boxShadow: "none",
    borderColor: colors.lightGrey3,
    background: "#f0f0f0",
    borderRadius: "0",
    [theme.breakpoints.down("sm")]: {
      minWidth: "90vw",
      width: "90vw",
      height: "50vh",
      minHeight: "350px",
      boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.45)",
    },
  },
  cardContent: {
    height: "100%",
    width: "100%",
  },
  title: {
    ...garamond,
    fontStyle: "italic",
    fontSize: "32px",
    fontWeight: "500",
    marginTop: "2%",
    [theme.breakpoints.down("sm")]: {
      marginTop: "2%",
      marginBottom: "3%",
    },
  },
  mapContainer: {
    height: "85%",
    minHeight: "85%",
    width: "100%",
  },
})

export default findUsCardStyle
