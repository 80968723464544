import React, { useRef, useState } from 'react';
import withStyles from "@material-ui/core/styles/withStyles"
import dropZoneStyle from "./DropZoneStyle"
import copy from "../../constants/copy.json"
import downloadIcon from "../../images/svg/icon_upload.svg"

const DropZone = ({classes, title, handleFiles, validTypes, multiple}) => {
    const fileInputRef = useRef();

    const [selectedFiles, setSelectedFiles] = useState([]);

    const fileInputClicked = () => {
        fileInputRef.current.click();
    }

    const dragOver = (e) => {
        e.preventDefault();
    }
    
    const dragEnter = (e) => {
        e.preventDefault();
    }
    
    const dragLeave = (e) => {
        e.preventDefault();
    }

    const fileDrop = (e) => {
        e.preventDefault();
        let files = e.dataTransfer.files;

        if(!multiple && files.length > 1) {
            files = [files[0]];
        }

        handleFiles(files);
        setSelectedFiles(files);
    }

    const filesSelected = () => {
        if (fileInputRef.current.files.length) {
            handleFiles(fileInputRef.current.files);
            setSelectedFiles(fileInputRef.current.files);
        }
        else {
            handleFiles([]);
            setSelectedFiles([]);
        }
    }

    const removeFile = () => {
        handleFiles([]);
        setSelectedFiles([]);
    }

    let inputFile = (
        <input
            ref={fileInputRef}
            required
            style={{opacity : '0', height: '1px', width: '1px'}}
            type="file"
            onChange={filesSelected}
            name='inputFile'
        />
    );

    if(multiple) {
        inputFile = (
            <input
                ref={fileInputRef}
                required
                style={{opacity : '0', height: '1px', width: '1px'}}
                type="file"
                multiple
                onChange={filesSelected}
                name='multipleInputFile'
            />
        );
    }
    
    let content;
    if(selectedFiles.length === 0) {
        content = (
            <div className={classes.desktopFullSize}>
                <div className={classes.desktopContainer}>
                    <div className={classes.dropContainer}
                    role={"button"} tabIndex={0}
                    onDragOver={dragOver}
                    onDragEnter={dragEnter}
                    onDragLeave={dragLeave}
                    onDrop={fileDrop}>

                    <div className={classes.dropMessage}>

                        {inputFile}

                        <div className={classes.messageTitle}>
                            {title}
                        </div>

                        <div className={classes.messageSubtitle}>
                            <div onKeyDown={fileInputClicked} role={"button"} tabIndex={0} onClick={fileInputClicked} className={classes.clickToUpload}>
                                {copy.lavoraConNoi.form.dropZone.sfoglia}
                            </div>
                            {copy.lavoraConNoi.form.dropZone.rest}
                        </div>
                    </div>
                </div>
                </div>

                <div className={classes.mobileContainer}>
                    <div className={classes.buCompanyProfileContainer}>            
                        <div role={"button"} tabIndex={0} className={classes.buttonDownload} onClick={fileInputClicked} onKeyDown={fileInputClicked}>
                            <div className={classes.buIconContainer}>
                                {inputFile}
                                <div className={classes.buIconContainer}>
                                    <img className={classes.svgDownload} src={downloadIcon} alt={""}/>
                                </div>
                                <div className={classes.buTextContainer}>
                                    {copy.lavoraConNoi.form.carica_mobile}

                                    <hr className={classes.halfBorder}/>
                                </div>
                            </div>    
                        </div>          
                    </div>
                </div>
            </div>
        )
    }
    else {
        let fileRows = Array.from(selectedFiles).map((elem, index) =>
            (
                <div className={classes.fileRow} key={index}>
                    <div className={classes.fileTitle}>
                        {elem.name}
                    </div>
                    <div role={"button"} tabIndex={0} onKeyDown={removeFile} className={classes.deleteFile} onClick={removeFile}>
                        X
                    </div>
                </div>
            )
        );

        content = fileRows
    }

    return (
        <div className={classes.container}>
            {content}
        </div>
    )
}
export default withStyles(dropZoneStyle)(DropZone);