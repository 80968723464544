import backgroundImage from "../../images/lavoraconnoi_background.jpg"
import colors from "../../style/colors.scss";

import {
    garamond,
    haylardDisplay,
    greyButton,
    greyButtonHover,
    italicSubTitle,
    error
  } from "../../style/style.jsx";

const contactLavoraConNoiStyle = theme => ({
    background: {
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        //backgroundPosition: 'center',
        display: "flex",
        "justify-content": "center",
        "align-items": "center",
        "padding-top": "100px",
        "padding-bottom": "150px",
        [theme.breakpoints.down('sm')] : {
            backgroundImage: "unset",
            paddingTop: "40px",
            paddingBottom: "40px"
        }
    },
    formContainer: {
        "max-width": "60%",
        "background": colors.lightGrey1,
        [theme.breakpoints.down('md')] : {
            "max-width": "95%",
        }
    },
    contactContainer : {
        backgroundColor: colors.lightGrey3,
        "box-shadow": "0 0 10px rgba(0, 0, 0, 0.2)",
        padding: '20px 40px',
        minHeight: '720px',
        [theme.breakpoints.down('sm')] : {
            padding: '20px'
        },
        [theme.breakpoints.down('md')] : {
            padding: '10px 20px'            
        }
    },
    subTitle : {
        ...italicSubTitle,
        margin: '5px',
        fontSize: "30px",
        [theme.breakpoints.down('sm')] : {
            fontSize: '18px',
            textAlign: 'center'
        }
    },
    titleDark: {
        color: "black",
        marginTop: "30px",
        [theme.breakpoints.down('md')] : {
            marginTop: "20px",
            fontSize: "25px"
        }
    },
    error: {
        ...error,
        fontSize: '24px',
        [theme.breakpoints.down('sm')] : {
            fontSize: '18px'
        }
    },
    small: {
        fontSize: '24px',
        [theme.breakpoints.down('sm')] : {
            fontSize: '18px'
        }
    },
    formControlRoot: {
        width: '100%',
        marginTop: '10px',
        marginBottom: '10px',
        fontStyle: 'italic'
    },
    contactSection1: {
        borderRight: '1px solid',
        borderColor: colors.darkGrey,
        paddingRight: '40px',
        [theme.breakpoints.down('xs')] : {
            padding: '0px',
            border: 'none'
        }
    },
    contactSection2:{
        paddingLeft: '40px',
        [theme.breakpoints.down('xs')] : {
            padding: '0px',
        }
    },
    textFieldRoot: {
        marginBottom: '30px',
        [theme.breakpoints.down('sm')] : {
            marginBottom: '15px'
        }
    },
    inputPropsRoot: {
        ...garamond, 
        background: 'white',               
        fontSize: '20px',
        borderRadius: '0px',
        [theme.breakpoints.down('sm')] : {
            fontSize: '16px'
        }
    },
    inputContactPropsRoot: {
        ...garamond, 
        fontSize: '20px',
        color: colors.darkGrey + ' !important',
        [theme.breakpoints.down('sm')] : {
            fontSize: '16px'
        }
    },
    contactTextFieldFocused:{
        '&:after': {
            borderBottom: "2px solid",
            borderColor: colors.darkGrey 
        }
    },
    formControl: {
        position: 'relative'
    },
    select: {
        marginTop: '0px !important',
        marginBottom: '30px'
    },
    underlinedInput: {
        '&:after': {
            borderBottom: "2px solid",
            borderColor: colors.darkGrey 
        }
    },
    checkBoxContainer: {
        margin: '0px 0px 0px 0px'
    },
    unwrappedFlex: {
        "flex-wrap": "unset"
    },
    checkedIcon: {
        backgroundColor: colors.darkGrey,
        width: '20px', 
        height: '20px'
    },
    icon: {
        border: '2px solid',
        borderColor: colors.grey,
        width: '20px', 
        height: '20px',
        marginTop: '0px',
    },
    checkboxMarginTop: {
        "margin-top": "20px"
    },
    checkBoxLabel: {
        ...haylardDisplay,
        fontSize: '10px',
        lineHeight: '1.8',
        marginTop: '0px',
        color: colors.darkGrey,
        [theme.breakpoints.down('sm')] : {
            textAlign: 'initial',
        }
    },
    checkBoxLabelLink: {
        textDecoration: 'underline',
        cursor: 'pointer'
    },
    greyButton : {
        ...greyButton,
        fontStyle: "initial",
        '&:hover': {
            ...greyButtonHover
        }
    },
    submitButtonContainer: {
        "margin-top": "20px",
        "margin-bottom": "30px"
    },
    dropFile: {
        display: "flex",
        "flex-grow": "1",
        "flex-wrap": "unset",
        "flex-direction": "column"
    },
    cvTitle: {
        ...haylardDisplay,
        "font-style": "initial",
        "margin-bottom": "10px",
        [theme.breakpoints.down('sm')] : {
            "display": "none"
        }
    },
});

export default contactLavoraConNoiStyle;