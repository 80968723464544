import React from 'react';
import SvgIcon from "@material-ui/core/SvgIcon";

const Checkbox = (props) => (
  <SvgIcon {...props}>    
    <svg id="Livello_1" data-name="Livello 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 7.29 7.76"><polygon className="cls-1" points="3.34 7.76 0 4.42 1.2 3.22 2.97 4.98 5.81 0 7.29 0.84 3.34 7.76"/></svg>  
  </SvgIcon>
);

const CheckboxIcon = ({color, dimension, top}) => {
  const iconStyles = {
    color : color ? color : 'black',
    height: dimension ? dimension : 'unset',
    width: dimension ? dimension : 'unset',
    top: top ? top : '0px',
    position: 'relative'
  };
  
  return (
  <div>
    <Checkbox style={iconStyles} />
  </div>
)};

export default CheckboxIcon;