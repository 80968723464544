import colors from "../../style/colors.scss"

const contactPageStyle = theme => ({
  container: {
    backgroundColor: colors.lightGrey3,
    marginTop: "64px",
    padding: "12vh 0",
    [theme.breakpoints.down("sm")]: {
      padding: "0 0 5vh 0",
    },
  },
  contactCardContainer: {
    display: "flex",
    justifyContent: "flex-end",
    paddingRight: "0.5vw",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
      paddingRight: "0",
    },
  },
  findUsCardContainer: {
    display: "flex",
    justifyContent: "flex-start",
    paddingLeft: "1vw",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
      paddingLeft: "0",
    },
  },
  contactContainer: {
    backgroundColor: "white",
    padding: "200px",
    minHeight: "750px",
    [theme.breakpoints.down("md")]: {
      padding: "100px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "20px",
    },
  },
})

export default contactPageStyle
