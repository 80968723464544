import React from 'react';
import SvgIcon from "@material-ui/core/SvgIcon";

const Instagram = (props) => (
  <SvgIcon {...props}>    
    <svg version="1.1" id="Livello_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
      viewBox="0 0 30 30" style={{enableBackground:'new 0 0 30 30'}}>
    <path class="st0" d="M15,2.7c4,0,4.5,0,6.1,0.1c1,0,1.9,0.2,2.8,0.5c0.7,0.2,1.2,0.6,1.7,1.1c0.5,0.5,0.9,1.1,1.1,1.7
      C27,7,27.2,8,27.2,8.9c0.1,1.6,0.1,2.1,0.1,6.1s0,4.5-0.1,6.1c0,1-0.2,1.9-0.5,2.8c-0.5,1.3-1.5,2.3-2.8,2.8
      C23,27,22,27.2,21.1,27.2c-1.6,0.1-2,0.1-6.1,0.1s-4.5,0-6.1-0.1c-1,0-1.9-0.2-2.8-0.5c-0.7-0.2-1.2-0.6-1.7-1.1
      c-0.5-0.5-0.9-1.1-1.1-1.7C3,23,2.8,22,2.8,21.1C2.7,19.5,2.7,19,2.7,15s0-4.5,0.1-6.1C2.8,8,3,7,3.3,6.2c0.2-0.7,0.6-1.2,1.1-1.7
      c0.5-0.5,1.1-0.9,1.7-1.1C7,3,8,2.8,8.9,2.8C10.5,2.7,11,2.7,15,2.7 M15,0c-4.1,0-4.6,0-6.2,0.1c-1.2,0-2.5,0.3-3.7,0.7
      c-1,0.4-1.9,1-2.6,1.7C1.8,3.3,1.2,4.2,0.8,5.2C0.4,6.3,0.1,7.6,0.1,8.8C0,10.4,0,10.9,0,15s0,4.6,0.1,6.2c0,1.2,0.3,2.5,0.7,3.6
      c0.4,1,1,1.9,1.7,2.6c0.7,0.8,1.6,1.4,2.7,1.7c1.2,0.4,2.4,0.7,3.6,0.7C10.4,30,10.9,30,15,30s4.6,0,6.2-0.1c1.2,0,2.5-0.3,3.6-0.7
      c2-0.8,3.6-2.4,4.4-4.4c0.4-1.2,0.7-2.4,0.7-3.6C30,19.6,30,19.1,30,15s0-4.6-0.1-6.2c0-1.2-0.3-2.5-0.7-3.7c-0.4-1-1-1.9-1.7-2.7
      c-0.7-0.8-1.6-1.4-2.6-1.7c-1.2-0.4-2.4-0.7-3.6-0.7C19.6,0,19.1,0,15,0z"/>
    <path class="st0" d="M15,7.3c-4.3,0-7.7,3.4-7.7,7.7s3.4,7.7,7.7,7.7s7.7-3.4,7.7-7.7S19.3,7.3,15,7.3L15,7.3z M15,20
      c-2.8,0-5-2.2-5-5s2.2-5,5-5s5,2.2,5,5S17.8,20,15,20z"/>
    <circle class="st0" cx="23" cy="7" r="1.8"/>
    </svg>
  </SvgIcon>
);

const InstagramIcon = ({extraClasses}) => {
  
  return (
  <div>
    <Instagram className={extraClasses} />
  </div>
)};

export default InstagramIcon;