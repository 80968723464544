const videoStyle = theme => ({
  iframeContainer: {
    [theme.breakpoints.down('lg')] : {
      position: 'relative',
      overflow: 'hidden',
      width: '100%',
      paddingTop: '56.25%',
      margin: '50px 0px'
    },
    [theme.breakpoints.down('xs')] : {
      margin: '20px 0px'
    },
    [theme.breakpoints.up('xl')] : {
      width: '1120px',
      height: '630px',
      margin: '50px 0px'
    },
  },
  articleVideo: {
    [theme.breakpoints.up('xl')] : {
      width: '960px',
      height: '540px'
    }
  },
  videoContainerFrame: {
    border: 'none',
    width: '100%',
    height: '100%',
    [theme.breakpoints.down('lg')] : {
      position: 'absolute',
      top: '0',
      left: '0',
      bottom: '0',
      right: '0',
    }
  },
  audioPlayer: {
    outline: 'none',
    width: '50%',
    [theme.breakpoints.down('md')] : {
      width: '100%'
    },
    [theme.breakpoints.down('sm')] : {
      width: '100%'
    }
  }
});

export default videoStyle;