import React from 'react';
import SvgIcon from "@material-ui/core/SvgIcon";

const Audio = (props) => (
  <SvgIcon {...props}>    
    <svg id="Livello_1" data-name="Livello 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 75.83 75.83"><defs></defs><circle className="cls-1" style={{fill:'#353535'}} cx="37.92" cy="37.92" r="37.92"/><path className="cls-2" style={{fill:'#fff'}} d="M39.4,22.76l-9.22,6.69H23.57a2.71,2.71,0,0,0-2.7,2.7V43.68a2.7,2.7,0,0,0,2.7,2.7h6.61l9.22,6.69a1.77,1.77,0,0,0,2.82-1.43V24.2A1.78,1.78,0,0,0,39.4,22.76Z"/><path className="cls-1" style={{fill:'#fff'}} d="M47.55,31.06l3.53-1.94A2.19,2.19,0,0,0,49,25.29l-3.53,1.94a2.19,2.19,0,1,0,2.11,3.83Z"/><path className="cls-1" style={{fill:'#fff'}} d="M52.77,35.75h-4a2.19,2.19,0,1,0,0,4.38h4a2.19,2.19,0,1,0,0-4.38Z"/><path className="cls-1" style={{fill:'#fff'}} d="M51.08,46.75l-3.53-1.94a2.2,2.2,0,0,0-3,.86h0a2.2,2.2,0,0,0,.87,3L49,50.59a2.19,2.19,0,0,0,2.11-3.84Z"/></svg>  
  </SvgIcon>
);

const Link = (props) => (
    <SvgIcon {...props}>    
      <svg id="Livello_1" data-name="Livello 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 75.83 75.83"><defs></defs><circle className="cls-1" style={{fill:'#353535'}} cx="37.92" cy="37.92" r="37.92"/><path className="cls-1" style={{fill:'#fff'}} d="M40,45.79h-.23a9.82,9.82,0,0,1-3.67-.71.59.59,0,0,0-.63.12l-4.33,4.34a3.44,3.44,0,1,1-4.87-4.87l7.33-7.32a3.43,3.43,0,0,1,4.87,0,2.35,2.35,0,0,0,3.22,0,2.27,2.27,0,0,0,0-3.23A8.37,8.37,0,0,0,40.36,33,8.09,8.09,0,0,0,36,31.77a8,8,0,0,0-5.68,2.35L23,41.43a8,8,0,0,0,5.69,13.71A7.93,7.93,0,0,0,34.4,52.8l6-6a.58.58,0,0,0,.17-.41A.57.57,0,0,0,40,45.79Z"/><path className="cls-1" style={{fill:'#fff'}} d="M52.78,23.05a8,8,0,0,0-11.36,0l-6,6a.58.58,0,0,0,.42,1H36a9.83,9.83,0,0,1,3.67.72.57.57,0,0,0,.62-.12l4.32-4.32a3.45,3.45,0,0,1,4.88,4.87l-5.39,5.38,0,0L42.2,38.49a3.42,3.42,0,0,1-4.86,0A2.29,2.29,0,0,0,33.44,40a2.32,2.32,0,0,0,.67,1.77,8,8,0,0,0,2.3,1.61l.36.15a2.79,2.79,0,0,0,.37.14l.36.12.34.09c.23.06.46.11.7.15a8.14,8.14,0,0,0,.85.08h.44l.34,0c.13,0,.26,0,.41,0h.2l.39-.05.19,0,.33-.07h.06a8,8,0,0,0,3.73-2.11l7.3-7.31A8,8,0,0,0,52.78,23.05Z"/></svg>
    </SvgIcon>
);

const Testo = (props) => (
    <SvgIcon {...props}>  
      <svg id="Livello_1" data-name="Livello 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 75.83 75.83"><defs></defs><circle className="cls-1" style={{fill:'#353535'}} cx="37.92" cy="37.92" r="37.92"/><path className="cls-1" style={{fill:'#fff'}} d="M31.12,32.47h9.79a1.57,1.57,0,1,0,0-3.13H31.12a1.57,1.57,0,1,0,0,3.13Z"/><path className="cls-1" style={{fill:'#fff'}} d="M31.11,40H44.67a1.57,1.57,0,0,0,0-3.13H31.11a1.57,1.57,0,0,0,0,3.13Z"/><path className="cls-1" style={{fill:'#fff'}} d="M53.32,43.36V21.08a1.57,1.57,0,0,0-1.56-1.54H24.08a1.57,1.57,0,0,0-1.57,1.57V32.47h0V54.75a1.57,1.57,0,0,0,1.57,1.54H51.75a1.56,1.56,0,0,0,1.57-1.56V43.36Zm-3.13,6v3.85H25.64V22.67H50.19Z"/><path className="cls-1" style={{fill:'#fff'}} d="M40.91,44H31.12a1.57,1.57,0,1,0,0,3.13h9.79a1.57,1.57,0,1,0,0-3.13Z"/></svg>
    </SvgIcon>
);

const Video = (props) => (
  <SvgIcon {...props}>  
    <svg id="Livello_1" data-name="Livello 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 75.83 75.83"><defs></defs><circle className="cls-1" style={{fill:'#353535'}} cx="37.92" cy="37.92" r="37.92"/><path className="cls-2" style={{fill:'#fff'}} d="M30.75,52.41a1.21,1.21,0,0,1-1.21-1.21V24.63a1.21,1.21,0,0,1,1.21-1.21,1.18,1.18,0,0,1,.63.18l22,13.28a1.2,1.2,0,0,1,.41,1.66,1.15,1.15,0,0,1-.41.41l-22,13.29a1.27,1.27,0,0,1-.63.17"/></svg>
  </SvgIcon>
);

const RassegnaIcon = ({type, color, dimension}) => {
  const iconStyles = {
    height: dimension ? dimension : 'unset',
    width: dimension ? dimension : 'unset'
  };
  
  return (
  <div>
    {type === 'video' && <Video style={iconStyles} />}
    {type === 'podcast' && <Audio style={iconStyles}/>}
    {type === 'site' && <Link style={iconStyles}/>}
    {type === 'article' && <Testo style={iconStyles}/>}
  </div>
)};

export default RassegnaIcon;