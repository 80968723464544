import React from "react"

import CircularProgress from '@material-ui/core/CircularProgress'
import loaderStyle from "./LoaderStyle"
import withStyles from "@material-ui/core/styles/withStyles"

const Loader = ({classes, color}) => {

  return (
    <CircularProgress thickness={3} classes={{root: `${classes.loader} ${color === 'dark' && classes.dark} ${color === 'light' && classes.light}`}}/>
  )
}

export default withStyles(loaderStyle)(Loader);