import {
    garamond,
    haylardDisplayBold,
    whiteButton,
    whiteButtonHover,
    greyButton,
    greyButtonHover,
    titleLight,
    titleDark,
    titleMedium,
    title
  } from "../../style/style.jsx";
  
import colors from "../../style/colors.scss";


const privacyStyle = theme => ({

    hidden: {
        display: 'none'
    },
    absolute: {
        position: 'absolute'
    },
    titleDark: {
        ...titleDark
    },
    titleLight: {
        ...titleLight
    },
    whiteButton : {
        ...whiteButton,
        '&:hover': {
            ...whiteButtonHover
        }
    },
    greyButton : {
        ...greyButton,
        '&:hover': {
            ...greyButtonHover
        }
    },
    privacyContainer : {
        width:'100%',
        marginTop: '64px',
        padding: '0px 20px'
    },
    privacySection: {
        margin: '50px 0px'
    },
    subTitle : {
        ...haylardDisplayBold,
        fontSize: '12px',
        marginTop: '30px',
        color: colors.darkGrey,
        textTransform: 'uppercase'
    },
    title : {
        ...title,
        [theme.breakpoints.down('sm')] : {
            fontSize: '16px'
        }
    },
    titleMedium : {
        ...titleMedium,
        [theme.breakpoints.down('sm')] : {
            fontSize: '20px'
        }
    },
    linkCta : {
        ...haylardDisplayBold,
        fontSize: '12px',
        marginTop: '30px',
        color: colors.darkGrey,
        textTransform: 'uppercase',
        cursor: 'pointer',
        "& a": {
            color: colors.darkGrey,
            textDecoration: 'none'
        }
    },
    linkCtaBorder: {
        borderBottom: '1px solid',
        borderColor: colors.darkGrey,
        width: '150px'
    },
    description : {
        ...garamond,
        marginTop: '20px'
    }
});

export default privacyStyle;