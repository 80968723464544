import React from 'react';
import SvgIcon from "@material-ui/core/SvgIcon";

const ArrowBack = (props) => (
  <SvgIcon {...props}>    
    <svg id="Livello_1" data-name="Livello 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.58 25"><defs></defs><polygon className="cls-1" points="12.5 25 14.58 22.92 4.17 12.5 14.58 2.08 12.5 0 0 12.5 12.5 25"/></svg>
  </SvgIcon>
);

const ArrowForward = (props) => (
    <SvgIcon {...props}>    
        <svg id="Livello_1" data-name="Livello 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.58 25"><defs></defs><polygon className="cls-1" points="2.08 0 0 2.08 10.42 12.5 0 22.92 2.08 25 14.58 12.5 2.08 0"/></svg>    
    </SvgIcon>
);

const ArrowDown = (props) => (
    <SvgIcon {...props}>  
        <svg id="Livello_1" data-name="Livello 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 12.45"><defs></defs><polygon className="cls-1" points="10.94 12.45 0 1.51 1.38 0.13 10.94 9.68 20.62 0 22 1.38 10.94 12.45"/></svg>  
    </SvgIcon>
);

const Arrow = ({direction, color, dimension}) => {
  const iconStyles = {
    color : color ? color : 'black',
    height: dimension ? dimension : 'unset',
    width: dimension ? dimension : 'unset'
  };
  
  return (
  <div>
    {direction === 'back' && <ArrowBack style={iconStyles} />}
    {direction === 'forward' && <ArrowForward style={iconStyles}/>}
    {direction === 'down' && <ArrowDown style={iconStyles}/>}
  </div>
)};

export default Arrow;