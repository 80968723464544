import React, { useState, useEffect } from 'react'
import { navigate } from "gatsby"

import Grid from '@material-ui/core/Grid'
import projectsStyle from "./projectsStyle.jsx"
import withStyles from "@material-ui/core/styles/withStyles"
import { Button } from '@material-ui/core'

import CercaMappa from '../../images/icons/CercaMappa'
import CercaZona from '../../images/icons/CercaZona'
import { zoneCards } from '../../constants/NewHomeCard'
import copy from '../../constants/copy.json'

import format from '../../utils/format'
import moment from 'moment'
import 'moment/locale/it'

const ProjectCard = ({ classes, item, last, isSoldOut, zoneTab }) => {

  const zone = (item.acfResidenza.zone !== "italia" && item.acfResidenza.zone !== "hinterland") ? 'milano' : zoneCards.find((z) => z.zoneCode === item.acfResidenza.zone).zoneUrl;

  return (

    <Grid container item xs={12} md={6} className={`${classes.card} ${last ? '' : classes.cardBorder}`} direction='row-reverse'>
      {item.acfResidenza.soldout === 'soldOut' ?
        <Grid container item xs={12} sm={8} md={7} lg={8} className={classes.backgroundImage} style={{ backgroundImage: `url(${item.acfResidenza.imagepreview && item.acfResidenza.imagepreview.sourceUrl})`, cursor: 'pointer' }} onClick={() => navigate(`/nuove-costruzioni-${zone}/${item.acfResidenza.seourl ? item.acfResidenza.seourl : item.acfResidenza.name.trim()}`)}>
          <Grid container className={classes.cardOverlayBox + ' ' + classes.overflowHidden} alignContent='flex-end'>
            <Grid container className={classes.soldoutBox} direction='column' justify='center' alignItems='center'>
              <Grid item className={classes.titleSoldOut}>
                {copy.progetti.projectPage.soldOut.soldOut}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        :
        <Grid container item xs={12} sm={8} md={7} lg={8} className={classes.backgroundImage} style={{ backgroundImage: `url(${item.acfResidenza.imagepreview && item.acfResidenza.imagepreview.sourceUrl})` }}>
          {item.acfResidenza.soldout === 'boxOnly' &&
            <Grid container className={classes.cardOverlayBox} alignContent='flex-end'>
              <Grid container className={classes.onlyBox} direction='column' justify='center' alignItems='center'>
                <Grid item className={classes.subTitleBox}>
                  {copy.progetti.projectPage.boxOnly.bannerTitle}
                </Grid>
                <Grid item className={classes.titleBox}>
                  {copy.progetti.projectPage.boxOnly.bannerSubtitle}
                </Grid>
              </Grid>
            </Grid>}
        </Grid>}
      {isSoldOut ?
        <Grid container item xs={12} sm={4} md={5} lg={4} className={classes.cardInfo + ' ' + classes.cardInfoGrey} direction='column' alignContent='center' alignItems='center' justify='space-between'>
          <Grid item className={classes.logoContainer}>
            <img className={classes.logo} alt='' src={item.acfResidenza.logo && item.acfResidenza.logo.sourceUrl} />
          </Grid>
          <Grid>
            <Grid item className={classes.title}>
              {item.acfResidenza.name}
            </Grid>
            <Grid item className={classes.address}>
              {item.acfResidenza.address.streetName}, {item.acfResidenza.address.streetNumber}
            </Grid>
            <Grid item className={classes.addressCity}>
              {item.acfResidenza.cittaIniziativa ?? item.acfResidenza.address.city ?? item.acfResidenza.zonedescription}
            </Grid>
          </Grid>
          <Grid container item justify='center'>
            <Grid container item xs={4} sm={6} justify='center' alignContent='flex-start'>
              <Grid container item className={classes.subTitleCircle} justify='center'>
                {copy.progetti.projectPage.appartamenti}
              </Grid>
              <Grid container item className={classes.priceCircle + ' ' + classes.circleFontMedium} alignItems='center' justify='center'>
                {item.acfResidenza.apartments}
              </Grid>
            </Grid>
            <Grid container item xs={4} sm={6} alignContent='flex-start' justify='center'>
              <Grid container item className={classes.subTitleCircle} justify='center'>
                Zona
              </Grid>
              {(item.acfResidenza.zone !== "italia" && item.acfResidenza.zone !== "hinterland") ?
                <Grid container item className={classes.priceCircle} alignContent='center' justify='center'>
                  <CercaZona color='black' dimension='50px' zoneId={item.acfResidenza.zone} />
                </Grid>
                :
                <Grid container item justify='center'>
                  <CercaMappa black={true} dimension='70px' zoneCode={item.acfResidenza.zone} />
                </Grid>}
            </Grid>
          </Grid>
        </Grid>
        :
        <Grid container item xs={12} sm={4} md={5} lg={4} className={classes.cardInfo} direction='column' alignContent='center' alignItems='center' justify='space-between'>
          <Grid>
            <Grid item className={classes.title}>
              {item.acfResidenza.name}
            </Grid>
            <Grid item className={classes.address}>
              {item.acfResidenza.address.streetName}, {item.acfResidenza.address.streetNumber}
            </Grid>
            <Grid item className={classes.addressCity}>
              {item.acfResidenza.cittaIniziativa ?? item.acfResidenza.address.city ?? item.acfResidenza.zonedescription}
            </Grid>
          </Grid>
          <Grid container item justify='center'>
            {zoneTab ?
              <Grid container item xs={4} sm={6} justify='center' alignContent='flex-start'>
                <Grid container item className={classes.subTitleCircle} justify='center'>
                  {copy.progetti.projectPage.appartamenti}
                </Grid>
                <Grid container item className={classes.priceCircle} alignItems='center' justify='center'>
                  {item.acfResidenza.apartments}
                </Grid>
              </Grid>
              :
              <Grid container item xs={4} sm={6} alignContent='flex-start' justify='center'>
                <Grid container item className={classes.subTitleCircle} justify='center'>
                  Zona
            </Grid>
                {(item.acfResidenza.zone !== "italia" && item.acfResidenza.zone !== "hinterland") ?
                  <Grid container item className={classes.subTitle + ' ' + classes.priceCircle} alignContent='center' justify='center'>
                    <CercaZona color='black' dimension='50px' zoneId={item.acfResidenza.zone} />
                  </Grid>
                  :
                  <Grid container item className={classes.subTitle} justify='center'>
                    <CercaMappa black={true} dimension='70px' zoneCode={item.acfResidenza.zone} />
                  </Grid>}
              </Grid>}
            {!item.acfResidenza.hideprice &&
              <Grid container item xs={4} sm={6} justify='center' alignContent='flex-start'>
                <Grid container item className={`${classes.subTitleCircle}`} justify='center' style={{ textTransform: 'none' }}>
                  {copy.progetti.projectPage.prezzo}
                </Grid>
                <Grid container item className={classes.priceCircle} alignItems='center' justify='center'>
                  {format.currencyFormat(item.acfResidenza.price)}
                </Grid>
              </Grid>}
            <Grid container item justify="center" alignItems="center" style={{ marginTop: '40px' }}>
              <Button classes={{ root: classes.greyButton }} onClick={() => navigate(`/nuove-costruzioni-${zone}/${item.acfResidenza.seourl ? item.acfResidenza.seourl : item.acfResidenza.name.trim()}`)}>
                SCOPRI
            </Button>
            </Grid>
          </Grid>
        </Grid>
      }
    </Grid>
  )
};

export default withStyles(projectsStyle)(ProjectCard)
