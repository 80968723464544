import React from "react"
import Grid from '@material-ui/core/Grid'

const HeroOnlyImage = ({classes, img, altText, otherClasses}) => {

  return (
    <Grid container item xs={12} className={otherClasses} style={{backgroundImage : `url(${img})`}}>
    </Grid>
  )
}

export default HeroOnlyImage
