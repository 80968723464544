import {
  Button, Checkbox, FormControl, InputLabel,
  MenuItem, Select, TextField
} from "@material-ui/core"
import Grid from "@material-ui/core/Grid"
import withStyles from "@material-ui/core/styles/withStyles"
import React, { useEffect, useState } from "react"
import copy from "../../constants/copy.json"
import {
  lavoraConNoiApplication
} from "../../constants/endpoints.jsx"
import CheckboxIcon from "../../images/icons/Checkbox"
import Loader from "../Loader/Loader"
import DropZone from "../Other/DropZone"
import contactLavoraConNoiStyle from "./contactLavoraConNoiStyle"
import { PrivacyPdf } from "../../constants/links"



const ContactLavoraConNoi = ({ classes }) => {
  const [loading, setLoading] = useState(false)

  const [error, setError] = useState(false)
  const [success, setSuccess] = useState(false)

  const UrlParametersToSend = {};

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const entries = urlParams.entries();
    for (const entry of entries) {
      UrlParametersToSend[entry[0]] = entry[1];
    }
  }, [UrlParametersToSend]);

  const [data, setData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    message: "", // text area messaggio
    workingArea: "", // area di lavoro,
    consent1: false, // checkbox 1
  });

  const [file, setFile] = useState(undefined);

  const handleFiles = (files) => {
    if (files.length)
      handleChangeData("cv", files[0]);
    else handleChangeData("cv", undefined)
  }

  const handleChangeData = (param, e) => {
    if (param === "consent1") {
      setData({ ...data, [param]: e.target.checked })
    } else if (param === "cv") {
      //setData({ ...data, [param]: e })
      setFile(e);
    }
    else {
      setData({ ...data, [param]: e.target.value })
    }
  }

  const handleSubmit = event => {
    event.preventDefault()
    setError(false)
    setSuccess(false)
    setLoading(true)

    let form_data = new FormData();
    form_data.append("application", JSON.stringify(data));
    form_data.append("cv", file);

    if (UrlParametersToSend) {
      try {
        form_data.append("utm", JSON.stringify(UrlParametersToSend));
      } catch (e) {
        console.log(e)
      }
    }

    // converto json in FormData
    /*for (let key in data) {
      form_data.append(key, data[key]);
    }*/

    fetch(lavoraConNoiApplication, {
      method: "POST",
      body: form_data
    })
      .then(results => {
        return results.json()
      })
      .then(response => {
        var responseCode = response.statusCode
        if (responseCode !== 200 && responseCode !== 401) {
          console.error(
            "Error calling " +
            lavoraConNoiApplication +
            ": " +
            response.error.type +
            "\n\n" +
            response.error.description
          )
          setError(true)
          setLoading(false)
        } else {
          const emptyData = {
            firstName: null,
            lastName: null,
            email: null,
            phone: null,
            message: null, // text area messaggio
            workingArea: null, // area di lavoro,
            consent1: false, // checkbox 1
          }
          setData(emptyData)
          setSuccess(true)
          setLoading(false)
        }
      })
  }

  return (
    <Grid container className={classes.background}>
      <Grid container className={classes.formContainer}>
        <Grid
          container
          direction="column"
          justify="center"
          alignItems="center"
          className={classes.contactContainer}
        >
          <Grid item className={classes.subTitle + " " + classes.titleDark}>
            {copy.lavoraConNoi.form.title}
          </Grid>
          {loading && <Loader color="dark" />}
          {error && !loading && (
            <Grid item className={classes.subTitle + " " + classes.error}>
              {copy.lavoraConNoi.form.error}
            </Grid>
          )}
          {success && !loading && (
            <Grid
              container
              className={classes.subTitle + " " + classes.titleDark + " " + classes.small}
              direction="row"
              justify="center"
              style={{ margin: "10px 0px" }}
            >
              <Grid item>
                <CheckboxIcon dimension="30px" color="#353535" />
              </Grid>
              <Grid item style={{ alignSelf: "flex-end", fontSize: '30px', fontWeight: 'bold' }}>
                {copy.lavoraConNoi.form.success}
              </Grid>
            </Grid>
          )}
          {!loading && (
            <form onSubmit={handleSubmit}>
              <Grid container>
                <FormControl classes={{ root: classes.formControlRoot }}>
                  <Grid container direction="row">
                    <Grid
                      container
                      item
                      xs={12}
                      sm={6}
                      direction="column"
                      className={classes.contactSection1}
                    >
                      <TextField
                        label={copy.lavoraConNoi.form.nome}
                        onChange={e => handleChangeData("firstName", e)}
                        value={data.firstName ? data.firstName : ""}
                        required
                        classes={{ root: classes.textFieldRoot }}
                        InputLabelProps={{
                          classes: {
                            root: classes.inputContactPropsRoot,
                          },
                        }}
                        InputProps={{
                          classes: {
                            root: classes.inputContactPropsRoot,
                            underline: classes.contactTextFieldFocused,
                          },
                        }}
                      />
                      <TextField
                        label={copy.lavoraConNoi.form.cognome}
                        onChange={e => handleChangeData("lastName", e)}
                        value={data.lastName ? data.lastName : ""}
                        required
                        classes={{ root: classes.textFieldRoot }}
                        InputLabelProps={{
                          classes: {
                            root: classes.inputContactPropsRoot,
                          },
                        }}
                        InputProps={{
                          classes: {
                            root: classes.inputContactPropsRoot,
                            underline: classes.contactTextFieldFocused,
                          },
                        }}
                      />
                      <TextField
                        label={copy.lavoraConNoi.form.email}
                        type="email"
                        onChange={e => handleChangeData("email", e)}
                        value={data.email ? data.email : ""}
                        required
                        classes={{ root: classes.textFieldRoot }}
                        InputLabelProps={{
                          classes: {
                            root: classes.inputContactPropsRoot,
                          },
                        }}
                        InputProps={{
                          classes: {
                            root: classes.inputContactPropsRoot,
                            underline: classes.contactTextFieldFocused,
                          },
                        }}
                      />
                      <TextField
                        label={copy.lavoraConNoi.form.tel}
                        value={data.phone ? data.phone : ""}
                        type="tel"
                        onChange={e => handleChangeData("phone", e)}
                        required
                        classes={{ root: classes.textFieldRoot }}
                        InputLabelProps={{
                          classes: {
                            root: classes.inputContactPropsRoot,
                          },
                        }}
                        inputProps={{
                          pattern: "^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-/0-9]*$",
                        }}
                        InputProps={{
                          classes: {
                            root: classes.inputContactPropsRoot,
                            underline: classes.contactTextFieldFocused,
                          },
                        }}
                      />
                      <TextField
                        label={copy.lavoraConNoi.form.messaggio}
                        onChange={e => handleChangeData("message", e)}
                        value={data.message ? data.message : ""}
                        required
                        multiline
                        rows={8}
                        classes={{ root: classes.textFieldRoot }}
                        InputLabelProps={{
                          classes: {
                            root: classes.inputContactPropsRoot,
                          },
                        }}
                        InputProps={{
                          classes: {
                            root: classes.inputContactPropsRoot,
                            underline: classes.contactTextFieldFocused,
                          },
                        }}
                      />
                    </Grid>
                    <Grid
                      container
                      item
                      xs={12}
                      sm={6}
                      direction="column"
                      className={classes.contactSection2}
                    >
                      <FormControl required>
                        <InputLabel
                          id="workingAreaLabel"
                          classes={{
                            root: classes.inputContactPropsRoot,
                            formControl: classes.formControl,
                          }}
                        >
                          {copy.lavoraConNoi.form.area_lavoro}
                        </InputLabel>
                        <Select
                          labelId="workingAreaLabel"
                          id="workingArea"
                          name="workingArea"
                          key="workingArea"
                          value={data.workingArea ? data.workingArea : ''}
                          onChange={e => handleChangeData("workingArea", e)}
                          required
                          className={
                            classes.select + " " + classes.underlinedInput
                          }
                          inputProps={{
                            classes: {
                              root: classes.inputContactPropsRoot,
                            },
                          }}
                        >
                          {copy.lavoraConNoi.form.aree_lavoro &&
                            copy.lavoraConNoi.form.aree_lavoro.map((item, index) => (
                              <MenuItem
                                key={index}
                                className={classes.inputContactPropsRoot}
                                value={item.name}
                              >
                                {item.name}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                      <Grid container className={classes.dropFile}>
                        <div className={classes.cvTitle}>
                          {copy.lavoraConNoi.form.cv}
                        </div>
                        <DropZone title="carica il tuo cv" handleFiles={handleFiles} multiple={false}></DropZone>
                      </Grid>

                      <Grid className={classes.checkBoxContainer}>
                        <Grid container direction="row" spacing={1} className={`${classes.unwrappedFlex} ${classes.checkboxMarginTop}`}>
                          <div>
                            <Checkbox
                              required={true}
                              name="consent1"
                              onChange={e => handleChangeData("consent1", e)}
                              value={data.consent1 || ""}
                              checkedIcon={
                                <div className={classes.checkedIcon}>
                                  <CheckboxIcon
                                    dimension="13px"
                                    color="white"
                                    top="-5px"
                                  />
                                </div>
                              }
                              icon={<div className={classes.icon} />}
                              classes={{
                                root: classes.checkBoxRoot,
                              }}
                            />
                          </div>
                          <div className={classes.checkBoxLabel} >
                            {copy.lavoraConNoi.form.check1}
                            &nbsp;
                            <a className={classes.checkBoxLabel + ' ' + classes.checkBoxLabelLink} href={PrivacyPdf} target="_blank" rel="noreferrer">
                              {copy.lavoraConNoi.form.check1Link}
                            </a>
                          </div>
                        </Grid>
                      </Grid>

                      <Grid container justify="center" className={classes.submitButtonContainer}>
                        <Button type="submit" classes={{ root: classes.greyButton }}>
                          {copy.lavoraConNoi.form.submit}
                        </Button>
                      </Grid>

                    </Grid>
                  </Grid>
                </FormControl>
              </Grid>
            </form>
          )}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default withStyles(contactLavoraConNoiStyle)(ContactLavoraConNoi)
