import React from 'react'
import { useStaticQuery, graphql, navigate } from "gatsby"

import Grid from '@material-ui/core/Grid'
import homeStyle from "./homeStyle.jsx"
import withStyles from "@material-ui/core/styles/withStyles"
import Header from "../Header/Header"
import { Button, Divider, RootRef } from '@material-ui/core'

import LifeStyleCarousel from '../Carousel/LifestyleCarousel/LifeStyleCarousel'
import HomeCarousel from '../Carousel/HomeCarousel'
import Hero from './Hero'
import Introduction from './Introduction'
import Footer from '../Footer/Footer'
import NewsLetter from '../NewsLetter/NewsLetter'
import Contact from '../Contact/Contact'
import HomeCard from './HomeCard'
import { zoneCards } from '../../constants/NewHomeCard'
import MessengerIcon from './MessengerIcon'

import gallery1 from "../../images/home_scopri1.jpg"
import gallery2 from "../../images/home_scopri2.jpg"
import gallery3 from "../../images/home_scopri3.jpg"
import gallery4 from "../../images/home_scopri4.jpg"

import copy from '../../constants/copy.json'

const Home = ({ classes }) => {

  const contactRef = React.useRef();

  const carouselRes = useStaticQuery(graphql`
  {
    wpgraphql {
      residenzeComingSoon(first: 1000) {
        nodes {
          acfResidenzaComingSoon {
            description
            place
            residenza {
              ... on WPGraphQL_Residenza {
                id
              }
            }
            title
            image {
              sourceUrl
            }
          }
        }
      }
      residenzePrimoPiano(first: 1000) {
        nodes {
          acfResidenzaPrimoPiano {
            title
            order
            description
            residenza {
              ... on WPGraphQL_Residenza {
                id
                acfResidenza {
                  name
                  zone
                  seourl
                }
              }
            }
            image {
              sourceUrl
            }
          }
        }
      }
    }
  }`);

  const primoPianoCards = carouselRes && carouselRes.wpgraphql && carouselRes.wpgraphql.residenzePrimoPiano && carouselRes.wpgraphql.residenzePrimoPiano.nodes.sort((a, b) => { return (b.acfResidenzaPrimoPiano.order != null) - (a.acfResidenzaPrimoPiano.order != null) || a.acfResidenzaPrimoPiano.order - b.acfResidenzaPrimoPiano.order });
  const comingSoonCards = carouselRes && carouselRes.wpgraphql && carouselRes.wpgraphql.residenzeComingSoon && carouselRes.wpgraphql.residenzeComingSoon.nodes;

  const homeCards = zoneCards.map((item, idx) => {
    return (
      <HomeCard item={item} key={idx} />
    )
  });

  return (
    <div style={{ height: "100%" }}>
      <Header home={true} />
      <Hero />
      <Grid style={{ position: 'fixed', zIndex: '1000', right: '0px', bottom: '15px' }}>
        <Grid container className={classes.iconContainer} alignContent='center' justify='center'>
          <MessengerIcon type='whatsapp' />
        </Grid>
        <Grid container className={classes.iconContainer} alignContent='center' justify='center'>
          <MessengerIcon type='tel' />
        </Grid>
      </Grid>
      <div className={classes.homeContainer}>
        <Introduction />
        <LifeStyleCarousel/>
        <Grid container className={`${classes.mobileShow}`}>
          <Grid container justify='center' style={{margin: '5px 25px 50px 25px'}}>
            <Divider className={classes.divider} />
          </Grid>
        </Grid>
        {primoPianoCards &&
          <>
            <Grid container className={classes.carouselContainer}>
              <Grid container alignContent='center' alignItems='center' justify='center' className={classes.sectionTitle}>
                {copy.home.primoPiano.title}
              </Grid>
              <HomeCarousel type='primoPiano' data={primoPianoCards} />
            </Grid>
            <Grid container className={`${classes.mobileShow}`}>
                <Grid container justify='center' alignItems='center' style={{margin: '50px 25px'}}>
                    <Divider className={classes.divider} />
                </Grid>
            </Grid>
          </>
          }
        <Grid container className={classes.primaCasa}>
          <Grid container alignContent='center' alignItems='center' justify='center' className={classes.sectionTitle + ' ' + classes.mobileShow}>
            {copy.home.cercaCasa.title}
          </Grid>
          {homeCards}
        </Grid>
        {comingSoonCards &&
          <>
            <Grid container className={`${classes.mobileShow}`}>
                <Grid container justify='center' alignItems='center' style={{margin: '50px 25px'}}>
                    <Divider className={classes.divider} />
                </Grid>
            </Grid>
            <Grid container className={classes.carouselContainer}>
              <Grid container alignContent='center' alignItems='center' justify='center' className={classes.sectionTitle + ' ' + classes.mobileShow} />
              <HomeCarousel type='comingSoon' data={comingSoonCards} contactRef={contactRef} />
            </Grid>
          </>}
        <NewsLetter home={true} />
        <Grid className={classes.galleryContainer}>
          <Grid container>
            <Grid item xs={12} md={6} className={classes.galleryContainerImage} style={{ backgroundImage: `url(${gallery1})` }} />
            <Grid container item xs={12} md={6} alignItems="center" direction="column" justify="center" className={classes.galleryIntroduction1}>
              <Grid className={classes.subTitle}>
                {copy.home.centroStudi.subTitle}
              </Grid>
              <Grid className={classes.title + ' ' + classes.titleMaxWidth}>
                {copy.home.centroStudi.title}
              </Grid>
              <Grid style={{ width: '100px', borderBottom: '2px solid black', marginTop: '10px', marginBottom: '15px' }} />
              <Grid className={classes.description}>
                {copy.home.centroStudi.description}
              </Grid>
              <Grid style={{ marginTop: '50px' }}>
                <Button classes={{ root: classes.greyButton }} onClick={() => navigate("/news")}>
                  {copy.home.centroStudi.cta}
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid container className={classes.galleryContainerImage1 + ' ' + classes.mobileHidden}>
            <Grid item xs={4}>
              <Grid item className={classes.galleryContainerImage2} style={{ backgroundImage: `url(${gallery2})` }} />
            </Grid>
            <Grid item xs={8}>
              <Grid item className={classes.galleryContainerImage3} style={{ backgroundImage: `url(${gallery3})` }} />
            </Grid>
          </Grid>
          <Grid container direction='row-reverse'>
            <Grid container item xs={12} md={6} className={classes.galleryContainerImage} style={{ backgroundImage: `url(${gallery4})` }} />
            <Grid container item xs={12} md={6} alignItems="center" direction="column" justify="center" className={classes.galleryIntroduction1}>
              <Grid className={classes.subTitle}>
                {copy.home.business.subTitle}
              </Grid>
              <Grid className={classes.title + ' ' + classes.titleMaxWidth}>
                {copy.home.business.title}
              </Grid>
              <Grid style={{ width: '100px', borderBottom: '2px solid black', marginTop: '10px', marginBottom: '15px' }} />
              <Grid className={classes.description}>
                {copy.home.business.description}
              </Grid>
              <Grid style={{ marginTop: '50px' }}>
                <Button classes={{ root: classes.greyButton }} onClick={() => navigate("/real-estate-milano")}>
                  {copy.home.business.cta}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <RootRef rootRef={contactRef}>
          <Contact />
        </RootRef>
      </div>
      <Footer />
    </div>
  )
}

export default withStyles(homeStyle)(Home)
