import homeHero from "../../images/home_hero.jpg"
import homeIntroduction from "../../images/home_abitarepresenta.jpg"

import {
  garamond,
  haylardDisplayBold,
  haylardDisplay,
  whiteButton,
  whiteButtonHover,
  greyButton,
  greyButtonHover,
  greyButtonDisabled,
  italicSubTitleMedium,
  italicSubTitle,
  titleLight,
  titleDark,
  error,
  titleBig,
  titleMedium,
  title,
} from "../../style/style.jsx"

import colors from "../../style/colors.scss"

const homeStyle = theme => ({
  hidden: {
    display: "none",
  },
  absolute: {
    position: "absolute",
  },
  titleDark: {
    ...titleDark,
  },
  titleLight: {
    ...titleLight,
  },
  error: {
    ...error,
  },
  heroSection: {
    color: "white",
    top: "24%",
    position: "relative",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      position: "initial",
      marginBottom: "1em",
    },
  },
  whiteButton: {
    ...whiteButton,
    "&:hover": {
      ...whiteButtonHover,
    },
  },
  greyButton: {
    ...greyButton,
    "&:hover": {
      ...greyButtonHover,
      "& .invertOnHover": {
        filter: "invert(100%)",
      },
    },
  },
  greyButtonDisabled: {
    ...greyButtonDisabled,
  },
  searchIcon: {
    width: "20px",
    height: "20px",
    margin: "auto",
    marginLeft: "10px",
  },
  heroContainer: {
    width: "100%",
    height: "100%",
    backgroundImage: `url(${homeHero})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      height: "90%",
      alignItems: "flex-end",
    },
  },
  homeContainer: {
    width: "100%",
  },
  subTitleHero: {
    ...italicSubTitleMedium,
    fontWeight: "bold",
    margin: "5px",
    textShadow: "black 1px 0 20px",
  },
  titleHero: {
    ...titleBig,
    margin: "5px",
    lineHeight: "1",
    textShadow: "black 1px 0 20px",
  },
  introductionContainer: {
    margin: "200px 200px 25px 200px",
    height: "600px",
    backgroundImage: `url(${homeIntroduction})`,
    backgroundSize: "cover",
    [theme.breakpoints.down("md")]: {
      margin: "100px 100px 25px 100px",
    },
    [theme.breakpoints.down("sm")]: {
      margin: "0px 0px 25px 0px",
      padding: "100px 30px 30px 30px",
      height: "800px",
      alignContent: "flex-end",
    },
  },
  introduction: {
    backgroundColor: "rgba(255, 255, 255, 0.8)",
    height: "100%",
    margin: "auto",
    textAlign: "center",
    color: "black",
    [theme.breakpoints.down("sm")]: {
      height: "60%",
      padding: "10px",
    },
  },
  subTitle: {
    ...italicSubTitle,
    margin: "5px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px",
      textAlign: "center",
    },
    "& h2": {
      ...italicSubTitle,
      fontSize: "inherit",
      fontWeight: "inherit",
      display: "contents",
    },
    "& h1": {
      ...italicSubTitle,
      fontSize: "inherit",
      fontWeight: "inherit",
      display: "contents",
    },
  },
  title: {
    ...title,
    margin: "5px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
    },
    "& h1": {
      ...title,
      margin: "0px",
      fontSize: "inherit",
      display: "contents",
    },
  },
  titleMedium: {
    ...titleMedium,
    margin: "5px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "20px",
    },
  },
  sectionTitle: {
    ...haylardDisplay,
    fontSize: "26px",
    textAlign: "center",
    textTransform: "uppercase",
    [theme.breakpoints.down("sm")]: {
      fontSize: "22px",
    },
  },
  linkCtaWrapper: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
  },
  linkCta: {
    ...haylardDisplayBold,
    fontSize: "12px",
    margin: "5px",
    marginTop: "15px",
    color: colors.red,
    textTransform: "uppercase",
    cursor: "pointer",
    "& a": {
      color: colors.darkGrey,
      textDecoration: "none",
    },
    [theme.breakpoints.down("sm")]: {
      alignItems: "center",
    },
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  linkCtaBorder: {
    borderBottom: "1px solid",
    borderColor: colors.darkGrey,
    width: "40px",
  },
  linkCtaBorderDouble: {
    width: "100px",
  },
  titleMaxWidth: {
    maxWidth: "350px",
  },
  description: {
    ...garamond,
    width: "52%",
    margin: "5px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    "& h2": {
      ...garamond,
      fontWeight: "inherit",
      display: "contents",
      fontSize: "inherit",
    },
  },
  lifestyleCarousel: {
    margin: "25px 200px 20px 200px",
    height: "350px",
    border: "1px solid black",
    [theme.breakpoints.down("md")]: {
      margin: "25px 100px 20px 100px",
    },
    [theme.breakpoints.down("sm")]: {
      margin: "50px 25px",
      height: "100%",
      padding: "8px",
    },
  },
  carouselContainer: {
    marginTop: "250px",
    color: "black",
    [theme.breakpoints.down("sm")]: {
      marginTop: "0px",
    },
  },
  primaCasa: {
    margin: "200px",
    marginBottom: "0",
    width: "auto",
    color: "black",
    [theme.breakpoints.down("md")]: {
      margin: "200px 50px",
    },
    [theme.breakpoints.down("sm")]: {
      margin: "0px",
    },
  },
  primaCasaCard: {
    padding: "20px",
    color: "black",
    textAlign: "center",
  },
  homeCardTitleContainer: {
    [theme.breakpoints.up("md")]: {
      height: "2.6em",
      display: "flex",
      alignItems: "center",
    },
  },
  cardMargin: {
    marginTop: "30px",
  },
  newsLetterContainer: {
    backgroundColor: colors.lightGrey2,
    marginTop: "200px",
    overflow: "hidden",
    [theme.breakpoints.down("sm")]: {
      marginTop: "45px",
    },
  },
  newsLetter: {
    padding: "80px 80px 50px 80px",
    [theme.breakpoints.down("md")]: {
      padding: "50px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "30px",
      textAlign: "center",
      zIndex: "1",
      background: "#ededed",
    },
  },
  newsLetterBoxShadow: {
    backgroundSize: "cover",
    backgroundPosition: "center",
    boxShadow: "#808080 0px 0px 25px 0px",
    [theme.breakpoints.down("sm")]: {
      height: "300px",
    },
  },
  newsLetterBoxShadowNoBkg: {
    boxShadow: "#808080 0px 0px 25px 0px",
    [theme.breakpoints.down("sm")]: {
      height: "300px",
      padding: "50px",
      borderTop: "1px solid " + colors.darkGrey,
      zIndex: "2",
    },
    background: "white",
  },
  social: {
    borderLeft: "1px solid gray",
    height: "300px",
    margin: "auto",
    [theme.breakpoints.down("sm")]: {
      position: "relative",
      borderLeft: "none",
      margin: "20px",
      height: "auto",
      paddingTop: "50px",
      paddingBottom: "50px",
    },
  },
  formControlRoot: {
    width: "100%",
    marginTop: "30px",
    marginBottom: "50px",
    fontStyle: "italic",
  },
  textFieldRoot: {
    marginBottom: "30px",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "15px",
    },
  },
  textFieldInputStyle: {
    paddingBottom: "15px",
  },
  inputPropsRoot: {
    ...garamond,
    background: "white",
    fontSize: "20px",
    borderRadius: "0px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
    },
  },
  inputContactPropsRoot: {
    ...garamond,
    fontSize: "14px",
    color: colors.darkGrey + " !important",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
    },
  },
  select: {
    marginTop: "0px !important",
    marginBottom: "30px",
  },
  underlinedInput: {
    "&:after": {
      borderBottom: "2px solid",
      borderColor: colors.darkGrey,
    },
  },
  formControl: {
    position: "relative",
  },
  selectFormControl: {
    marginTop: "0px !important",
  },
  contactTextFieldFocused: {
    "&:after": {
      borderBottom: "2px solid",
      borderColor: colors.darkGrey,
    },
  },
  textFieldFocused: {
    borderColor: colors.darkGrey + " !important",
  },
  checkBoxLabel: {
    ...haylardDisplay,
    fontSize: "10px",
    lineHeight: "1.8",
    marginTop: "5px",
    paddingLeft: "5px",
    letterSpacing: "1px",
    color: colors.darkGrey,
    [theme.breakpoints.down("sm")]: {
      textAlign: "initial",
    },
  },
  checkBoxLabelLink: {
    textDecoration: "underline",
    cursor: "pointer",
    padding: "0",
  },
  checkedIcon: {
    backgroundColor: colors.darkGrey,
    width: "20px",
    height: "20px",
  },
  checkBoxContainer: {
    margin: "70px 0px 0px 0px",
  },
  icon: {
    border: "2px solid",
    borderColor: colors.grey,
    width: "20px",
    height: "20px",
  },
  galleryContainer: {
    margin: "200px 200px 200px 200px",
    color: "black",
    [theme.breakpoints.down("sm")]: {
      margin: "100px 15px",
    },
  },
  galleryIntroduction1: {
    width: "50%",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      margin: "45px 0px",
    },
  },
  galleryContainerImage: {
    backgroundSize: "cover",
    backgroundPosition: "center",
    height: "730px",
    [theme.breakpoints.down("sm")]: {
      height: "350px",
    },
  },
  galleryContainerImage1: {
    marginTop: "30px",
    marginBottom: "30px",
    height: "300px",
  },
  galleryContainerImage2: {
    backgroundSize: "cover",
    backgroundPosition: "center",
    height: "300px",
    marginRight: "15px",
  },
  galleryContainerImage3: {
    backgroundSize: "cover",
    backgroundPosition: "center",
    height: "300px",
    marginLeft: "15px",
  },
  contactContainer: {
    scrollMarginTop: "64px",
    backgroundColor: colors.lightGrey3,
    padding: "100px 450px",
    minHeight: "750px",
    [theme.breakpoints.down("lg")]: {
      padding: "70px 200px",
    },
    [theme.breakpoints.down("md")]: {
      padding: "50px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "20px",
      zIndex: "1",
    },
  },
  logoContainer: {
    height: "auto",
    width: "100%",
    marginBottom: "10px",
  },
  logo: {
    height: "100%",
    width: "100%",
    maxHeight: "200px",
    maxWidth: "250px",
    objectFit: "contain",
    margin: 0,
    [theme.breakpoints.down("sm")]: {
      maxHeight: "180px",
      maxWidth: "200px",
    },
  },
  contactSection1: {
    borderRight: "1px solid",
    borderColor: colors.darkGrey,
    paddingRight: "40px",
    [theme.breakpoints.down("xs")]: {
      padding: "0px",
      border: "none",
    },
  },
  contactSection2: {
    paddingLeft: "40px",
    [theme.breakpoints.down("xs")]: {
      padding: "0px",
    },
  },
  contactForm: {
    [theme.breakpoints.up("md")]: {
      width: "60%",
      minWidth: "720px",
    },
  },
  link: {
    ...haylardDisplayBold,
    color: colors.grey,
    fontSize: "14px",
    cursor: "pointer",
    marginTop: "2px",
    marginBottom: "2px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
    },
  },
  border100: {
    width: "100px",
    borderBottom: "3px solid black",
    marginTop: "10px",
    marginBottom: "15px",
  },
  border60: {
    width: "60px",
    borderBottom: "3px solid black",
    marginTop: "10px",
    marginBottom: "40px",
  },
  noMarginTop: {
    marginTop: "0px",
  },
  backgroundImage: {
    height: "100%",
    width: "100%",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  primaCasaCardImage: {
    height: "600px",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      height: "300px",
    },
  },
  mobileHidden: {
    [theme.breakpoints.down("sm")]: {
      display: "none !important",
    },
  },
  mobileShow: {
    display: "none !important",
    [theme.breakpoints.down("sm")]: {
      display: "flex !important",
    },
  },
  separatorMobile: {
    [theme.breakpoints.down("sm")]: {
      margin: "0px 20px",
      paddingTop: "45px",
      marginTop: "45px",
    },
  },
  iconContainer: {
    width: "45px",
    height: "45px",
    borderRadius: "50px",
    alignSelf: "flex-end",
    margin: "4px",
    marginBottom: "10px",
    marginRight: "10px",
    [theme.breakpoints.down("md")]: {
      width: "35px",
      height: "35px",
      marginBottom: "10px",
      marginRight: "10px",
    },
  },
  iconShare: {
    cursor: "pointer",
  },
  iconShareContainer: {
    display: "block",
    widht: "100%",
    height: "100%",
  },
  cardOverlay: {
    backgroundColor: "rgba(0,0,0,0.5)",
    height: "100%",
  },
  socialIconWithHover: {
    width: "40px",
    height: "40px",
    margin: "10px",
    cursor: "pointer",
    color: colors.grey,
  },
  socialBackGround: {
    [theme.breakpoints.down("sm")]: {
      position: "absolute",
      top: "-21px",
      left: "-20px",
      background: "#ededed",
      width: "calc(100% + 40px)",
      height: "calc(100% + 40.5px)",
      zIndex: 1,
    },
  },
  divider: {
    backgroundColor: "black",
    width: "100%",
  },
  socialDivider: {
    [theme.breakpoints.down("sm")]: {
      position: "absolute",
      top: "-1px",
      backgroundColor: "gray",
      width: "100%",
      height: "1px",
      zIndex: "1",
    },
  },
  socialLogosContainer: {
    [theme.breakpoints.down("sm")]: {
      zIndex: "1",
    },
  },
  facebookHover: {
    "&:hover": {
      color: "#4267B2",
    },
  },
  instagramHover: {
    "&:hover": {
      color: "#F00075",
    },
  },
  linkedinHover: {
    "&:hover": {
      color: "#0077B7",
    },
  },
  youtubeHover: {
    "&:hover": {
      color: "#FF0000",
    },
  },
})

export default homeStyle
