import React from 'react'

import Grid from '@material-ui/core/Grid'
import mapStyle from './mapStyle'
import withStyles from "@material-ui/core/styles/withStyles"
import { Button } from '@material-ui/core'

import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import CercaMappa from '../../../images/icons/CercaMappa'

import copy from '../../../constants/copy.json'

const MapCard = ({classes, item, idx, selectedTab, changeZone, height}) => {
  
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));

  return (

    <Grid container item justify='center' onClick={() => changeZone(item.zoneUrl)} style={{height: height}}>
      <Grid container justify='center' direction='column' style={{cursor : 'pointer'}} >
        <Grid container item alignItems='center' justify='center' alignContent='center' style={{
            backgroundImage : `url(${item.zone})`, 
            height:'100%',
            width: '100%',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            marginBottom: idx > 1 ? '0px' : '3px'
          }}>
              <Grid container direction='row' justify='center' alignContent='center' className={`${classes.cardOverlay} ${item.zoneCode === selectedTab ? classes.cardOverlaySelected : ''}`}>
                <Grid item md={4} lg={3}>
                  <CercaMappa black={item.zoneCode === selectedTab} dimension={matches && '100px'} zoneCode={item.zoneCode}/>
                </Grid>
                <Grid container item md={8} lg={9} direction='column' alignItems='center' justify='center'>
                  <Grid item className={`${classes.subTitle} ${item.zoneCode === selectedTab ? classes.titleDark : classes.titleWhite}`}>
                    {item.zoneCode === selectedTab ? copy.progetti.cercaSelected : copy.progetti.cerca}
                  </Grid>
                  <Grid container item justify="center" alignItems="center">
                    <Button classes={{root: item.zoneCode === selectedTab ? classes.whiteButtonBorder : classes.greyButtonBorder}}>
                      {item.cta}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
};

export default withStyles(mapStyle)(MapCard)
