import homeStyle from '../Home/homeStyle'
import projectsStyle from '../Projects/projectsStyle'

import {
    garamond,
    titleBig,
    italicSubTitleMedium
  } from "../../style/style.jsx";
  
import colors from "../../style/colors.scss";


const projectsStyleZone = theme => ({
    ...homeStyle(theme),
    ...projectsStyle(theme),
    subTitle: {
      ...italicSubTitleMedium,
      margin: '5px',
      '& h1':{
        ...italicSubTitleMedium,
        fontWeight: 'inherit',
        display: 'contents'
      }
    },
    sectionTitleUnderline : {
      ...titleBig,
      color: 'black',
      textDecoration: 'underline',
      textUnderlinePosition: 'under',
      marginBottom: '20px',
      textAlign: 'center',
      [theme.breakpoints.down('sm')] : {
        fontSize: '20px'
      },
      '& h1': {
        ...titleBig,
        fontSize: 'inherit',
        display: 'contents'
      }
    },
    description : {
      ...garamond,
      width: '30%',
      margin: '25px',
      textAlign: 'center',
      [theme.breakpoints.down('sm')] : {
        width: '100%',
        letterSpacing: '1px'
      }
    },
    zoneTabContainer: {
      padding: '50px 210px 100px 210px',
      [theme.breakpoints.down('sm')] : {
        padding: '50px 0px'
      }
    },
    zoneTabSection: {
      background: colors.lightGrey3,
      padding: '50px',
      width: '19.8%',
      height: '350px',
      cursor: 'pointer',
      borderBottom: '3px solid white',
      [theme.breakpoints.down('lg')] : {
        padding: '40px 10px'
      }
    },
    anchorLinkStyle: {
      color: 'black',
      textDecoration: 'none'
    },
    noPointer: {
      cursor: 'auto !important'
    },
    zoneTabSectionSelected: {
      background: colors.lightGrey2,
      width: '19.8%',
      height: '350px',
      cursor: 'pointer',
      borderBottom: '3px solid ' + colors.lightGrey2
    },
    sectionZoneCard: {
      height: '290px',
      width: '50%',
      background: 'transparent',
      border: 'none'
    },
    descriptionZone : {
      ...garamond,
      marginTop: '100px',
      width: '50%',
      textAlign: 'center',
      [theme.breakpoints.down('sm')] : {
        width: '100%',
        padding: '30px',
        letterSpacing: '1px',
        marginTop: '30px',
      }
    },
    zoneSectionContainer: {
      padding: '30px 20px',
      background: colors.lightGrey2,
      color: 'black'
    },
    zoneSection: {
      padding: '0px 0px 140px 0px',
      [theme.breakpoints.down('sm')] : {
        paddingBottom: '40px'
      }
    },
    AContainer: {
      backgroundRepeat : 'no-repeat !important',
      [theme.breakpoints.down('sm')] : {
        backgroundImage: 'none !important'
      }
    },
    zoneSection2:{
      padding: '50px',
      [theme.breakpoints.down('sm')] : {
        padding: '0px',
        paddingTop: '40px'
      }
    },
    titleZoneSection: {
      marginTop: '100px',
      [theme.breakpoints.down('sm')] : {
        marginTop: '40px'
      }
    },
    zoneTabSectionSelectedCircle: {
      border: '1px solid grey !important'
    },
    zoneTabSectionCircle: {
      height: '140px',
      width: '140px',
      border: '1px solid ' + colors.lightGrey3,
      borderRadius: '75px',
      [theme.breakpoints.down('md')] : {
        height: '100px',
        width: '100px',
      }
    },
    bannerImageContainer: {
      height: '100%',
      width: '19.8%',
      overflow: 'hidden',
      [theme.breakpoints.down('sm')] : {
        width: '49.6%'
      }
    },
    bannerImage: {      
      height: '100%',
      width: '100%',   
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      transition: 'transform .5s ease',
      display: 'block',
      '&:hover': {
        transform: 'scale(1.05)'
      }
    },
    descriptionTab: {
      width: '50%',
      marginBottom: '100px',
      margin: '0px',
      [theme.breakpoints.down('sm')] : {
        width: '100%',
        letterSpacing: '1px',
        marginBottom: '20px'
      }
    },
    resCardsContainer: {
      padding: '50px 250px 200px 250px',
      [theme.breakpoints.down('lg')] : {
        padding: '50px 50px 200px 50px'
      },
      [theme.breakpoints.down('sm')] : {
        padding: '0px',
        marginTop: '50px'
      }
    },
    backgroundImageZone:{
      height: '700px',
      width: '100%',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      display: 'initial'
  },
  zoneSectionImage: {
    width: '100%',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    [theme.breakpoints.down('sm')] : {
      height: '400px'
    }
  },
  backgroundImageZs: {
    height: '100%',
    width: '100%',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  backgroundImageZs2: {
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: '49.7%',
    marginLeft: '5px',
    marginRight: '5px'
  },
  zoneSectionImages:{
    marginTop: '5px', 
    height: '800px',
    [theme.breakpoints.down('sm')] : {
      height: '300px'
    }
  }
});

export default projectsStyleZone;