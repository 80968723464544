import React from 'react';
import SvgIcon from "@material-ui/core/SvgIcon";

const Menu = (props) => (
  <SvgIcon {...props}>    
    <svg id="Livello_1" data-name="Livello 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 34.02 18"><defs></defs><rect className="cls-1" y="16" width="34.02" height="2"/><rect className="cls-1" y="8" width="34.02" height="2"/><rect className="cls-1" width="34.02" height="2"/></svg>  
  </SvgIcon>
);

const MenuIcon = ({color}) => {
  const iconStyles = {
    color : color ? color : 'black',
    height: '40px',
    width: '40px'
  };
  
  return (
  <div style={{height: '40px'}}>
    <Menu style={iconStyles} />
  </div>
)};

export default MenuIcon;