import {
    cormorantGaramond,
    haylardDisplayBold,
    garamond,
    haylardDisplay,
} from "../../style/style.jsx";
import colors from "../../style/colors.scss";

const NumberIconListStyle = theme => ({
    singleElementContainer: {
        "display": "flex",
        "justify-content": "center",
        [theme.breakpoints.down('sm')] : {
            "flex-direction": "column"
        }
    },
    imgIcon: {
        width: "50px",
        height: "50px",
        "margin-top": "1.45rem",
        [theme.breakpoints.down('sm')] : {
            width: "30px",
            height: "30px",
            "margin-top": "1rem",
            "margin-bottom": "1rem",
        }
    },
    listContainer: {
        "display": "flex",
        "align-items": "center",
        "justify-content": "center",
        "margin-top": "100px",
        [theme.breakpoints.down('sm')] : {
            "margin-top": "50px",
        }
    },
    verticalLineDesktop: {
        "display": "flex",
        "flex-grow": "1",
        "width": "1px",
        "background-color": "black",
        [theme.breakpoints.down('sm')] : {
            "display": "none",
        }
    },
    verticalLineMobile: {
        "display": "none",
        "flex-grow": "1",
        "width": "1px",
        "background-color": "black",
        [theme.breakpoints.down('sm')] : {
            "display": "flex",
            "height": "50px",
            "align-self": "center"
        }
    },
    numberBlockContainer: {
        "display": "flex",
        "flex-direction": "column",
        "align-items": "center",
        "max-width": "100px",
        [theme.breakpoints.down('sm')] : {
            "max-width": "100%",
            "flex-direction": "row-reverse",
            "align-items": "unset",
        }
    },
    textContainer: {
        "margin-bottom": "50px",
        "background-color": "white",
        "border": "1px solid black",
        "padding": "20px",
        [theme.breakpoints.down('sm')] : {
            "margin-bottom": "0px",
        }
    },
    iconContainer: {
        "background-color": colors.darkGrey,
        "width": "100%",
        "display": "flex",
        "align-items": "center",
        "justify-content": "center",
        [theme.breakpoints.down('sm')] : {
            "max-width": "85%",
            "justify-content": "space-between",
            "padding-left": "20px",
            "padding-right": "20px"
        }
    },
    numberContainer: {
        ...haylardDisplay,
        "background-color": "#dddeea",
        "border": "1px solid "+colors.darkGrey,
        "border-right": "none",
        "color": colors.darkGrey,
        "width": "100%",
        "display": "flex",
        "align-items": "center",
        "justify-content": "center",
        "padding": "10px 10px",
        "-moz-box-shadow": "inset -2px 4px 6px hsla(0, 0%, 0%, 0.4)",
        "-webkit-box-shadow": "inset -2px 4px 6px hsla(0, 0%, 0%, 0.4)",
        "box-shadow": "inset -2px 4px 6px hsla(0, 0%, 0%, 0.4)",
        [theme.breakpoints.down('sm')] : {
            "max-width": "15%",
            "-moz-box-shadow": "inset -2px -2px 6px hsla(0, 0%, 0%, 0.4)",
            "-webkit-box-shadow": "inset -2px -2px 6px hsla(0, 0%, 0%, 0.4)",
            "box-shadow": "inset -2px -2px 6px hsla(0, 0%, 0%, 0.4)",
            "padding": "5px 5px",
        }
    },
    titleContainer: {
        ...haylardDisplayBold,
        "text-transform": "uppercase",
        [theme.breakpoints.down('sm')] : {
            "display": "none",
        }
    },
    titleContainerMobile: {
        ...haylardDisplayBold,
        "display": "none",
        "text-transform": "uppercase",
        [theme.breakpoints.down('sm')] : {
            "display": "block",
            "color": "white"
        }
    },
    subtitleContainer: {
        ...cormorantGaramond,
        "font-style": "italic",
        [theme.breakpoints.down('sm')] : {
            fontSize: '1.4em',
            paddingBottom: '15px'
        }
    },
    descriptionContainer: {
        ...garamond
    },


});

export default NumberIconListStyle;