import React from 'react'
import { navigate } from "gatsby"

import {Button, Grid} from '@material-ui/core'
import newsStyle from "../newsStyle"
import withStyles from "@material-ui/core/styles/withStyles"
import Header from "../../Header/Header"

import Footer from '../../Footer/Footer'
import Contact from '../../Contact/Contact'
import SocialShare from '../SocialShare'
import RassegnaStampaSide from './RassegnaStampaSide'
import VideoPlayer from '../../Player/VideoPlayer'
import AudioPlayer from '../../Player/AudioPlayer'
import copy from '../../../constants/copy.json'

const RassegnaStampaPage = ({classes, ...props}) => {

  const rassegnaList = props.pageContext && props.pageContext.allProducts;

  const article = props.pageContext && props.pageContext.product;

  return (
    <div style={{height: "100%"}}>
      <Header />
      <div className={classes.heroContainer}>
        <div className={classes.hero}/>
      </div>
      <div>
        <Grid container className={classes.newsPageContent}>
          <Grid container item xs={1} className={classes.mobileHidden}/>
          <Grid container item xs={12} md={8} direction='column' className={classes.articleHeader}>
              <Grid item className={classes.articleDate}>
                {article.acfRassegna.date}
              </Grid>
              <Grid item className={classes.titlePage}>
                {article.acfRassegna.title}
              </Grid>
              <Grid item className={classes.descriptionPage}>
                {article.acfRassegna.description}
              </Grid>
          </Grid>
          <Grid container item xs={12} className={classes.articleContent}>
            <SocialShare
              xs={1} 
              md={1} 
              direction='column' 
              justify='flex-start' 
              alignItems='flex-end' 
              classesStyle={classes.socialIconContainer + ' ' + classes.mobileHidden}/>
            <Grid container item xs={12} md={8} direction='column' className={classes.articleHeader}>
                <Grid container justify='center'>
                  {article.acfRassegna.type === 'video' &&
                  <VideoPlayer
                      title={article.acfRassegna.title}
                      src={article.acfRassegna.file && article.acfRassegna.file.mediaItemUrl}
                      article={true}
                  />}   
                  {article.acfRassegna.type === 'podcast' &&
                    <AudioPlayer src={article.acfRassegna.file && article.acfRassegna.file.mediaItemUrl} />}
                </Grid>              
                <Grid container className={classes.articleFooterContainer} justify='space-between' alignIitems='flex-start'>
                  {article.tags && article.tags.nodes &&
                  <Grid container item xs={12} sm={6} md={4} className={classes.descriptionCardR} direction='row' style={{marginBottom: '20px'}}>
                    {article.tags.nodes.map((item) => (
                      <Grid item className={classes.hashtagContainer}>
                        #{item.name}
                      </Grid>
                      ))}
                  </Grid>}  
                  <SocialShare 
                    xs={12} 
                    md={8} 
                    direction='row' 
                    justify='flex-end' 
                    alignItems='center' 
                    classesStyle={classes.socialIconContainerFooter}/>
                </Grid>
              </Grid>
            <Grid container item md={3} direction='column' className={classes.mobileHidden}>
              <RassegnaStampaSide rassegnaList={rassegnaList}/>
            </Grid>               
          </Grid>             
        </Grid>             
        <Grid container item xs={12} direction='column' alignItems='center' className={classes.mobileShow + ' ' + classes.articleList}>
          <Grid item className={classes.titleOtherArticle}>
            SFOGLIA ALTRE NEWS DA 
          </Grid>
          <Grid container item justify='center'>
            <Button classes={{root: classes.greyButton + ' ' + classes.buttonFont}} onClick={() => navigate(`/news`)}>
              NEWS
            </Button>
            <Button classes={{root: classes.greyButton + ' ' + classes.buttonFont}} onClick={() => navigate(`/rassegna-stampa`)}>
            {copy.news.rassegnaStampa.title}
            </Button>
          </Grid>
        </Grid> 
        <Contact />         
      </div>
      <Footer />
    </div>
  )
}

export default withStyles(newsStyle)(RassegnaStampaPage)
