import React from "react"
import { navigate } from "gatsby"

import withStyles from "@material-ui/core/styles/withStyles"
import Grid from '@material-ui/core/Grid'

import PrimoPianoCarouselStyle from './primoPianoCarouselStyle.jsx'
import copy from '../../constants/copy.json'
import { zoneCards } from '../../constants/NewHomeCard'

const PrimoPianoCard = ({classes, item, isCurrentSlide, isPreview}) => {

    const zone = (item.residenza.acfResidenza.zone !== "italia" && item.residenza.acfResidenza.zone !== "hinterland") ? 'milano' : (zoneCards.find((z) => z.zoneCode === item.residenza.acfResidenza.zone)).zoneUrl;

    return (
        <div className={`${classes.primoPianoCard} ${!isCurrentSlide ? classes.sliderContentCloned : classes.sliderContent} ${isPreview && classes.sliderContentPreview}`}>
            <Grid container direction='column' className={`${classes.clickable} ${classes.sliderContentCard}`} onClick={() => navigate(`/nuove-costruzioni-${zone}/${item.residenza.acfResidenza.seourl ? item.residenza.acfResidenza.seourl : item.residenza.acfResidenza.name.trim()}`)}>
                <Grid item className={classes.primoPianoImg} style={{backgroundImage : `url(${item.image && item.image.sourceUrl})`}}>
                </Grid>
                <Grid container item className={classes.carouselContent} direction='column'>
                    <Grid item className={classes.title}>
                        {item.title}
                    </Grid>
                    <Grid item className={classes.description}>
                        {item.description}
                    </Grid>
                    <Grid container item className={classes.linkCta} direction='column' onClick={() => navigate(`/nuove-costruzioni-${zone}/${item.residenza.acfResidenza.seourl ? item.residenza.acfResidenza.seourl : item.residenza.acfResidenza.name.trim()}`)}>
                        {copy.home.primoPiano.cta}
                        <Grid item className={classes.linkCtaBorder}/>
                    </Grid>
                </Grid>
            </Grid>
        </div>    
    );
}

export default withStyles(PrimoPianoCarouselStyle)(PrimoPianoCard)