import React from 'react'
import OtherArticleSection from './OtherArticleSection'
import moment from 'moment'

import { Grid } from '@material-ui/core'
import newsStyle from "./newsStyle.jsx"
import withStyles from "@material-ui/core/styles/withStyles"

import articlesCat from '../../constants/ArticlesCategory'

const OtherArticleSide = ({ classes, articlesList, currentArticle, maxArticle }) => {


  const cat = articlesCat.find((item) => currentArticle.acfNews.category === item.code);

  const artFilter = articlesList && articlesList.filter((item) => item.id !== currentArticle.id && item.acfNews.category === currentArticle.acfNews.category)
    .sort((a, b) => moment(b.acfNews.date, 'DD/MM/YYYY').valueOf() - moment(a.acfNews.date, 'DD/MM/YYYY').valueOf())
    .slice(0, maxArticle);

  const otherArticles = artFilter.map((item, idx) => {
    return (
      <OtherArticleSection key={idx} type='article' article={item.acfNews} baseUrl={'/news/'} articleNumber={artFilter.length} idx={idx} />
    );
  });

  return (
    <>
      {otherArticles && otherArticles.length > 0 &&
        <>
          <Grid className={classes.titleOtherArticle2}>
            News &gt; <div dangerouslySetInnerHTML={{ __html: cat.label }} />
          </Grid>
          <Grid container className={classes.articleList} alignItems='center' direction='column'>
            <Grid item className={classes.titleOtherArticle}>
              ALTRE NEWS DA
          </Grid>
            <Grid item className={classes.titleR}>
              News &gt; {cat.label}
            </Grid>
            <Grid container style={{ marginTop: '30px' }}>
              {otherArticles}
            </Grid>
          </Grid>
        </>}
    </>
  )
};

export default withStyles(newsStyle)(OtherArticleSide)
