import { Button, Checkbox, FormControl, TextField } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import withStyles from "@material-ui/core/styles/withStyles"
import { navigate } from "gatsby"
import PropTypes from 'prop-types'
import React, { useState } from "react"
import copy from '../../constants/copy.json'
import { newsletter } from "../../constants/endpoints.jsx"
import newsLetterImage from '../../images/home_rimaniaggiornato.jpg'
import CheckboxIcon from '../../images/icons/Checkbox'
import FacebookIcon from "../../images/icons/FacebookIcon"
import InstagramIcon from "../../images/icons/InstagramIcon"
import LinkedinIcon from "../../images/icons/LinkedinIcon"
import YoutubeIcon from "../../images/icons/YoutubeIcon"
import homeStyle from "../Home/homeStyle"
import Loader from '../Loader/Loader'
import { PrivacyPdf } from '../../constants/links'


const NewsLetter = ({ home, classes, mapRef, zone }) => {

  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const [email, setEmail] = useState(null);
  const [flag1, setFlag1] = useState(false);
  const [flag2, setFlag2] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    setError(false);
    setSuccess(false);
    setLoading(true);

    const body = {
      "email": email,
      "dataCollectionFlag": true,
      "marketingFlag": flag1,
      "profilazioneFlag": flag2
    };

    fetch(newsletter, {
      method: "post",
      headers: {
        'Accept': '*/*',
        "Content-Type": "application/json"
      },
      body: JSON.stringify(body)
    })
      .then(results => {
        return results.json();
      })
      .then(response => {
        var responseCode = response.statusCode;
        if (responseCode !== 200 && responseCode !== 401) {
          console.error(
            "Error calling " + newsletter + ": " + response.error.type + "\n\n" + response.error.description
          );
          setError(true);
          setLoading(false);
        } else {
          setEmail(null);
          setFlag1(false);
          setFlag2(false);
          setSuccess(true);
          setLoading(false);
        }
      });
  };


  return (
    <Grid container className={`${classes.newsLetterContainer} ${!home && classes.noMarginTop}`}>
      {home ?
        <Grid item xs={12} md={4} style={{ backgroundImage: `url(${newsLetterImage})` }} className={classes.newsLetterBoxShadow}>
        </Grid>
        :
        <Grid container item xs={12} md={4} justify='center' alignContent='center' direction='column' alignItems='center' className={classes.newsLetterBoxShadowNoBkg}>
          <Grid item className={classes.subTitle + ' ' + classes.titleDark} style={{ textAlign: 'center' }}>
            Vuoi cercare delle soluzioni in un'altra zona?
          </Grid>
          <Grid item style={{ marginTop: '20px' }}>
            <Button classes={{ root: classes.greyButton }} onClick={() => mapRef ? mapRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' }) : (zone && navigate(`/nuove-costruzioni-${zone}`))}>
              {copy.progetti.newsLetter.cta1}
            </Button>
          </Grid>
        </Grid>}
      <Grid container item xs={12} md={5} direction="column" className={classes.newsLetter}>
        <Grid item className={classes.subTitle + ' ' + classes.titleDark}>
          {home ? copy.home.newsLetter.subTitle : 'Non hai trovato quello che cercavi?'}
        </Grid>
        <Grid item className={classes.subTitle + ' ' + classes.titleLight}>
          {copy.home.newsLetter.title}
        </Grid>
        {loading && <Loader color='dark' />}
        {error && !loading &&
          <Grid item className={classes.subTitle + ' ' + classes.error}>
            {copy.home.form.error}
          </Grid>}
        {success && !loading &&
          <Grid container item direction="column" justify="center">
            <Grid container className={classes.subTitle + ' ' + classes.titleDark} direction='row'>
              <Grid item>
                <CheckboxIcon dimension='30px' color='#353535' />
              </Grid>
              <Grid item style={{ alignSelf: 'flex-end', fontSize: '30px', fontWeight: 'bold' }}>
                {copy.home.form.success}
              </Grid>
            </Grid>
          </Grid>}
        {!loading &&
          <form onSubmit={handleSubmit}>
            <Grid item>
              <FormControl classes={{ root: classes.formControlRoot }}>
                <TextField
                  required
                  type='email'
                  onChange={(e) => setEmail(e.target.value)}
                  value={email ? email : ''}
                  variant="outlined"
                  placeholder={copy.home.footer.email}
                  classes={{ root: classes.textFieldRoot }}
                  InputProps={{
                    classes: {
                      root: classes.inputPropsRoot,
                      notchedOutline: classes.textFieldFocused
                    }
                  }} />
                <Grid container >
                  <Grid item xs={12} className={classes.checkBoxLabel}>
                    {copy.home.newsLetter.privacy} <a className={classes.checkBoxLabel + ' ' + classes.checkBoxLabelLink} href={PrivacyPdf} target="_blank" rel="noreferrer">{copy.home.newsLetter.privacyLink}</a>
                  </Grid>
                  <Grid item xs={2} sm={1}>
                    <Checkbox
                      required={true}
                      name="flag1"
                      onChange={(e) => setFlag1(e.target.checked)}
                      value={flag1}
                      checkedIcon={<div className={classes.checkedIcon}>
                        <CheckboxIcon dimension='13px' color='white' top='-5px' />
                      </div>}
                      icon={<div className={classes.icon} />}
                      classes={{
                        root: classes.checkBoxRoot
                      }}
                    />
                  </Grid>
                  <Grid item xs={10} sm={11} className={classes.checkBoxLabel}>
                    {copy.home.newsLetter.check1}
                  </Grid>
                </Grid>
                <Grid container >
                  <Grid item xs={2} sm={1}>
                    <Checkbox
                      name="flag2"
                      onChange={(e) => setFlag2(e.target.checked)}
                      value={flag2}
                      checkedIcon={<div className={classes.checkedIcon}>
                        <CheckboxIcon dimension='13px' color='white' top='-5px' />
                      </div>}
                      icon={<div className={classes.icon} />}
                      classes={{
                        root: classes.checkBoxRoot
                      }}
                    />
                  </Grid>
                  <Grid item xs={10} sm={11} className={classes.checkBoxLabel}>
                    {copy.home.newsLetter.check2}
                  </Grid>
                </Grid>
              </FormControl>
            </Grid>
            <Grid item>
              <Button type='submit' classes={{ root: classes.greyButton }}>
                {copy.home.newsLetter.cta}
              </Button>
            </Grid>
          </form>}
      </Grid>
      <Grid container item xs={12} md={3} direction="column" justify="center" alignItems='center' className={classes.social}>
        <div className={`${classes.socialBackGround} ${classes.mobileShow}`}></div>
        <hr className={`${classes.socialDivider} ${classes.mobileShow}`} />
        <Grid item className={classes.subTitle + ' ' + classes.titleLight} style={{ textAlign: 'center', zIndex: '1' }}>
          {copy.home.newsLetter.follow}
        </Grid>
        <Grid container justify='center' className={classes.socialLogosContainer}>
          <Grid onClick={() => window.open("https://www.facebook.com/abitareco/?fref=ts", '_blank')}>
            <FacebookIcon extraClasses={classes.socialIconWithHover + ' ' + classes.facebookHover} />
          </Grid>
          <Grid onClick={() => window.open("https://www.youtube.com/user/AbitareCoMilano", '_blank')}>
            <YoutubeIcon extraClasses={classes.socialIconWithHover + ' ' + classes.youtubeHover} />
          </Grid>
          <Grid onClick={() => window.open("https://www.instagram.com/abitareco/", '_blank')}>
            <InstagramIcon extraClasses={classes.socialIconWithHover + ' ' + classes.instagramHover} />
          </Grid>
          <Grid onClick={() => window.open("https://www.linkedin.com/company/abitare-co./", '_blank')}>
            <LinkedinIcon extraClasses={classes.socialIconWithHover + ' ' + classes.linkedinHover} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

NewsLetter.propTypes = {
  home: PropTypes.bool,
};

NewsLetter.defaultProps = {
  home: false
};

export default withStyles(homeStyle)(NewsLetter)
