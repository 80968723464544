import React from 'react'
import { useStaticQuery, graphql } from "gatsby"

import scopriAbitareCoStyle from "./ScopriAbitareCoStyle.jsx"
import Header from "../Header/Header"
import HeroOnlyImage from "../Other/HeroOnlyImage"
import withStyles from "@material-ui/core/styles/withStyles"
import Grid from '@material-ui/core/Grid'
import Contact from '../Contact/Contact'
import GalleryCarousel from '../Carousel/GalleryCarousel'
import copy from '../../constants/copy.json'
import { Divider, useMediaQuery, useTheme } from '@material-ui/core'
import Footer from '../Footer/Footer'

import NumberIconList from "./NumberIconList"

import CardMosaico from "../../components/Other/CardMosaico"

import downloadIcon from "../../images/svg/icon_download.svg"
import imgHero from "../../images/scopriabitareco_business-unit.jpg"
import ALogoRight from "../../images/svg/A_bianco.svg"
import ALogoLeft from "../../images/svg/A_sx_scuro.svg"

const ScopriAbitareCo = ({classes}) => {

  const formatNumber = (e) => {
    if(e.numberSmall) {
        return (
            <div className={classes.numberContainer}>
                <div className={classes.bigNumber}>
                    {e.numberBig}
                </div>
                <div className={classes.smallNumber}>
                    .{e.numberSmall} {e.append}
                </div>
            </div>
        );
    }
    else {
        return (
            <div className={classes.numberContainer}>
                <div className={classes.bigNumber}>
                    {e.numberBig}
                </div>
                <div className={classes.smallNumber}>
                    {e.append}
                </div>
            </div>
        );
    }
  }; 

  const data = useStaticQuery(graphql`
  {
    wpgraphql {
       residenzeScopriAbitareCo(first: 1000) {
        nodes {
          acfResidenzaScopri {
            order
            residenza {
              ... on WPGraphQL_Residenza {
                id
                acfResidenza {
                  name
                  apartments
                  address {
                    city
                  }
                }
              }
            }
            image {
              sourceUrl
            }
          }
        }
      }
      companyProfiles {
        nodes {
          acfCompanyProfile {
            pdf {
                mediaItemUrl
            }
            description
            hidden
          }
        }
      }
    }
  }`);

  const carouselCards = data && data.wpgraphql && data.wpgraphql.residenzeScopriAbitareCo && data.wpgraphql.residenzeScopriAbitareCo.nodes.sort((a, b) => {return (b.acfResidenzaScopri.order != null) - (a.acfResidenzaScopri.order != null) || a.acfResidenzaScopri.order - b.acfResidenzaScopri.order});

  const fileToDownloads = data && data.wpgraphql && data.wpgraphql.companyProfiles && data.wpgraphql.companyProfiles.nodes && data.wpgraphql.companyProfiles.nodes.length > 0 && data.wpgraphql.companyProfiles.nodes.filter((item) => !item.acfCompanyProfile.hidden);

  const theme = useTheme();
  const blocksData = useMediaQuery(theme.breakpoints.down('sm')) ? 
                        [...copy.scopriAbitareCo.numberBlock.blocks].sort((a,b) => a.mobileIndex - b.mobileIndex) : 
                        copy.scopriAbitareCo.numberBlock.blocks;

  return (
    <div style={{height: "100%"}}>
        <Header home={false} />
        <div className={classes.heroContainer}>
            <div className={classes.hero}/>
        </div>

        <div className={classes.firstTextBlock}>
            <div className={classes.heroTextContainer}>
                <div className={classes.title}>
                    {copy.scopriAbitareCo.title}
                </div>

                <div className={classes.subtitle}>
                    <span>
                        <span className={classes.underlineContent}>
                            <div dangerouslySetInnerHTML={{__html: copy.scopriAbitareCo.subtitle}}/>
                        </span>
                    </span>
                </div>

                <div className={classes.description1}>
                    <div dangerouslySetInnerHTML={{__html: copy.scopriAbitareCo.description1}}/>
                </div>

                <div className={classes.quote}>
                    {copy.scopriAbitareCo.quote}
                </div>

            </div>
        </div>


        <div className={classes.numberBlockContainer}>
            <div className={classes.letterBackground} style={{bottom: 0, right: 0}}>
                <img src={ALogoLeft} />
            </div>

            <div className={classes.numberBlock}>
                {carouselCards &&
                <div className={classes.numberCarouselContainer}>
                    <div className={classes.visibleDesktop}>
                        <GalleryCarousel 
                            type='galleryTitleSubtitleProject' 
                            centerMode={true} 
                            data={carouselCards} 
                            dots={true}
                            arrowColor='white'
                            arrowPos='2%'/>
                    </div>
                    <div className={classes.visibleMobile}>
                        <GalleryCarousel 
                            type='galleryTitleSubtitleProject' 
                            centerMode={true} 
                            data={carouselCards}
                            dots={true}
                            variableWidth={true}
                            autoplay={false}
                            arrowColor='white'
                            arrowPos='2%'/>
                    </div>                    
                </div>}

                <Grid container className={`${classes.mobileShow}`}>
                    <Grid container justify='center' style={{margin: '50px 25px'}}>
                        <Divider className={classes.divider} />
                    </Grid>
                </Grid>

                <div className={classes.numberInfoContainer}>
                    <div className={classes.numeriTitle}>
                        <span>
                            <span className={classes.underlineContent}>
                                {copy.scopriAbitareCo.numberBlock.title}
                            </span>
                        </span>
                    </div>

                    <div className={classes.numeriSubtitle}>
                        {copy.scopriAbitareCo.numberBlock.subtitle}
                    </div>
                </div>

                <Grid container className={classes.blocksContainer}>
                    {blocksData.map((e, i) => (
                        <Grid item key={i} className={classes.singleNumberBlock} xs={5} md={3} >

                            <div className={classes.singleNumberBlockNumber}>
                                {formatNumber(e)}
                            </div>
                            
                            <div className={classes.singleNumberBlockTitle} dangerouslySetInnerHTML={{__html: e.title.concat(``)}}>
                            </div>
                        </Grid>
                    ))}
                </Grid>

            </div>
        </div>


        <div className={classes.valoriContainer}>
            <div className={classes.valoriTextContainer}>
                <div className={classes.sectionTitle}>
                    <span>
                        <span className={classes.underlineContent}>
                            {copy.scopriAbitareCo.valoriContainer.title}
                        </span>
                    </span>
                </div>

                <div className={classes.sectionSubtitle}>
                    {copy.scopriAbitareCo.valoriContainer.subtitle}
                </div>
            </div>

            <div className={classes.valoriCarouselContainer}>
                <div className={classes.visibleDesktop}>
                    
                    <GalleryCarousel 
                        type='galleryFullInfoProject' 
                        centerMode={true} 
                        data={copy.scopriAbitareCo.valoriContainer.valori} 
                        dots={true}
                        arrowColor='black'
                        arrowPos='-6%'/>
                </div>
                <div className={classes.visibleMobile}>
                    <GalleryCarousel 
                        type='galleryFullInfoProject' 
                        centerMode={true} 
                        infinite={true}
                        data={copy.scopriAbitareCo.valoriContainer.valori} 
                        dots={true}
                        variableWidth={true}
                        autoplay={false}
                        arrowColor='black'
                        arrowPos='-6%'/>
                </div>
            </div>
        </div>

        <Grid container className={classes.mosaicoBlock}>
            <Grid container className={classes.mosaicoDescriptionContainer}>
                <Grid item xs={12} md={4} className={classes.sectionTitle}>
                    <span>
                        <span className={classes.underlineContent}>
                            {copy.scopriAbitareCo.experiencesContainer.title}
                        </span>
                    </span>
                </Grid>

                <Grid item xs={12} md={4} className={classes.sectionDescription}>
                    {copy.scopriAbitareCo.experiencesContainer.description}
                </Grid>
            </Grid>

            <Grid item md={8} className={classes.mosaicoContainer}>
                
                <Grid container className={`${classes.mosaicoRow} ${classes.mosaicoMarginBottom}`}>
                    <Grid item md={8} xs={12} className={`${classes.mosaicoMarginBottom} ${classes.mosaicoMaxHeigh}`}>
                        <Grid container className={`${classes.mosaicoMarginBottom} ${classes.mosaicoHalfMaxHeigh}`}>
                            <Grid item md={7} xs={12}  className={`${classes.mosaicoHalfMaxHeigh} ${classes.mosaicoImgContainer}  ${classes.mosaicoPaddingRight}`}>
                                <CardMosaico even={true} imgClass={classes.mosaicoImg} divClass={classes.mosaicoTextContainer} data={copy.scopriAbitareCo.experiencesContainer.mosaico[0]}></CardMosaico>
                            </Grid>

                            <Grid item md={5} xs={12} className={`${classes.mosaicoHalfMaxHeigh} ${classes.mosaicoImgContainer}  ${classes.mosaicoPaddingRight}`}>
                                <CardMosaico even={false} imgClass={classes.mosaicoImg} divClass={classes.mosaicoTextContainer} data={copy.scopriAbitareCo.experiencesContainer.mosaico[1]}></CardMosaico>
                            </Grid>
                        </Grid> 

                        <Grid container className={classes.mosaicoHalfMaxHeigh}>
                            <Grid item md={5} xs={12} className={`${classes.mosaicoHalfMaxHeigh} ${classes.mosaicoImgContainer}  ${classes.mosaicoPaddingRight}`}>
                                <CardMosaico even={true} imgClass={classes.mosaicoImg} divClass={classes.mosaicoTextContainer} data={copy.scopriAbitareCo.experiencesContainer.mosaico[2]}></CardMosaico>
                            </Grid>

                            <Grid item md={7} xs={12} className={`${classes.mosaicoHalfMaxHeigh} ${classes.mosaicoImgContainer}  ${classes.mosaicoPaddingRight}`}>
                                <CardMosaico even={false} imgClass={classes.mosaicoImg} divClass={classes.mosaicoTextContainer} data={copy.scopriAbitareCo.experiencesContainer.mosaico[3]}></CardMosaico>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item className={classes.mosaicoMaxHeighSingleImage} md={4} xs={12}>
                        <CardMosaico even={true} imgClass={classes.mosaicoImg} divClass={classes.mosaicoTextContainer} data={copy.scopriAbitareCo.experiencesContainer.mosaico[4]}></CardMosaico>
                    </Grid>
                </Grid>


                <Grid container>
                    <Grid item className={classes.mosaicoMaxHeighSingleImage} md={4} xs={12}>
                        <CardMosaico even={false} imgClass={classes.mosaicoImg} divClass={classes.mosaicoTextContainer} data={copy.scopriAbitareCo.experiencesContainer.mosaico[5]}></CardMosaico>
                    </Grid>

                    <Grid item md={8} xs={12} className={classes.mosaicoMaxHeigh}>
                        
                        <Grid container className={`${classes.mosaicoMarginBottom} ${classes.mosaicoHalfMaxHeigh}`}>
                            <Grid item md={5} xs={12} className={`${classes.mosaicoHalfMaxHeigh} ${classes.mosaicoImgContainer}  ${classes.mosaicoPaddingLeft}`}>
                                <CardMosaico even={true} imgClass={classes.mosaicoImg} divClass={classes.mosaicoTextContainer} data={copy.scopriAbitareCo.experiencesContainer.mosaico[6]}></CardMosaico>
                            </Grid>

                            <Grid item md={7} xs={12} className={`${classes.mosaicoHalfMaxHeigh} ${classes.mosaicoImgContainer}  ${classes.mosaicoPaddingLeft}`}>
                                <CardMosaico even={false} imgClass={classes.mosaicoImg} divClass={classes.mosaicoTextContainer} data={copy.scopriAbitareCo.experiencesContainer.mosaico[7]}></CardMosaico>
                            </Grid>
                        </Grid> 

                        <Grid container className={classes.mosaicoHalfMaxHeigh}>
                            <Grid item md={7} xs={12} className={`${classes.mosaicoHalfMaxHeigh} ${classes.mosaicoImgContainer}  ${classes.mosaicoPaddingLeft}`}>
                                <CardMosaico even={true} imgClass={classes.mosaicoImg} divClass={classes.mosaicoTextContainer} data={copy.scopriAbitareCo.experiencesContainer.mosaico[8]}></CardMosaico>
                            </Grid>

                            <Grid item md={5} xs={12} className={`${classes.mosaicoHalfMaxHeigh} ${classes.mosaicoImgContainer}  ${classes.mosaicoPaddingLeft}`}>
                                <CardMosaico even={false} imgClass={classes.mosaicoImg} divClass={classes.mosaicoTextContainer} data={copy.scopriAbitareCo.experiencesContainer.mosaico[9]}></CardMosaico>
                            </Grid>
                        </Grid>                                              
                    </Grid>
                </Grid>
            </Grid>
        </Grid>

        <Grid container className={`${classes.mobileShow}`}>
          <Grid container justify='center' style={{margin: '50px 25px'}}>
            <Divider className={classes.divider} />
          </Grid>
        </Grid>

        <HeroOnlyImage img={imgHero} otherClasses={`${classes.heroImg} ${classes.backgroundImage}`} altText={"Hero image"}></HeroOnlyImage>


        <Grid container item xs={12} className={classes.businessUnitContainer} id='businessUnit'>

            <div className={classes.letterBackground} style={{bottom: 0, left: 0}}>
                <img src={ALogoRight}/>
            </div>

            <Grid container className={classes.titleSubDescContainer}>
                <Grid item xs={12} md={4} className={classes.sectionTitle}>
                    <span>
                        <span className={classes.underlineContent}>
                            {copy.scopriAbitareCo.businessUnitSection.title}
                        </span>
                    </span>
                </Grid>

                <Grid item xs={12} md={4} className={classes.sectionDescription}>
                    {copy.scopriAbitareCo.businessUnitSection.description}
                </Grid>
            </Grid>

            <NumberIconList elements={copy.scopriAbitareCo.businessUnitSection.bu_list}/>
            {fileToDownloads && fileToDownloads.length > 0 && fileToDownloads.map((item) => (
                <Grid container direction='row' justify='center' className={classes.buCompanyProfileContainer}>
                    <Grid container item sm={12} md={6} className={classes.buttonDownload} direction='row' justify='center' onClick={() => window.open(item.acfCompanyProfile.pdf.mediaItemUrl, '_blank')}>
                        <Grid item className={classes.buIconContainer}>
                            <img className={classes.svgDownload} src={downloadIcon} alt={""}/>
                        </Grid>
                        <Grid item className={classes.buTextContainer}>
                            {copy.scopriAbitareCo.businessUnitSection.scarica} - {item.acfCompanyProfile.description}
                            <Grid item className={classes.halfBorder}/>
                        </Grid>
                    </Grid>
                </Grid>))}     
        </Grid>

        <Contact />        
        
        <Footer />
    </div>
  )
}

export default withStyles(scopriAbitareCoStyle)(ScopriAbitareCo)
