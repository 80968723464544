import React from 'react'
import { navigate } from "gatsby"

import { Grid, Button } from '@material-ui/core'
import newsStyle from "../newsStyle"
import withStyles from "@material-ui/core/styles/withStyles"

import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import RassegnaIcon from '../../../images/icons/RassegnaIcon'
import copy from '../../../constants/copy.json'

const RassegnaArticle = ({classes, article, idx}) => {
    
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <Grid container item direction='row' 
    className={`${classes.rassegnaArticle} ${idx % 2 === 0 && classes.rassegnaArticleBk} ${classes.rassegnaArticleBorder}`}
    onClick={()=> {
      if(article.acfRassegna.linkEsterno) {
        window.open(article.acfRassegna.link, '_blank');
      } else {
        if(article.acfRassegna.type === 'article'){
          window.open(article.acfRassegna.file && article.acfRassegna.file.mediaItemUrl, '_blank');
        } else {
          navigate(`/rassegna-stampa/${article.acfRassegna.title.replace(/[&\\#, +()$~%.'":*?<>{}]/g, '_')}`);
        }
      }}}>
      <Grid sm={2} md={1} xl={1} container item justify='center' alignItems='center' className={classes.iconContainer}>
        <RassegnaIcon color='white' type={article.acfRassegna.type} dimension={matches ? '40px' : '80px'}/>
      </Grid>
      <Grid xs={12} sm={6} md={7} xl={8} container item direction='column' className={classes.rassegnaArticleContainer}>
        <Grid item className={classes.articleDateR}>
          {article.acfRassegna.date}
        </Grid>
        <Grid item className={classes.titleR}>
          {article.acfRassegna.title}
        </Grid>
        <Grid item className={classes.descriptionCardR}>
          {article.acfRassegna.description}
        </Grid>
        {article.tags && article.tags.nodes &&
        <Grid container item className={classes.descriptionCardR} direction='row'>
          {article.tags.nodes.map((item) => (
            <Grid item className={classes.hashtagContainer}>
              #{item.name}
            </Grid>
            ))}
        </Grid>}
      </Grid>
      <Grid container item sm={4} md={4} xl={3} justify='flex-end' alignItems='flex-end'>
        <Button onClick={()=> {
          if(article.acfRassegna.linkEsterno) {
            window.open(article.acfRassegna.link, '_blank');
          } else {
            if(article.acfRassegna.type === 'article'){
              window.open(article.acfRassegna.file && article.acfRassegna.file.mediaItemUrl, '_blank');
            } else {
              navigate(`/rassegna-stampa/${article.acfRassegna.title.replace(/[&\\#, +()$~%.'":*?<>{}]/g, '_')}`);
            }
          }}}
          classes={{root:  classes.greyButton + ' ' + classes.mobileHiddenXs + ' ' +  classes.buttonWidth}}>
          {copy.news.rassegnaStampa[article.acfRassegna.type]}  
        </Button>
      </Grid>
    </Grid>
  );
};

export default withStyles(newsStyle)(RassegnaArticle)
