import colors from "../../style/colors.scss"

import { garamond, title, cormorantGaramond } from "../../style/style.jsx"

const contactCardStyle = theme => ({
  card: {
    minWidth: "35vw",
    minHeight: "360px",
    width: "35vw",
    height: "70vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    boxShadow: "none",
    borderColor: colors.lightGrey3,
    borderRadius: "0",
    [theme.breakpoints.down("sm")]: {
      minWidth: "100vw",
      width: "100vw",
      height: "50vh",
      minHeight: "350px",
      backgroundColor: colors.lightGrey3,
    },
  },
  cardContent: {
    padding: "20px",
  },
  subtitle: {
    ...garamond,
    fontStyle: "italic",
    fontSize: "22px",
    fontWeight: "500",
    marginBottom: "0.8em",
    color: "rgba(0,0,0,.8)",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "0.2em",
    },
  },
  title: {
    ...title,
    marginBottom: "0.8em",
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px",
    },
  },
  divider: {
    marginBottom: "2em",
    backgroundColor: "black",
    width: "19%",
    [theme.breakpoints.down("sm")]: {
      width: "25%",
      height: "2px",
    },
  },
  sectionTitle: {
    ...garamond,
    fontSize: "1em",
    fontWeight: "bold",
    marginBottom: "0",
  },
  sectionContent: {
    ...cormorantGaramond,
    letterSpacing: "1px",
    marginBottom: "1.5em",
  },
  sectionFirstContent: {
    ...cormorantGaramond,
    letterSpacing: "1px",
    marginBottom: "0",
  },
  sectionLastContent: {
    ...cormorantGaramond,
    letterSpacing: "1px",
    marginBottom: "0",
  },
  link: {
    color: '#0000EE',
    textDecoration: "none",
    width: 'fit-content',
    display: 'block',
    '&:hover':{
      cursor: 'pointer'
    }
  }
})

export default contactCardStyle
