import React, { useState, useEffect } from 'react'

import newsStyle from "../newsStyle"
import withStyles from "@material-ui/core/styles/withStyles"
import Header from "../../Header/Header"
import { Select, Grid, MenuItem, InputLabel } from '@material-ui/core'

import UfficioStampa from '../UfficioStampa'
import Footer from '../../Footer/Footer'
import Contact from '../../Contact/Contact'
import RassegnaListYear from './RassegnaListYear'
import Pagination from '../../Pagination/Pagination'
import copy from '../../../constants/copy.json'

import moment from 'moment'

const RassegnaStampa = ({classes, ...props}) => {

  const [years, setYears] = useState([]);
  const [selectedYear, setSelectedYear] = useState('');

  const [tags, setTags] = useState([]);
  const [selectedTag, setSelectedTag] = useState('');

  const rassegnaArticleList = props.pageContext && props.pageContext.allProducts;

  const rassegnaArticleListSorted = rassegnaArticleList.sort((a, b) => moment(b.acfRassegna.date, 'DD/MM/YYYY').valueOf() - moment(a.acfRassegna.date, 'DD/MM/YYYY').valueOf());

  const rassegnaArticleFiltered = rassegnaArticleListSorted.filter((item) => {
      if(selectedYear && selectedTag){
        return moment(item.acfRassegna.date, 'DD/MM/YYYY').year() === selectedYear && item.tags.nodes && item.tags.nodes.filter((item) => item.name === selectedTag).length > 0;
      } else if(selectedYear){
        return moment(item.acfRassegna.date, 'DD/MM/YYYY').year() === selectedYear;
      } else if(selectedTag){
        return item.tags.nodes && item.tags.nodes.filter((item) => item.name === selectedTag).length > 0;
      } else {
        return true;
      }
    });

  useEffect(() => { 
    const yearsList = rassegnaArticleListSorted.reduce((r, a) => {
      const year = moment(a.acfRassegna.date, 'DD/MM/YYYY').year();
      if(!r.includes(year)){
        r.push(year);
      }
      return r;
    }, []);

    setYears(yearsList);

    const tagsList = rassegnaArticleListSorted.reduce((r, a) => {
      const tags = a.tags.nodes;
      for(const tag in tags){        
        if(!r.includes(tags[tag].name)){
          r.push(tags[tag].name);
        }
      }
      return r;
    }, []);

    setTags(tagsList);

  }, [rassegnaArticleListSorted]);

  const populatePage = (visibleElements) => {
    const articles = rassegnaArticleFiltered.slice(0, visibleElements);

    const yearArticles = articles.reduce((r, a) => {
      r[moment(a.acfRassegna.date, 'DD/MM/YYYY').year()] = r[moment(a.acfRassegna.date, 'DD/MM/YYYY').year()] || [];
      r[moment(a.acfRassegna.date, 'DD/MM/YYYY').year()].push(a);
      return r;
    }, Object.create(null));

    const articleList = Object.keys(yearArticles).sort((a, b) => b - a).map((date, idx) => {
      const articlesSorted = yearArticles[date].sort((a, b) => moment(b.acfRassegna.date, 'DD/MM/YYYY').valueOf() - moment(a.acfRassegna.date, 'DD/MM/YYYY').valueOf());
      return (
        <RassegnaListYear articles={articlesSorted} key={idx} year={date}/>
      );
    });

    return articleList;
  };

  return (
    <div style={{height: "100%"}}>
      <Header />
      <div className={classes.heroContainer}>
        <div className={classes.hero}/>
      </div>
      <div className={classes.newsContainer}>
        <Grid container item  justify='center'>
          <Grid container alignContent='center' alignItems='center' justify='center' className={classes.subTitle}>
          {copy.news.subtitleRassegna}
          </Grid>
          <Grid container alignContent='center' alignItems='center' justify='center' className={classes.sectionTitleUnderline}>
          <h1>{copy.news.rassegnaStampa.title}</h1>
          </Grid>
        </Grid>
        <Grid container direction='column' alignContent='center' alignItems='center' justify='center' style={{marginTop: '20px'}}>
          <Grid item  xs={10} md={4} className={classes.description}>
          {copy.news.rassegnaStampa.description}
          </Grid>
        </Grid>
        <Grid container className={classes.filterContainer} justify='flex-end'>
          <Grid container item xs={12} md={2} className={classes.descriptionFilter} justify='flex-end' alignItems='flex-end'>
            Filtra gli articoli
          </Grid>
          <Grid container item xs={12} md={3}>
            <InputLabel id="yearLabel" className={classes.inputLabel + ' ' + classes.mobileHidden}>Filtra per anno:</InputLabel>
            <Select 
                id="year"
                key="year"
                name="year"
                onChange={(e) => {
                  setSelectedYear(e.target.value);
                }} 
                disableUnderline
                displayEmpty
                labelId="yearLabel"
                value={selectedYear}
                className={classes.selectMapRassegna}
                inputProps={{
                  classes: {
                    root: classes.inputContactPropsRoot
                  }
                }}
                >
                  <MenuItem className={classes.inputContactPropsRoot} value={""}>
                    <Grid item className={`${classes.headerTextSelectRassegna}`}>
                    Tutti gli anni
                    </Grid>
                  </MenuItem>
                  {years.map((item, idx) => (
                    <MenuItem key={idx} className={classes.inputContactPropsRoot} value={item}>
                      <Grid item className={`${classes.headerTextSelectRassegna}`}>
                      {item}
                      </Grid>
                    </MenuItem>
                  ))}
            </Select>
          </Grid>
          <Grid container item xs={12} md={3}>
            <InputLabel id="tagLabel" className={classes.inputLabel+ ' ' + classes.mobileHidden}>Filtra per tag:</InputLabel>
            <Select 
                id="tag"
                key="tag"
                name="tag"
                onChange={(e) => {
                  setSelectedTag(e.target.value);
                }} 
                displayEmpty
                disableUnderline
                labelId="tagLabel"
                value={selectedTag}
                className={classes.selectMapRassegna}
                inputProps={{
                  classes: {
                    root: classes.inputContactPropsRoot
                  }
                }}
                >
                  <MenuItem className={classes.inputContactPropsRoot} value={""}>
                    <Grid item className={`${classes.headerTextSelectRassegna}`}>
                    Tutti i tag
                    </Grid>
                  </MenuItem>
                  {tags.map((item, idx) => (
                    <MenuItem key={idx} className={classes.inputContactPropsRoot} value={item}>
                      <Grid item className={`${classes.headerTextSelectRassegna}`}>
                      {item}
                      </Grid>
                    </MenuItem>
                  ))}
            </Select>
          </Grid>
        </Grid>
        {rassegnaArticleList.length > 0 ?
        <Grid container className={classes.articlesContainerRassegna}>
          <Pagination 
            pagination={4} 
            cta='Carica altri articoli' 
            elementNumber={rassegnaArticleFiltered.length}
            elements={rassegnaArticleFiltered}
            populatePage={(visibleElements) => populatePage(visibleElements)}/>
        </Grid>
        :
        <Grid container alignContent='center' alignItems='center' justify='center' className={classes.subTitleNoRes}>
              Al momento non sono disponibili articoli nella rassegna stampa
        </Grid>}

        <UfficioStampa rassegnaStampa={true}/>
        <Contact />        
      </div>
      <Footer/>
    </div>
  )
}

export default withStyles(newsStyle)(RassegnaStampa)
