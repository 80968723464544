import React, { useState } from "react"
import withStyles from "@material-ui/core/styles/withStyles"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import CarouselArrow from './CarouselArrow'

import CarouselStyle from './carouselStyle.jsx'
import PrimoPianoCard from "./PrimoPianoCard"
import ComingSoonCard from "./ComingSoonCard"

const HomeCarousel = ({classes, type, data, contactRef}) => {

    const [currentSlide, setCurrentSlide] = useState(0);

    const carouselCards = data && data.map((item, idx) => {

        if(type === 'primoPiano' && item.acfResidenzaPrimoPiano) { 
        return (
                <PrimoPianoCard key={idx} item={item.acfResidenzaPrimoPiano} isCurrentSlide={currentSlide === idx}/>
        );
        } else if(type === 'comingSoon' && item.acfResidenzaComingSoon){
        return (
                <ComingSoonCard key={idx} item={item.acfResidenzaComingSoon} isCurrentSlide={currentSlide === idx} contactRef={contactRef}/>
        );
        } else {
            return null;
        }
    });

    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('sm'));

    const settings = {
        className: classes.sliderContent,
        centerMode: carouselCards && carouselCards.length > 1 ? true : false,
        infinite: true,
        centerPadding: "60px",
        slidesToShow: 1,
        speed: 500,
        variableWidth: true,
        dots: true,
        arrows: !matches,
        beforeChange: (prev, next) => {            
            setTimeout( () => { setCurrentSlide(next); }, 10 );			
        },
        customPaging: index => {
            return (
              <div className={index === currentSlide ? classes.activeDots : classes.customDots}/>
            )
        },
        nextArrow: <CarouselArrow arrowStyle={{right: '0%', top: '0%', width: '15%', height: '100%'}} color='black' direction='forward'/>,
        prevArrow: <CarouselArrow arrowStyle={{left: '0%', top: '00%', width: '15%', height: '100%'}} color='black' direction='back'/>
    };

    return (
        <Slider {...settings} className={`${classes.slider} ${carouselCards.length === 1 && classes.sliderSingleContent}`}>
            {carouselCards}
        </Slider>
    );
}

export default withStyles(CarouselStyle)(HomeCarousel)
