import React from 'react'

import videoStyle from "./videoStyle.jsx"
import withStyles from "@material-ui/core/styles/withStyles"


const AudioPlayer = ({classes, src}) => {

  return (
    <audio className={classes.audioPlayer} src={src} controls>
      <track src='' kind="captions"></track>
    </audio>
  )
}

export default withStyles(videoStyle)(AudioPlayer)
