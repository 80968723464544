import homeStyle from '../Home/homeStyle';
import colors from "../../style/colors.scss";

import {
    garamond,
    titleMedium
  } from "../../style/style.jsx";

const ComingSoonCarouselStyle = theme => ({
    ...homeStyle(theme),    
    carouselContent: {
        paddingTop: '100px',
        paddingLeft: '150px',
        paddingRight: '100px',
        [theme.breakpoints.down('md')] : {
            padding: '50px'
        },
        [theme.breakpoints.down('sm')] : {
            padding: '0px',
            textAlign: 'center',
            alignItems: 'center'
        }
    },
    carouselImage: {
        margin: 'auto'
    },
    sliderContentCard: {
        border: '1px solid black',
        height: '700px',
        padding: '30px',
        [theme.breakpoints.down('sm')] : {
            height: '650px',
            padding: '10px'
        }
    },
    sliderContent: {
        paddingLeft: '15px',
        paddingRight: '15px',
        paddingBottom: '30px',
        border: 'none',
        outline: 'none',
        [theme.breakpoints.down('sm')] : {
            paddingLeft: '5px',
            paddingRight: '5px',
        }
    },
    sliderContentPreview: {
        margin: 'auto',
        marginTop: '30px'
    },
    sliderContentCloned:{
        paddingLeft: '30px',
        paddingRight: '30px',
        paddingBottom: '30px',
        border: 'none',
        outline: 'none',
        opacity : '0.6',
        [theme.breakpoints.down('sm')] : {
            paddingLeft: '10px',
            paddingRight: '10px',
        }
    },
    comingSoonTitle: {
        ...titleMedium,
        color: 'white',
        backgroundColor: colors.darkGrey,
        height: '50px'
    },
    comingSoonTitleHidden: {
        height: '50px'
    },
    description: {
        ...garamond,
        color: 'black',
        margin: '5px',
        width: '80%',
        marginTop: '40px',
        marginBottom: '40px',
        [theme.breakpoints.down('sm')] : {
            width: '100%',
            margin: '0px'
        }
    },
    comingSoonCard: {
        width: '1200px',
        [theme.breakpoints.down('lg')] : {
            width: '1000px'
        },
        [theme.breakpoints.down('md')] : {
            width: '800px'
        },
        [theme.breakpoints.down('sm')] : {
            width: '600px'
        },
        [theme.breakpoints.down('xs')] : {
            width: '300px'
        }
    },
    backgroundImage:{
        height: '100%',
        width: '100%',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        [theme.breakpoints.down('sm')] : {
            height: '50%'
        }
    },
    clickable: {
        cursor: 'pointer'
    }
});

export default ComingSoonCarouselStyle;