import React, { useState } from 'react'

import Grid from '@material-ui/core/Grid'
import withStyles from "@material-ui/core/styles/withStyles"

import newsStyle from "./newsStyle.jsx"

import Header from "../Header/Header"
import Footer from '../Footer/Footer'
import Contact from '../Contact/Contact'
import UfficioStampa from './UfficioStampa'
import HeaderNews from './HeaderNews'
import ArticleCard from './ArticleCard'
import copy from '../../constants/copy.json'

import moment from 'moment'

const News = ({classes, ...props}) => {

  const articles = props.pageContext && props.pageContext.news;

  const cat = props.pageContext && props.pageContext.category;

  const [activeTab] = useState(cat ? cat : 'all');

  const articleList = articles ? articles.filter((item) => activeTab === 'all' || item.acfNews.category === activeTab) : [];
  
  articleList.sort((a, b) => moment(b.acfNews.date, 'DD/MM/YYYY').valueOf() - moment(a.acfNews.date, 'DD/MM/YYYY').valueOf());

  const populatePage = (visibleElements) => {
    const list = articleList.map((item, idx) => {
      return (
        <ArticleCard article={item} key={idx}/>
      );
    });
    return list.slice(0, visibleElements);
  };

  return (
    <div style={{height: "100%"}}>
      <Header />
      <div className={classes.heroContainer}>
        <div className={classes.hero}/>
      </div>
      <div className={classes.newsContainer}>
        <Grid container item  justify='center'>
          <Grid container alignContent='center' alignItems='center' justify='center' className={classes.subTitle}>
            <div dangerouslySetInnerHTML={{__html: copy.news.subtitle}}/>
          </Grid>
          <Grid container alignContent='center' alignItems='center' justify='center' className={classes.sectionTitleUnderline}>
            {copy.news.title}
          </Grid>
        </Grid>
        <Grid container direction='column' alignContent='center' alignItems='center' justify='center' style={{marginBottom: '100px', marginTop: '20px'}}>
          <Grid item xs={10} md={4} className={classes.description}>
            {copy.news.description}
          </Grid>
          {/*<Grid item xs={10} md={4} className={classes.description}>
            {copy.news.description2}
          </Grid>*/}
          <Grid item xs={10} md={4} className={classes.citation}>
          {copy.news.description3}
          </Grid>
          <Grid item xs={10} md={4} className={classes.citationAuthor}>
          {copy.news.description4}
          </Grid>
        </Grid>
        <HeaderNews activeTab={activeTab} children={articleList} populatePage={(visibleElements) => populatePage(visibleElements)}/>
        <UfficioStampa rassegnaStampa={false}/>
        <Contact />        
      </div>
      <Footer/>
    </div>
  )
}

export default withStyles(newsStyle)(News)
