import {
    cormorantGaramond,
    haylardDisplayBold,
    garamond,
    haylardDisplay,
} from "../../style/style.jsx";

import lavoraConNoiHero from "../../images/lavoraconnoi_hero.jpg"
import colors from "../../style/colors.scss";


const LavoraConNoiStyle = theme => ({
    hiddenMobile: {
        [theme.breakpoints.down('sm')] : {
            "display": "none"
        },
    },
    heroContainer: {
        height: 'calc(100% - 240px)', 
        width:'100%', 
        overflow: 'hidden',
        marginTop: '64px'
    },
    hero: {      
        width:'100%', 
        height: '100%',
        backgroundImage: `url(${lavoraConNoiHero})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        transition: 'transform .5s ease',
        display: 'block',
        '&:hover': {
          transform: 'scale(1.2)'
        }
    },

    title: {
        ...cormorantGaramond,
        "margin-top": "50px",
        "font-size": "30px",
        "line-height": "36px",
        "font-style": "italic",
        "color": 'black'
    },
    subtitle: {
        ...haylardDisplayBold,
        "text-transform": "uppercase",
        "font-size": "30px",
        "line-height": "36px",
        "text-decoration": "underline",
        "text-underline-position": "under",
        "max-width": "33.333%",
        [theme.breakpoints.down('md')] : {
            "max-width": "90%"
        },
        [theme.breakpoints.down('sm')] : {
            fontSize: '20px'
        }
    },
    description1: {
        ...garamond,
        "text-align": "center",
        "margin-top": "50px",
        "max-width": "33.333%",
        [theme.breakpoints.down('md')] : {
            "max-width": "90%"
        },
        [theme.breakpoints.down('xs')] : {
            marginTop: '20px'
        },
    },
    description2: {
        ...garamond,
        justifyContent: "center",
        "text-align": "center",
        "margin-top": "40px",
        "max-width": "25%",
        [theme.breakpoints.down('md')] : {
            "max-width": "70%",
        },
        [theme.breakpoints.down('sm')] : {
            "max-width": "90%",
        },
    },
    heroTextContainer: {
        width:"100%",
        "display": "flex",
        "justify-content": "center",
        "flex-direction": "column",
        "align-items": "center",
        color: 'black',
        [theme.breakpoints.down('sm')] : {
            width:"100%",
            "margin-top": "40px"
        }
    },
    firstTextBlock: {
        display: "flex",
        "justify-content": "center",
        "align-items": "center",
        "text-align": "center"
    },

    blocksContainer: {
        "display": "flex",
        "justify-content": "center",
        "max-width": "50%",
        [theme.breakpoints.down('md')] : {
            maxWidth: "80%"
        },
        [theme.breakpoints.down('sm')] : {
            maxWidth: "100%",
            "justify-content": "space-between",
            paddingLeft: "10px",
            paddingRight: "10px"
        },
    },
    singleNumberBlock: {
        "display": "inline-flex",
        "flex-direction": "column",
        "border-right": "1px solid black",
        "&:last-child": {
            "border-right": "none"
        },
        "padding-left": "20px",
        "padding-right": "20px",
        "padding-top": "20px",
        "padding-bottom": "20px",
        width: "auto",
        [theme.breakpoints.down('sm')] : {
            "padding": "20px",
            "border": "none",
            "background-color": colors.lightGrey3,
            margin: "0",
            "&:not(:first-child)": {
                "margin-top": "10px",
                "max-width": "49%",
            }
        }
    },
    bigNumber: {
        ...garamond,
        display: "inline-flex",
        "font-size": "80px",
        "line-height": "80px",
        "color": "black",
        [theme.breakpoints.down('md')] : {
            "font-size": "50px",
            "line-height": "50px",
        }
    },
    smallNumber: {
        ...garamond,
        display: "inline-flex",
        "font-size": "30px",
        "line-height": "30px",
        "color": "black",
        [theme.breakpoints.down('md')] : {
            "font-size": "20px",
            "line-height": "20px",
        }
    },
    numberBlockContainer: {
        "display": "flex",
        "justify-content": "center",
        "align-items": "center",
        "width": "auto",
        "flex-direction": "column"
    },
    numberContainer: {
        "display": "flex",
        "align-items":"flex-end",
        "flex-direction": "row",
        "justify-content": "center"
    },
    singleNumberBlockTitle: {
        ...haylardDisplay,
        "font-size": "16px",
        "letter-spacing": "normal",
        "text-transform": "uppercase",
        "color": "black",
        "font-weight": "300",
        "text-align": "center"
    },
    titleNumberSection: {
        ...garamond,
        "max-width": "33.333%",
        width: "auto",
        "margin-top": "40px",
        "margin-bottom": "20px",
        "text-align": "center",
        [theme.breakpoints.down('md')] : {
            "max-width": "90%",
        },
    }
});

export default LavoraConNoiStyle;