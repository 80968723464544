import React from "react"
import { Helmet } from "react-helmet"
import MessengerIcon from '../components/Home/MessengerIcon'
import Grid from '@material-ui/core/Grid'
import RassegnaStampa from '../components/News/RassegnaStampa/RassegnaStampa'

import "../style/style.scss"
import "../style/font.css"

const rassegna = (props) => (
    <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>Rassegna stampa - Abitare Co.</title>
            <meta name="description" content={"Nuove costruzioni a Milano - Abitare Co."} />
        </Helmet>
        <Grid style={{ position: 'fixed', zIndex: '1000', width:'60px', right: '0px', bottom: '15px' }}>
            <Grid container  className={`iconContainer`} alignContent='center' justify='center'>
            <MessengerIcon type='whatsapp' />
            </Grid>
            <Grid container  className={`iconContainer`} alignContent='center' justify='center'>
            <MessengerIcon type='tel' />
            </Grid>
        </Grid>
        <RassegnaStampa {...props} />
    </>
)

export default rassegna
