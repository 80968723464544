import React from "react"
import Grid from '@material-ui/core/Grid'
import NumberIconListItem from "./NumberIconListItem"
import NumberIconListStyle from './NumberIconListStyle.jsx'
import withStyles from "@material-ui/core/styles/withStyles"

const NumberIconList = ({classes, img, elements, altText, otherClasses}) => {

    let list = elements.map((e, i) => {
        return (<NumberIconListItem data={e} key={i} index={i + 1} showLine={i !== (elements.length - 1)} />)
    })

  return (
    <Grid item xs={12} className={classes.listContainer}>
        <Grid item xs={11} md={5}>
            {list}
        </Grid>
    </Grid>
  )
}

export default withStyles(NumberIconListStyle)(NumberIconList)