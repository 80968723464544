import homeStyle from '../../Home/homeStyle';
import colors from "../../../style/colors.scss";

const CarouselStyle = theme => ({
    ...homeStyle(theme),
    slider: {
        width: '100%',
        height: '100%',
        display: 'flex',
        border: 'none',
        outline: 'none'
    },
    verticalDots: {
        left: '25%',
        listStyle: 'none',
        display: 'block',
        position: 'absolute',
        top: '35%',
        textAlign: 'left',
        cursor: 'pointer'
    },
    customDots: {
        width: "10px",
        height: "10px",
        border: "1px solid",
        borderColor: colors.darkGrey,
        margin: "5px",
        [theme.breakpoints.down('sm')] : {
            display: 'none'
        }
    },
    activeDots:{
        width: "10px",
        height: "10px",
        border: "1px solid",
        borderColor: colors.darkGrey,
        margin: "5px",
        background: colors.darkGrey,
        [theme.breakpoints.down('sm')] : {
            display: 'none'
        }
    },
    carouselContent: {
        marginTop: 'auto',
        marginBottom: 'auto',
        paddingLeft: '70px',
        color: 'black',
        [theme.breakpoints.down('sm')] : {
            padding: '20px',
            textAlign: 'center'
        }
    },
    carouselImage: {
        margin: 'auto'
    },
    sliderContent: {
        border: 'none',
        outline: 'none'
    },
    clickable:{
        cursor: 'pointer'
    }
});

export default CarouselStyle;