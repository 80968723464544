import React from 'react'
import { navigate } from "gatsby"

import { Button, Grid } from '@material-ui/core'
import newsStyle from "./newsStyle.jsx"
import withStyles from "@material-ui/core/styles/withStyles"
import Header from "../Header/Header"

import Footer from '../Footer/Footer'
import Contact from '../Contact/Contact'
import SocialShare from './SocialShare'
import OtherArticleSide from './OtherArticleSide'
import RassegnaStampaSide from './RassegnaStampa/RassegnaStampaSide'
import copy from '../../constants/copy.json'

import downloadIcon from "../../images/svg/icon_download.svg"

import articlesCat from '../../constants/ArticlesCategory'

const NewsPage = ({ classes, articleItem, isPreview, ...props }) => {

  const articlesList = props.pageContext && props.pageContext.allProducts;
  const rassegnaList = props.pageContext && props.pageContext.rassegnaArticles;

  const article = isPreview ? articleItem : props.pageContext && props.pageContext.product;

  const [isOtherNewsBarVisible, setOtherNewsBarVisible] = React.useState(false);

  const newsContainerRef = React.useRef();

  const cat = articlesCat.find((item) => article.acfNews.category === item.code);

  const TOP_OFFSET = 56; // px

  const updateOtherNewsBarVisibility = () => {
    const newsContainerDistanceFromTop = newsContainerRef.current.getBoundingClientRect().top - TOP_OFFSET;
    setOtherNewsBarVisible(newsContainerDistanceFromTop < 0);
  } 

  React.useEffect(() => {
    window.addEventListener('scroll', updateOtherNewsBarVisibility)
    return () => {
      window.removeEventListener('scroll',updateOtherNewsBarVisibility)
    }
  }, [newsContainerRef])

  return (
    <div style={{height: "100%"}}>
      {!isPreview && <Header />}
      <div className={`${isPreview ? classes.heroContainerPreview : classes.heroContainer}`}>
        <div className={classes.articleHero} style={{ backgroundImage: `url(${article.acfNews.mainimage && article.acfNews.mainimage.sourceUrl})` }} />
      </div>
      <div ref={newsContainerRef}>
        <Grid container className={classes.newsPageContent}>
          <Grid container item xs={1} className={classes.mobileHidden} />
          <Grid container item xs={12} md={8} direction='column' className={classes.articleHeader}>
            <Grid item className={classes.articleDate}>
              {article.acfNews.date}
            </Grid>
            <Grid item className={classes.titlePage}>
              {article.acfNews.title}
            </Grid>
            <Grid item className={classes.descriptionPage}>
              {article.acfNews.description}
            </Grid>
          </Grid>
          <Grid container item xs={12} className={classes.articleContent}>
            {isPreview && <Grid container item xs={1} className={classes.mobileHidden} />}
            {!isPreview && <SocialShare
              xs={1}
              md={1}
              direction='column'
              justify='flex-start'
              alignItems='flex-end'
              classesStyle={classes.socialIconContainer + ' ' + classes.mobileHidden} />}
            <Grid container item xs={12} md={8} direction='column' className={classes.articleHeader}>
              <Grid container className={classes.articleHtml}>
                <div dangerouslySetInnerHTML={{ __html: article.acfNews.content }} />
              </Grid>
              <Grid container direction='row' justify='flex-end'>
                {article.acfNews.articolopdf &&
                  <Grid container item sm={12} md={6} direction='row' onClick={() => window.open(article.acfNews.articolopdf.mediaItemUrl, '_blank')}>
                    <Grid item>
                      <img className={classes.svgDownload} src={downloadIcon} alt={""} />
                    </Grid>
                    <Grid item className={classes.linkCta}>
                      Scarica l'articolo
                        <Grid item className={classes.linkCtaBorder} />
                    </Grid>
                  </Grid>}
                {article.acfNews.url &&
                  <Grid container item sm={12} md={6} direction='row' justify='flex-end' className={classes.svgExternalLinkContainer} onClick={() => window.open(article.acfNews.url, '_blank')}>
                    <Grid item>
                      <img className={classes.svgExternalLink} src={downloadIcon} alt={""} />
                    </Grid>
                    <Grid item className={classes.linkCta}>
                      Visita il sito/guarda il video
                        <Grid item className={classes.linkCtaBorderLong} />
                    </Grid>
                  </Grid>}
              </Grid>
              <Grid container className={classes.articleFooterContainer} alignItems='center'>
                <Grid container item xs={12} md={6}>
                  <Grid className={classes.sourceStyle}>
                    Fonte:&nbsp;
                    </Grid>
                  <Grid className={classes.sourceStyleName}>
                    {article.acfNews.source}
                  </Grid>
                </Grid>
                {!isPreview && <SocialShare
                  xs={12}
                  md={6}
                  direction='row'
                  justify='flex-end'
                  alignItems='center'
                  classesStyle={classes.socialIconContainerFooter} />}
              </Grid>
            </Grid>
            {!isPreview &&
            <Grid container item md={3} direction='column' className={classes.mobileHidden}>
              <OtherArticleSide articlesList={articlesList} currentArticle={article} maxArticle={5}/>
              <RassegnaStampaSide rassegnaList={rassegnaList} maxArticle={5}/>
            </Grid>}               
          </Grid>             
        </Grid>
        {!isPreview &&           
        <Grid container item xs={12} direction='column' alignItems='center' className={`${classes.mobileShow} ${classes.articleList} ${isOtherNewsBarVisible ? classes.otherNewsBarVisible : classes.otherNewsBarHidden}`}>
          <Grid item className={classes.titleOtherArticle} style={{marginBottom: '2px', marginTop: '2px'}}>
            SFOGLIA ALTRE NEWS DA 
          </Grid>
          <Grid container item justify='center'>
            <Button classes={{root: classes.greyButton + ' ' + classes.buttonFont + ' ' + classes.otherNewsBarButton}} onClick={() => navigate(`/news-${cat.code}#section`)}>
              {cat.label}
            </Button>
            <Button classes={{root: classes.greyButton + ' ' + classes.buttonFont + ' ' + classes.otherNewsBarButton}} onClick={() => navigate(`/rassegna-stampa`)}>
              {copy.news.rassegnaStampa.title}
            </Button>
          </Grid>
        </Grid>} 
        {!isPreview && <Contact />}      
      </div>
      {!isPreview && <Footer/>}
    </div>
  )
}

export default withStyles(newsStyle)(NewsPage)
