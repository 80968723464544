import React from "react"

import Grid from '@material-ui/core/Grid'
import homeStyle from "./homeStyle"
import withStyles from "@material-ui/core/styles/withStyles"
import iconSocialWh from '../../images/svg/icon_contatti_whatsapp.svg'
import iconSocialF from '../../images/svg/icon_contatti_messenger.svg'
import iconContactTel from '../../images/svg/icon_contatti_tel.svg'
import format from "../../utils/format"

const MessengerIcon = ({classes, type}) => {
  const whatsappPhoneNumber = '+393938971053';
  const officePhoneNumber = '+390248958416'

  const isMobileOrTablet = () => {
    return /(android|iphone|ipad|mobile)/i.test(navigator.userAgent);
  }

  const getBoxPositionOnWindowCenter = (width, height) => ({
    left: window.outerWidth / 2 + (window.screenX || window.screenLeft || 0) - width / 2,
    top: window.outerHeight / 2 + (window.screenY || window.screenTop || 0) - height / 2,
  });

  const openMessengerLink = () => {

    if(type === 'whatsapp'){

      const config = {
        width : 550,
        height : 400,
        location: 'no',
        toolbar: 'no',
        status: 'no',
        directories: 'no',
        menubar: 'no',
        scrollbars: 'yes',
        resizable: 'no',
        centerscreen: 'yes',
        chrome: 'yes',
        ...getBoxPositionOnWindowCenter(550, 400)
      };

      window.open('https://' + (isMobileOrTablet() ? 'api' : 'web') + `.whatsapp.com/send?phone=${whatsappPhoneNumber}`, '', Object.keys(config)
        .map(key => `${key}=${config[key]}`)
        .join(', '),);
    } else if(type === 'messenger') {

      const config = {
        width: 1000,
        height: 820,
        location: 'no',
        toolbar: 'no',
        status: 'no',
        directories: 'no',
        menubar: 'no',
        scrollbars: 'yes',
        resizable: 'no',
        centerscreen: 'yes',
        chrome: 'yes',
        ...getBoxPositionOnWindowCenter(1000, 820)
      };
        
      window.open('http://m.me/abitareco', '', Object.keys(config)
        .map(key => `${key}=${config[key]}`)
        .join(', '),);
    }
  };

  return (
    <Grid item style={{height : '100%', width: '100%'}} onClick={() => openMessengerLink()}>
      {type === 'whatsapp' && <img src={iconSocialWh} alt='' className={classes.iconShare}/>} 
      {type === 'messenger' && <img src={iconSocialF} alt='' className={classes.iconShare}/>}           
      {type === 'tel' && <a href={`tel:${officePhoneNumber}`} className={classes.iconShareContainer}><img src={iconContactTel} alt='' className={classes.iconShare} title={format.phoneNumberFormat(officePhoneNumber)}/></a>}           
    </Grid>
  )
}

export default withStyles(homeStyle)(MessengerIcon)
