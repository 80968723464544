import React from 'react'
import { navigate } from "gatsby"

import { Grid, Button } from '@material-ui/core'
import { zoneCards } from '../../../constants/NewHomeCard'
import { Close } from '@material-ui/icons'

import mapStyle from './mapStyle'
import withStyles from "@material-ui/core/styles/withStyles"

const ProjectMapCard = ({classes, item, setSelectedPlace}) => {

  const zone = (item.acfResidenza.zone !== "italia" && item.acfResidenza.zone !== "hinterland") ? 'milano' : zoneCards.find((z) => z.zoneCode === item.acfResidenza.zone).zoneUrl;

  return (
    <Grid container className={classes.markerDetail} alignContent='space-between'>
        <Close className={classes.closeIcon} onClick={() => setSelectedPlace('')}/>
        <Grid item style={{
            backgroundImage : `url(${item.acfResidenza.imagepreview && item.acfResidenza.imagepreview.sourceUrl})`, 
            height:'60%',
            width: '100%',
            backgroundSize: 'cover',
            backgroundPosition: 'center'}}/>
        <Grid container item className={classes.title} justify='center'>
              {item.acfResidenza.name}
        </Grid>
        <Grid container item justify="center" alignItems="center">
            <Button classes={{root: classes.greyButton}} onClick={()=> navigate(`/nuove-costruzioni-${zone}/${item.acfResidenza.seourl ? item.acfResidenza.seourl : item.acfResidenza.name.trim()}`)}> 
                SCOPRI
            </Button>
        </Grid>

    </Grid>
  )
};

export default withStyles(mapStyle)(ProjectMapCard)