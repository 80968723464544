import React from 'react'
import { navigate } from "gatsby"

import Grid from '@material-ui/core/Grid'
import homeStyle from "./homeStyle"
import withStyles from "@material-ui/core/styles/withStyles"
import { Button } from '@material-ui/core'

import CercaMappa from '../../images/icons/CercaMappa'

import copy from '../../constants/copy.json'

const HomeCard = ({classes, item}) => {

  const goToMap = (zone) => {
    navigate(`/nuove-costruzioni-${zone}`);
  }

  return (
      <Grid container item xs={12} md={4} direction='row' justify='center' className={classes.primaCasaCard}>
        <Grid container justify='center' direction='column' className={classes.primaCasaCardImage} onClick={() => goToMap(item.zoneUrl) }>
          <Grid container className={classes.backgroundImage} style={{backgroundImage : `url(${item.zone})`}}>
            <Grid container item alignItems='flex-end' justify='center' alignContent='flex-end' className={classes.cardOverlay}>
                <Grid item xs={3} style={{marginBottom: '20px'}}>
                  <CercaMappa zoneCode={item.zoneCode} dimension='100px'/>
                </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container item direction='column' alignItems='center' className={classes.cardMargin}>
          <Grid item className={classes.subTitle}>
            {copy.home.cercaCasa.cerca}
          </Grid>
          <Grid item className={`${classes.title} ${classes.homeCardTitleContainer}`}>
            {item.description}
          </Grid>
          <Grid item className={classes.border60}/>
          <Grid container item justify="center" alignItems="center">
            <Button classes={{root: classes.greyButton}} onClick={() => goToMap(item.zoneUrl) }>
              {item.cta}
            </Button>
          </Grid>
        </Grid>
    </Grid>
  )
};

export default withStyles(homeStyle)(HomeCard)
