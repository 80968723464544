const articlesCat = [
    {
        code: 'all',
        label: 'Tutte le news',
        labelH1: 'Tutte le <h1>news</h1>',
        createPage: false,
        path: '/news',
        title: 'News - Abitare Co.',
        description: 'Le news di Abitare Co: informazioni e notizie sul mercato immobiliare di Milano e Hinterland per rimanere aggiornati sulle evoluzioni del settore.'
    },
    {
        label: 'News dai cantieri',
        labelH1: '<h1>News dai cantieri</h1>',
        createPage: true,
        code: 'dai-cantieri',
        path: '/nuovi-cantieri-immobiliari',
        title: 'Nuovi cantieri immobiliari a Milano - Abitare Co.',
        description: 'Scopri tutti i nuovi cantieri immobiliari Abitare Co a Milano e Hinterland per trovare l’immobile ideale e prenotare la tua partecipazione all’Open Day'
    },
    {
        label: 'Press',
        labelH1: 'Press',
        createPage: true,
        code: 'press',
        path: '/press',
        title: 'Rassegna stampa - Abitare Co.',
        description: 'La rassegna stampa Abitare CO: articoli su importanti magazine e interviste al management della prima agenzia immobiliare di nuove costruzioni a Milano.'
    },
    {
        label: 'Real Estate Insights',
        labelH1: '<h1>Real Estate Insights</h1>',
        createPage: true,
        code: 'real-estate-insights',
        path: '/news-real-estate-insights',
        title: 'News del mercato immobiliare - Abitare Co.',
        description: 'La visione Abitare Co sul mercato immobiliare di Milano e provincia: analisi e approfondimenti sull’andamento del settore e sugli investimenti immobiliari'
    }
];

export default articlesCat;