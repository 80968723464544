import React from 'react';
import SvgIcon from "@material-ui/core/SvgIcon";

const Facebook = (props) => (
  <SvgIcon {...props}>    
    <svg version="1.1" id="Livello_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
      viewBox="0 0 30 29.8" style={{enableBackground: 'new 0 0 30 29.8'}} >
    <path className="st0" d="M30,15c0-8.3-6.7-15-15-15S0,6.7,0,15c0,7.4,5.4,13.7,12.7,14.8V19.3H8.9V15h3.8v-3.3c0-3.8,2.2-5.8,5.7-5.8
      c1.1,0,2.2,0.1,3.4,0.3v3.7h-1.9c-1.2-0.2-2.3,0.7-2.4,1.9c0,0.2,0,0.3,0,0.5V15h4.2l-0.7,4.3h-3.5v10.5C24.6,28.7,30,22.4,30,15z"
      />
    </svg>
  </SvgIcon>
);

const FacebookIcon = ({extraClasses}) => {
  
  return (
  <div>
    <Facebook className={extraClasses} />
  </div>
)};

export default FacebookIcon;