import React from 'react'

import { Grid, Button } from '@material-ui/core'

import projectsStyle from "./projectsStyle.jsx"
import withStyles from "@material-ui/core/styles/withStyles"

import AImage from '../../images/svg/A_scuro.svg'
import ZoneCard from './ZoneCard'
import copy from '../../constants/copy.json'

const ZoneSection = ({classes, zone, setSelectedTab, resRef}) => {

  return (
    <Grid container className={classes.zoneSectionContainer}>
      <Grid item xs={12} md={6} className={classes.zoneSectionImage} style={{backgroundImage : `url(${zone.image})`}}/>
      <Grid container item xs={12} md={6}  justify='center' direction='column' alignItems='center' className={classes.zoneSection + ' ' + classes.AContainer} style={{background : `url(${AImage})`}}>
        <ZoneCard card={zone} idx={zone.code} section={true} selectedTab={zone} setSelectedTab={setSelectedTab}/>
        <Grid container alignContent='center' alignItems='center' justify='center' className={classes.title + ' ' + classes.titleZoneSection}>
          {zone ? zone.title : zone.titleH1}
        </Grid>
        <Grid container item alignContent='center' alignItems='center' justify='center' className={classes.description + ' ' + classes.descriptionTab}>
          {zone.description}
        </Grid>
        <Grid container item justify='center'>
          <Button classes={{root: classes.greyButton}}  onClick={() => resRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })}>
            {copy.zone.cta}
          </Button>
        </Grid>
      </Grid>
      <Grid container item className={classes.zoneSectionImages + ' ' + classes.mobileHidden}>
        <Grid item md={4}>
          <Grid item className={classes.backgroundImageZs} style={{backgroundImage : `url(${zone.images[0].img})`}}/>
        </Grid>
        <Grid item md={4}>        
          <Grid item className={classes.backgroundImageZs2} style={{backgroundImage : `url(${zone.images[1].img})`, marginBottom: '5px'}}/>    
          <Grid item className={classes.backgroundImageZs2} style={{backgroundImage : `url(${zone.images[2].img})`}}/>
        </Grid>
        <Grid item md={4}>        
          <Grid item className={classes.backgroundImageZs} style={{backgroundImage : `url(${zone.images[3].img})`}}/>
        </Grid>
      </Grid>
      <Grid container className={classes.zoneSectionImages + ' ' + classes.mobileShow}>
        <Grid item xs={8} style={{padding: '1px'}}>
          <Grid item className={classes.backgroundImageZs} style={{backgroundImage : `url(${zone.images[1].img})`}}/>
        </Grid>
        <Grid item xs={4} style={{padding: '1px'}}>
          <Grid item className={classes.backgroundImageZs} style={{backgroundImage : `url(${zone.images[3].img})`}}/>
        </Grid>
        <Grid item xs={4} style={{padding: '1px'}}>
          <Grid item className={classes.backgroundImageZs} style={{backgroundImage : `url(${zone.images[0].img})`}}/>
        </Grid>
        <Grid item xs={8} style={{padding: '1px'}}>
          <Grid item className={classes.backgroundImageZs} style={{backgroundImage : `url(${zone.images[2].img})`}}/>
        </Grid>
      </Grid>
      <Grid container alignContent='center' alignItems='center' justify='center' className={classes.zoneSection2}>
        <Grid container alignContent='center' alignItems='center' justify='center' className={classes.subTitle}>
          {copy.zone.curiosità}
        </Grid>
        <Grid container alignContent='center' alignItems='center' justify='center' className={classes.title}>
          {copy.zone.contesto}
        </Grid>
        <Grid container item alignContent='center' alignItems='center' justify='center' className={classes.description}>
          {zone.description2}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default withStyles(projectsStyle)(ZoneSection)
