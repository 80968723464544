import React from "react"
import { Helmet } from "react-helmet"
import Privacy from "../components/Static Pages/Privacy"
import "../style/style.scss"
import "../style/font.css"

const NormePrivacy = () => (
    <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>Privacy & Cookie policy - Abitare Co.</title>
            <meta name="description" content={"La privacy e cookie policy Abitare Co fornisce informazioni sul trattamento dei dati sensibili e sui sistemi di tracciamento adottati all'interno del sito."} />
        </Helmet>
        <Privacy />
    </>
)

export default NormePrivacy
