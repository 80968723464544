import React from 'react'
import { Grid } from '@material-ui/core'

import { ClickAwayListener } from '@material-ui/core';

import mapStyle from './mapStyle'
import withStyles from "@material-ui/core/styles/withStyles"
import ProjectMapCard from './ProjectMapCard';

const MapMarker = ({classes, item, isSelected, setSelectedPlace}) => {

  return (
    <ClickAwayListener onClickAway={() => {isSelected && setSelectedPlace('')}}>    
        <Grid className={classes.markerContainer}>
            {isSelected ?
            <>
                <ProjectMapCard 
                  item={item} 
                  setSelectedPlace={(value) => setSelectedPlace(value)}/>
                <Grid className={classes.markerSelectedBorder} onClick={() => isSelected && setSelectedPlace('')} onKeyDown={() => {}}>
                    <div className={classes.markerSelected}/>
                </Grid>
            </>
            :
            <Grid onClick={() => setSelectedPlace(item.id)} onKeyDown={() => {}} className={classes.marker}/>}
        </Grid>
    </ClickAwayListener>
  )
};
export default withStyles(mapStyle)(MapMarker)