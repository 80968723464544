import projectsStyle from '../projectsStyle'

import {
    haylardDisplay,
    greyButton,
    greyButtonHover,
    garamond   
  } from "../../../style/style.jsx";

const mapStyle = theme => ({
    ...projectsStyle(theme),
    marker:{
      position: 'absolute',
      transform: 'translate(-50%, -50%)',
      left: '50%',
      top: '50%',
      height: '25px',
      width: '25px',
      border: '3px solid black',
      borderRadius: '35px'
    },
    markerSelected: {
      height: '25px',
      width: '25px',
      backgroundColor: 'black',
      borderRadius: '35px'
    },
    markerSelectedBorder: {
      position: 'absolute',
      transform: 'translate(-50%, -50%)',
      left: '50%',
      top: '50%',
      height: '41px',
      width: '41px',
      border: '3px solid black',
      borderRadius: '35px',
      alignItems: 'center',
      justifyContent: 'center',
      display: 'flex',
      zIndex: '1000'
    },
    markerContainer: {
      height: '41px',
      width: '41px',
      alignItems: 'center',
      justifyContent: 'center',
      display: 'flex'
    },
    markerCluster: {
      ...haylardDisplay,
      position: 'absolute',
      transform: 'translate(-50%, -50%)',
      left: '50%',
      top: '50%',
      height: '25px',
      width: '25px',
      backgroundColor: 'black',
      borderRadius: '35px',
      color: 'white',
      fontWeight: 'bold',
      textAlign: 'center',
      fontSize: '14px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      letterSpacing: '1'
    },
    markerDetail: {
      position: 'absolute',
      transform: 'translate(-50%, -57%)',
      left: '50%',
      width: '210px',
      background: 'white',
      height: '270px',
      zIndex: '999',
      border: '1px solid black',
      padding: '15px 15px 30px 15px'
    },
    title : {
      lineHeight: '1.2',
      ...garamond,
      fontStyle: 'italic',
      fontSize: '18px',
      margin: '15px',
      textAlign: 'center'
    },
    greyButton : {
      ...greyButton,
      height: '35px !important',
      minWidth: '100px !important',
      '&:hover': {
          ...greyButtonHover
      }
    },
    closeIcon: {
      position: 'absolute',
      right: '-10px',
      top: '-10px',
      height: '25px',
      width: '25px',
      color: 'white',
      background: 'black',
      borderRadius: '20px'
    }
});

export default mapStyle;