import React from "react"
import { Helmet } from "react-helmet"

import NewsPage from '../components/News/NewsPage'

import "../style/style.scss"
import "../style/font.css"
import MessengerIcon from '../components/Home/MessengerIcon'
import Grid from '@material-ui/core/Grid'
const article = (props) => {
    return (
    <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>{props.pageContext.seoTitle || `${props.pageContext.title} - Abitare Co.`}</title>
            <meta name="description" content={props.pageContext.seoDescription || props.pageContext.title} />
        </Helmet>
        <Grid style={{ position: 'fixed', zIndex: '1000', width:'60px', right: '0px', bottom: '15px' }}>
            <Grid container  className={`iconContainer`} alignContent='center' justify='center'>
            <MessengerIcon type='whatsapp' />
            </Grid>
            <Grid container  className={`iconContainer`} alignContent='center' justify='center'>
            <MessengerIcon type='tel' />
            </Grid>
        </Grid>
        <NewsPage {...props} />
    </>
)}

export default article
