import React, { useState } from 'react'
import { navigate } from "gatsby"

import { Grid, RootRef, Select, MenuItem } from '@material-ui/core'

import projectsStyle from "./projectsStyle.jsx"
import withStyles from "@material-ui/core/styles/withStyles"
import Header from "../Header/Header"
import Footer from '../Footer/Footer'
import NewsLetter from '../NewsLetter/NewsLetter'
import Contact from '../Contact/Contact'
import MapCard from './Map/MapCard'
import ProjectCard from './ProjectCard'
import Map from './Map/Map'
import MapCardSelect from './Map/MapCardSelect'

import { zoneCards } from '../../constants/NewHomeCard'
import { StickyContainer, Sticky } from 'react-sticky'
import Pagination from '../Pagination/Pagination'

import copy from '../../constants/copy.json'

const Projects = ({ classes, ...props }) => {

  // force update
  const [mapKey, setMapKey] = useState(Math.random());
  const [selectedTab] = useState(props.pageContext.itZone.code);

  const residenze = props.pageContext && props.pageContext.residenze;

  const residenzeFiltered = residenze ? residenze.filter((item) => {
    if (item.acfResidenza.soldout === 'soldOut') {
      return false;
    }
    if (selectedTab === 'milano') {
      return (item.acfResidenza.zone !== "italia" && item.acfResidenza.zone !== "hinterland");
    } else {
      return item.acfResidenza.zone === selectedTab;
    }
  }) : [];

  residenzeFiltered.sort((a, b) => {
    if (a.acfResidenza.rating === b.acfResidenza.rating) {
      return b.acfResidenza.price - a.acfResidenza.price;
    }
    return a.acfResidenza.rating - b.acfResidenza.rating;
  });

  const residenzeSoldOut = residenze ? residenze.filter((item) => {
    if (item.acfResidenza.soldout === 'soldOut') {
      if (selectedTab === 'milano') {
        return (item.acfResidenza.zone !== "italia" && item.acfResidenza.zone !== "hinterland");
      } else {
        return item.acfResidenza.zone === selectedTab;
      }
    }
    return false;
  }) : [];

  residenzeSoldOut.sort((a, b) => {
    if (a.acfResidenza.rating === b.acfResidenza.rating) {
      return b.acfResidenza.price - a.acfResidenza.price;
    }
    return a.acfResidenza.rating - b.acfResidenza.rating;
  });

  const populateResCards = (visibleElements) => {
    const list = residenzeFiltered.map((item, idx) => {
      return (
        <ProjectCard
          item={item}
          key={idx}
          last={visibleElements % 2 !== 0 ? idx === visibleElements - 1 : idx >= visibleElements - 2} />
      );
    });
    return list.slice(0, visibleElements);
  };

  const populateResSoldOutCards = (visibleElements) => {
    const list = residenzeSoldOut.map((item, idx) => {
      return (
        <ProjectCard
          item={item}
          key={idx}
          last={residenzeSoldOut.length % 2 !== 0 ? idx === residenzeSoldOut.length - 1 : idx >= residenzeSoldOut.length - 2}
          isSoldOut={true} />
      );
    });
    return list.slice(0, visibleElements);
  };

  const changeZone = (zone) => {
    setMapKey(Math.random());
    navigate(`/nuove-costruzioni-${zone}`);
  }

  const enabledZone = zoneCards.filter((zone) => {
    const residenzeFiltered = residenze ? residenze.filter((item) => {
      if (item.acfResidenza.soldout === 'soldOut') {
        return false;
      }
      if (zone.zoneCode === 'milano') {
        return (item.acfResidenza.zone !== "italia" && item.acfResidenza.zone !== "hinterland");
      } else {
        return item.acfResidenza.zone === zone.zoneCode;
      }
    }) : [];
    return residenzeFiltered && residenzeFiltered.length > 0;
  });

  const homeCards = enabledZone.map((item, idx) => {
    return (
      <MapCard item={item} idx={idx} key={idx} selectedTab={selectedTab} changeZone={(zone) => changeZone(zone)} height={`${100 / enabledZone.length}%`} />
    )
  });

  const homeCardsSelect = enabledZone.map((item, idx) => {
    return (
      <MenuItem className={classes.inputContactPropsRoot} value={item.zoneCode} key={idx}>
        <MapCardSelect item={item} selectedTab={selectedTab} />
      </MenuItem>
    )
  });

  const mapRef = React.useRef();

  const pageContent = (
    <>
      <Grid container item justify='center' className={classes.cardsContainer}>
        <Grid container alignContent='center' alignItems='center' justify='center' className={classes.sectionTitleUnderline}>
          <div dangerouslySetInnerHTML={{ __html: copy.progetti[selectedTab].title }} />
        </Grid>
        <Grid container alignContent='center' alignItems='center' justify='center' className={classes.subTitle}>
          {selectedTab === 'italia' ? copy.progetti.italia.subtitle1 : copy.progetti.subtitle1}
        </Grid>
        <Grid container item alignContent='center' alignItems='center' justify='center' className={classes.description}>
          {selectedTab === 'italia' ? copy.progetti.italia.subtitle2 : copy.progetti.subtitle2}
        </Grid>
        {residenzeFiltered.length > 0 ?
          <Grid container className={classes.resCardsContainer}>
            <Pagination
              pagination={6}
              cta={copy.progetti.otherResCta}
              elementNumber={residenzeFiltered.length}
              elements={residenzeFiltered}
              populatePage={(visibleElements) => populateResCards(visibleElements)} />
          </Grid>
          :
          <Grid container alignContent='center' alignItems='center' justify='center' className={classes.subTitleNoRes}>
            {copy.progetti.projectPage.noRes}
          </Grid>}
      </Grid>
      {residenzeSoldOut.length > 0 &&
        <Grid container item className={classes.soldOutCardsContainer} justify='center'>
          <Grid container justify='center' direction='column' alignItems='center'>
            <Grid container item alignContent='center' alignItems='center' justify='center' className={classes.subTitleSoldOut}>
              Abitare Co.
            </Grid>
            <Grid container alignContent='center' alignItems='center' justify='center' className={classes.sectionTitleUnderline}>
              {copy.progetti[selectedTab].soldOut}
            </Grid>
            <Grid container alignContent='center' alignItems='center' justify='center' className={classes.sectionTitle + ' ' + classes.soldOutButton}>
              {copy.progetti.projectPage.soldOut.soldOut}
            </Grid>
          </Grid>
          <Grid container className={classes.resCardsContainer}>
            <Pagination
              pagination={4}
              cta={copy.progetti.otherResCta}
              elementNumber={residenzeSoldOut.length}
              residenzeFiltered={residenzeSoldOut}
              populatePage={(visibleElements) => populateResSoldOutCards(visibleElements)} />
          </Grid>
        </Grid>}
    </>
  );

  return (
    <div style={{ height: "100%" }}>
      <Header home={false} />
      <div className={classes.heroContainer}>
        <RootRef rootRef={mapRef}>
          <Grid container alignContent='center' alignItems='center' justify='center' style={{ height: '100%' }}>
            <Grid container direction='row' style={{ height: '100%' }}>
              <Grid container item md={4} direction='column' className={classes.mobileHidden}>
                {homeCards}
              </Grid>
              <Grid container item xs={12} md={8}>
                <Grid container item className={classes.map}>
                  <Map key={mapKey} places={residenzeFiltered} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </RootRef>
      </div>
      <div className={classes.projectContainer}>
        <StickyContainer>
          <Sticky>
            {({ style, isSticky }) => (
              <div style={{ ...style, top: '65px', zIndex: '1' }}>
                <Grid container item justify='center'>
                  <Select
                    id="subject"
                    key="subject"
                    name="subject"
                    labelId="subjectLabel"
                    value={selectedTab}
                    onChange={(e) => {
                      const zone = enabledZone.find((item) => item.zoneCode === e.target.value);
                      if (zone) {
                        changeZone(zone.zoneUrl);
                      }
                    }}
                    className={classes.selectMap + ' ' + classes.mobileShow}
                    inputProps={{
                      classes: {
                        root: classes.inputContactPropsRoot
                      }
                    }}
                  >
                    {homeCardsSelect}
                  </Select>
                </Grid>
              </div>
            )}
          </Sticky>
          {pageContent}
        </StickyContainer>
        <NewsLetter mapRef={mapRef} />
        <Contact />
      </div>
      <Footer />
    </div>
  )
}

export default withStyles(projectsStyle)(Projects)
