import hero from "../../images/home_scopri1.jpg"

import {
  garamond,
  haylardDisplay,
  haylardDisplayBold,
  whiteButton,
  whiteButtonHover,
  greyButton,
  greyButtonHover,
  italicSubTitle,
  titleLight,
  titleDark,
  error,
  titleBig,
  title,
  cormorantGaramond,
} from "../../style/style.jsx"

import colors from "../../style/colors.scss"

const newsStyle = theme => ({
  hidden: {
    display: "none",
  },
  absolute: {
    position: "absolute",
  },
  titleDark: {
    ...titleDark,
  },
  titleLight: {
    ...titleLight,
  },
  error: {
    ...error,
  },
  whiteButton: {
    ...whiteButton,
    "&:hover": {
      ...whiteButtonHover,
    },
  },
  greyButton: {
    ...greyButton,
    "&:hover": {
      ...greyButtonHover,
    },
  },
  mobileHidden: {
    [theme.breakpoints.down("sm")]: {
      display: "none !important",
    },
  },
  mobileHiddenXs: {
    [theme.breakpoints.down("xs")]: {
      display: "none !important",
    },
  },
  mobileShow: {
    display: "none !important",
    [theme.breakpoints.down("sm")]: {
      display: "flex !important",
    },
  },
  heroContainer: {
    height: "calc(100% - 240px)",
    width: "100%",
    overflow: "hidden",
    marginTop: "64px",
  },
  heroContainerPreview: {
    height: "calc(100% - 170px)",
    width: "100%",
    overflow: "hidden",
  },
  hero: {
    width: "100%",
    height: "100%",
    backgroundImage: `url(${hero})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    transition: "transform .5s ease",
    display: "block",
    "&:hover": {
      transform: "scale(1.05)",
    },
  },
  articleHero: {
    width: "100%",
    height: "100%",
    backgroundSize: "cover",
    backgroundPosition: "center",
    display: "block",
    transition: "transform .5s ease",
    "&:hover": {
      transform: "scale(1.05)",
    },
  },
  newsContainer: {
    marginTop: "45px",
    color: "black",
  },
  subTitle: {
    ...italicSubTitle,
    fontSize: "30px",
    margin: "10px",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      fontSize: "24px",
    },
    "& h1": {
      ...italicSubTitle,
      fontSize: "inherit",
      display: "contents",
      fontWeight: "inherit",
    },
  },
  subTitle2: {
    ...garamond,
    fontSize: "20px",
    fontStyle: "italic",
  },
  sectionTitleUnderline: {
    ...titleBig,
    textDecoration: "underline",
    textUnderlinePosition: "under",
    marginBottom: "20px",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      fontSize: "20px",
    },
    "& h1": {
      ...titleBig,
      fontSize: "inherit",
    },
  },
  rassegnaArticleContainer: {
    paddingLeft: "20px",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "0px",
    },
  },
  description: {
    ...garamond,
    textAlign: "center",
    margin: "20px",
  },
  descriptionFilter: {
    ...garamond,
    margin: "20px",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "start",
      margin: "10px",
    },
  },
  inputLabel: {
    ...garamond,
    marginLeft: "10px",
  },
  descriptionCard: {
    ...garamond,
    textAlign: "center",
    margin: "20px",
    fontSize: "16px",
  },
  descriptionCardR: {
    ...garamond,
  },
  citationAuthor: {
    ...garamond,
    textAlign: "center",
    fontStyle: "italic",
    fontSize: "16px",
    color: colors.darkGrey,
  },
  citation: {
    ...italicSubTitle,
    textAlign: "center",
    color: colors.grey,
    marginTop: "30px",
  },
  ufficioStampaContainer: {
    backgroundColor: colors.lightGrey2,
    marginTop: "60px",
    padding: "80px 0px",
    overflow: "hidden",
    ...haylardDisplay,
    letterSpacing: "0px",
    [theme.breakpoints.down("sm")]: {
      padding: "50px 0px",
      marginTop: "50px",
    },
  },
  ufficioStampaContent: {
    margin: "50px",
    [theme.breakpoints.down("xs")]: {
      margin: "20px",
    },
  },
  ufficioStampaSection: {
    padding: "20px 50px",
    [theme.breakpoints.down("xs")]: {
      alignItems: "center",
    },
  },
  border: {
    borderRight: "1px solid black",
    [theme.breakpoints.down("xs")]: {
      borderRight: "none",
      borderBottom: "1px solid black",
      marginBottom: "20px",
    },
  },
  headerUppercase: {
    textTransform: "uppercase",
    ...haylardDisplay,
  },
  headerNews: {
    background: colors.lightGrey3,
    padding: "20px 0px",
  },
  sticky: {
    borderBottom: "1px solid black",
  },
  headerText: {
    paddingLeft: "80px",
    paddingRight: "80px",
    cursor: "pointer",
    color: colors.grey,
    [theme.breakpoints.down("md")]: {
      paddingLeft: "35px",
      paddingRight: "35px",
    },
    "& h1": {
      display: "contents",
      fontSize: "inherit",
      fontWeight: "inherit",
      fontFamily: "inherit",
      letterSpacing: "inherit",
    },
  },
  headerTextSelect: {
    padding: "20px",
    ...title,
    fontSize: "14px",
  },
  headerTextSelectRassegna: {
    padding: "5px",
    paddingLeft: "10px",
    ...title,
    fontSize: "14px",
  },
  activeTab: {
    color: "black",
    textDecoration: "underline",
  },
  articlesContainer: {
    padding: "50px 250px",
    [theme.breakpoints.down("lg")]: {
      padding: "50px 100px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "50px 0px",
    },
  },
  articlesContainerRassegna: {
    padding: "0px 300px 50px 300px",
    [theme.breakpoints.down("md")]: {
      padding: "0px 100px 50px 100px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0px 20px 50px 20px",
    },
  },
  backgroundImage: {
    height: "350px",
    width: "100%",
    backgroundSize: "cover",
    backgroundPosition: "center",
    marginBottom: "5px",
  },
  articleCard: {
    padding: "15px",
  },
  cardInfo: {
    background: colors.lightGrey3,
    padding: "30px 50px 20px 50px",
    [theme.breakpoints.down("sm")]: {
      padding: "10px",
    },
  },
  title: {
    ...title,
    textAlign: "center",
    fontSize: "16px",
    lineHeight: "1",
  },
  titleR: {
    ...title,
    fontSize: "16px",
    [theme.breakpoints.down("xs")]: {
      width: "70%",
    },
  },
  articleDate: {
    ...garamond,
    color: colors.grey,
    marginBottom: "10px",
  },
  articleDateR: {
    ...garamond,
    color: colors.grey,
    fontSize: "12px",
  },
  tabInfo: {
    ...cormorantGaramond,
    fontStyle: "italic",
    color: colors.grey,
    marginTop: "20px",
  },
  tabInfoOther: {
    ...cormorantGaramond,
    fontStyle: "italic",
    color: colors.grey,
    fontSize: "14px",
    letterSpacing: "normal",
  },
  borderCard: {
    width: "60px",
    borderBottom: "2px solid black",
    marginTop: "10px",
    marginBottom: "20px",
  },
  rassegnaList: {
    color: colors.grey,
    borderBottom: "1px solid " + colors.grey,
    marginBottom: "50px",
    ...garamond,
    fontSize: "16px",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "10px",
    },
  },
  rassegnaArticle: {
    padding: "20px",
    pointerEvents: "none",
    [theme.breakpoints.down("xs")]: {
      pointerEvents: "auto",
    },
  },
  rassegnaListContainer: {
    margin: "50px 0px",
    [theme.breakpoints.down("sm")]: {
      margin: "10px 0px",
    },
  },
  rassegnaArticleBk: {
    background: colors.lightGrey4,
  },
  rassegnaArticleBorder: {
    borderBottom: "1px solid " + colors.lightGrey2,
  },
  newsPageContent: {
    padding: "30px 200px 30px 30px",
    [theme.breakpoints.down("md")]: {
      padding: "30px",
    },
  },
  iconShare: {
    width: "30px",
    height: "30px",
    margin: "10px",
    cursor: "pointer",
    [theme.breakpoints.down("md")]: {
      width: "20px",
      height: "20px",
      margin: "5px",
    },
  },
  articleHeader: {
    paddingRight: "50px",
    minHeight: "96px",
    [theme.breakpoints.down("sm")]: {
      paddingRight: "0px",
    },
  },
  socialIconContainer: {
    paddingRight: "30px",
  },
  socialIconContainerFooter: {
    [theme.breakpoints.down("sm")]: {
      justifyContent: "start",
    },
  },
  iconContainer: {
    [theme.breakpoints.down("xs")]: {
      position: "absolute",
      width: "40px",
      right: "30px",
    },
  },
  titlePage: {
    ...title,
    fontSize: "24px",
    color: "black",
    marginBottom: "10px",
  },
  descriptionPage: {
    ...garamond,
    fontStyle: "italic",
    marginTop: "5px",
  },
  articleContent: {
    marginTop: "50px",
  },
  linkCta: {
    ...haylardDisplayBold,
    fontSize: "12px",
    margin: "20px",
    marginTop: "auto",
    marginBottom: "auto",
    color: colors.red,
    textTransform: "uppercase",
    cursor: "pointer",
    "& a": {
      color: colors.darkGrey,
      textDecoration: "none",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "10px",
    },
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  linkCtaBorder: {
    borderBottom: "1px solid",
    borderColor: colors.darkGrey,
    width: "40px",
  },
  linkCtaBorderLong: {
    borderBottom: "1px solid",
    borderColor: colors.darkGrey,
    width: "150px",
  },
  sourceStyle: {
    ...haylardDisplay,
  },
  sourceStyleName: {
    ...garamond,
  },
  articleFooterContainer: {
    borderTop: "1px solid black",
    marginTop: "60px",
    paddingTop: "20px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "20px",
    },
  },
  articleList: {
    background: colors.lightGrey2,
    padding: "30px",
    marginBottom: "30px",
    [theme.breakpoints.down("sm")]: {
      padding: "10px",
      borderTop: "1px solid " + colors.grey,
      borderBottom: "1px solid " + colors.grey,
    },
  },
  buttonFont: {
    fontSize: "10px",
    margin: "10px",
  },
  titleOtherArticle: {
    ...title,
    fontSize: "16px",
    fontWeight: "normal",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "10px",
    },
  },
  titleOtherArticle2: {
    ...garamond,
    fontStyle: "italic",
    color: colors.grey,
    marginBottom: "20px",
  },
  border100: {
    width: "100px",
    borderBottom: "1px solid " + colors.darkGrey,
    marginTop: "30px",
    marginBottom: "30px",
    marginLeft: "auto",
    marginRight: "auto",
  },
  titleOther: {
    ...haylardDisplayBold,
    fontSize: "14px",
  },
  svgDownload: {
    height: "60px",
    width: "60px",
    margin: "0",
    cursor: "pointer",
    [theme.breakpoints.down("md")]: {
      height: "40px",
      width: "40px",
    },
  },
  svgExternalLinkContainer: {
    [theme.breakpoints.down("sm")]: {
      justifyContent: "start",
    },
  },
  svgExternalLink: {
    height: "60px",
    width: "60px",
    margin: "0",
    transform: "rotate(-90deg)",
    cursor: "pointer",
    [theme.breakpoints.down("md")]: {
      height: "40px",
      width: "40px",
    },
  },
  subTitleNoRes: {
    ...italicSubTitle,
    fontSize: "16px",
    textAlign: "center",
    color: colors.darkGrey,
    marginTop: "50px",
  },
  selectMap: {
    display: "initial",
    border: "2px solid black",
    paddingRight: "24px",
    width: "45%",
    marginTop: "10px",
    marginBottom: "10px",
    background: "white",
    "&:after": {
      borderBottom: "none",
    },
    [theme.breakpoints.down("sm")]: {
      width: "85%",
    },
  },
  selectMapRassegna: {
    display: "initial",
    border: "2px solid black",
    paddingRight: "24px",
    width: "100%",
    margin: "10px",
    background: "white",
    "&:after": {
      borderBottom: "none",
    },
  },
  inputContactPropsRoot: {
    ...garamond,
    fontSize: "20px",
    color: colors.darkGrey + " !important",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
    },
  },
  articleHtml: {
    fontFamily: "HalyardDisplay",
  },
  contactContainer: {
    height: "100%",
    [theme.breakpoints.down("xs")]: {
      height: "auto",
    },
  },
  buttonWidth: {
    minWidth: "210px",
    pointerEvents: "auto !important",
    "&:hover": {
      ...greyButtonHover,
      minWidth: "210px",
    },
  },
  filterContainer: {
    padding: "50px 300px 0px 300px",
    [theme.breakpoints.down("md")]: {
      padding: "50px 100px 0px 100px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "50px 20px 0px 20px",
    },
  },
  hashtagContainer: {
    background: colors.lightGrey2,
    height: "26px",
    color: colors.darkGrey,
    marginTop: "10px",
    marginRight: "10px",
    padding: "0px 10px",
    ...title,
    fontSize: "11px",
  },
  otherNewsBarVisible: {
    position: "sticky",
    bottom: "0",
    padding: "0",
    transition: "bottom 0.5s",
    marginBottom: 0,
  },
  otherNewsBarHidden: {
    position: "sticky",
    bottom: "-100px",
    transition: "bottom 0.5s",
  },
  otherNewsBarButton: {
    margin: "0 2px 5px 2px",
    padding: "0",
    height: "30px",
    width: "48%",
    "&:hover": {
      margin: "0 2px 5px 2px",
      padding: "0",
      height: "30px",
    },
  },
})

export default newsStyle
