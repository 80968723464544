import {
    haylardDisplayBold,
    haylardDisplay,
    greyButton,
    greyButtonHover
  } from "../../style/style.jsx";

const dropZoneStyle = theme => ({
    desktopFullSize: {
        [theme.breakpoints.up('md')] : {
            width: "100%",
            "height": "100%"
        },
    },
    desktopContainer: {
        width: "100%",
        "height": "100%",
        [theme.breakpoints.down('sm')] : {
            display: "none",
        },
    },
    mobileContainer: {
        [theme.breakpoints.up('md')] : {
            display: "none"
        },
    },
    buCompanyProfileContainer: {
        display: "flex",
        "width": "100%",
        "align-items": "center",
        "justify-content":"left",
        [theme.breakpoints.down('sm')] : {
            "margin-top": "20px"
        }
    },
    buttonDownload: {
        cursor:"pointer",
        "display": "flex",
        "flex-direction": "row",
        "text-decoration": "none",
        "color": "black",
        outline: 'none',
        border: 'none'
    },
    buIconContainer: {
        "width": "auto",
        "height": "auto",
        display: 'flex'
    },
    buTextContainer: {
        ...haylardDisplayBold,
        "display": "flex",
        "align-items": "start",
        "justify-content": "center",
        "margin-left": "30px",
        "flex-direction": "column",
        "font-style": "initial",
        [theme.breakpoints.down('sm')] : {
            "margin-left": "20px",
            "font-size": "15px"
        }
    },
    svgDownload: {
        "height": "80px",
        "width": "80px",
        "margin": "0",
        [theme.breakpoints.down('sm')] : {
            "height": "40px",
            "width": "40px",
        }
    },
    halfBorder: {
        "width": "30%",
        "height": "1px",
        margin: 0,
        "background-color":"black",
        "margin-bottom": "20px"
    },
    hidden: {
        display: "none"
    },
    container: {
        width: "100%",
        height: "100%"
    },
    dropContainer: {
        width: "100%",
        height: "100%",
        display: "flex",
        "align-items": "center",
        "justify-content": "center",
        "background-image": "url(\"data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23333' stroke-width='2' stroke-dasharray='10' stroke-dashoffset='25' stroke-linecap='square'/%3e%3c/svg%3e\")",
    },
    dropMessage: {
        "text-align": "center"
    },
    messageTitle: {
        ...haylardDisplay,
        "font-style":"initial",
        "text-transform": "uppercase",
        "color": "black",
        "font-size": "18px"
    },
    messageSubtitle: {
        "font-size": "14px",
        "margin-top": "5px"
    },
    clickToUpload: {
        "text-decoration": "underline",
        "display": "inline-flex",
        "font-weight": "bold",
        "cursor":"pointer",
        border: 'none',
        outline: 'none'
    },
    fileRow: {
        display: "flex",
        "justify-content": "space-between",
        "border": "1px solid black",
        "border-radius": "5px",
        "padding": "10px"
    },
    fileTitle: {
        display: "inline-flex",
        "justify-content": "center",
        "align-items": "center"
    },
    deleteFile: {
        ...greyButton,
        '&:hover': {
            ...greyButtonHover,
            "cursor": "pointer",
            "min-width": "unset",
            "justify-content": "center",
            "align-items": "center"
        },
        ...haylardDisplayBold,
        display: "inline-flex",
        "font-style":"initial",
        "cursor": "pointer",
        "min-width": "unset",
        "justify-content": "center",
        "align-items": "center"
    },
});

export default dropZoneStyle;