import React from 'react'
import { navigate } from "gatsby"

import { Grid, Button } from '@material-ui/core'
import newsStyle from "./newsStyle.jsx"
import withStyles from "@material-ui/core/styles/withStyles"

import articlesCat from '../../constants/ArticlesCategory'

const ArticleCard = ({ classes, article }) => {

  const cat = articlesCat.find((item) => article.acfNews.category === item.code);

  return (
    <Grid container item xs={12} sm={6} md={6} lg={4} direction='column' className={classes.articleCard}>
      <Grid container item className={classes.backgroundImage} style={{ backgroundImage: `url(${article.acfNews.mainimage && article.acfNews.mainimage.sourceUrl})` }} />
      <Grid container item className={classes.cardInfo} direction='column' alignItems='center'>
        <Grid item className={classes.articleDate}>
          {article.acfNews.date}
        </Grid>
        <Grid item className={classes.borderCard}/>
        <Grid container justify='center' direction='column'>
          <Grid item className={classes.title}>
            {article.acfNews.title}
          </Grid>
          <Grid item className={classes.descriptionCard}>
            {article.acfNews.description}
          </Grid>
        </Grid>
        <Grid container justify='center'>
          <Button classes={{ root: classes.greyButton }} onClick={() => navigate(`/news/${article.acfNews.seourl? article.acfNews.seourl.trim() : article.acfNews.title.trim().replace(/[&\/\\#, +()$~%.'":*?<>{}]/g, '_')}`)}>
            Scopri di più
          </Button>
        </Grid>
        <Grid item className={classes.tabInfo}>
          <div dangerouslySetInnerHTML={{ __html: cat && cat.label }} />
        </Grid>
      </Grid>
    </Grid>
  )
};

export default withStyles(newsStyle)(ArticleCard)
