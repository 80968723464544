function currencyFormat(num) {
    return num ? num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') : ""
}

function phoneNumberFormat(phoneNumber){
    return `${phoneNumber.slice(0,3)} ${phoneNumber.slice(3,5)} ${phoneNumber.slice(5,).match(/.{1,2}/g).join('.')}`
}

export default {
    currencyFormat,
    phoneNumberFormat
}

