import React from 'react'

import { Grid } from '@material-ui/core'
import newsStyle from "../newsStyle"
import withStyles from "@material-ui/core/styles/withStyles"

import RassegnaArticle from './RassegnaArticle'

const RassegnaListYear = ({classes, articles, year}) => {
  
  const rassegnaList = articles && articles.map((item, idx) => {
    return (
      <RassegnaArticle article={item} idx={idx} key={idx}/>
    );
  });

  return (
    <Grid container item direction='column' className={classes.rassegnaListContainer}>
      <Grid container item className={classes.rassegnaList}>
        {year}
      </Grid>
      {rassegnaList}
    </Grid>
  )
};

export default withStyles(newsStyle)(RassegnaListYear)
