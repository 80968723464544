import React from 'react'
import { navigate } from "gatsby"

import Grid from '@material-ui/core/Grid'
import projectsStyleZone from "./projectsStyle.jsx"
import withStyles from "@material-ui/core/styles/withStyles"
import CercaZona from '../../images/icons/CercaZona'
import CercaMappa from '../../images/icons/CercaMappa'

const ZoneCardSelect = ({classes, item}) => (

    <Grid container item justify='center' onClick={() => navigate(`/nuove-costruzioni-milano-${item.label}#zone`)} style={{padding: '0px 20px'}}>
      <Grid item xs={3}>
        {item.label === 'Milano' ?
        <CercaMappa black={true} dimension='50px' zoneCode={'milano'}/>
        :
        <CercaZona color='black' dimension='50px' zoneCode={item.code}/>}
      </Grid>
      <Grid container item xs={9} direction='column' alignItems='center' justify='center' className={classes.title}>
        {item.label}
      </Grid>
    </Grid>
);

export default withStyles(projectsStyleZone)(ZoneCardSelect)
