import React from "react"
import { Helmet } from "react-helmet"

import ContactPage from "../components/Contact/ContactPage"
import "../style/style.scss"
import "../style/font.css"
import MessengerIcon from "../components/Home/MessengerIcon"
import { Grid } from "@material-ui/core"

const ContattiUffici = () => (
    <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>Contatti e uffici - Abitare Co.</title>
            <meta name="description" content="Gli uffici Abitare Co si trovano in via Savona 52 a Milano; fissa un appuntamento con un nostro consulente per richieder informazioni o visitare un cantiere." />
        </Helmet>
        <Grid style={{ position: 'fixed', zIndex: '1000', width:'60px', right: '0px', bottom: '15px' }}>
            <Grid container  className={`iconContainer`} alignContent='center' justify='center'>
            <MessengerIcon type='whatsapp' />
            </Grid>
            <Grid container  className={`iconContainer`} alignContent='center' justify='center'>
            <MessengerIcon type='tel' />
            </Grid>
        </Grid>
        <ContactPage />
    </>
)

export default ContattiUffici
