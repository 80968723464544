export const UFFICIO_MILANO = {
  markerId: "ufficio_milano",
  address: "Via Savona, 52 20144",
  coordinates: {
    lat: 45.45409,
    lng: 9.16235,
  },
}
export const UFFICIO_ROMA = {
  markerId: "ufficio_roma",
  address: "Via Sicilia, 50 00187",
  coordinates: {
    lat: 41.908610,
    lng: 12.490920,
  },
}
