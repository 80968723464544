const env = process.env.API_BASE_URL;

var requestInfo = env + "/request-info";
var newsletter = env + "/newsletters";
var advSource = env + "/advertising-sources";
var appartment = env + "/appartment-types";
var leads = env + "/leads"
var searchAppointments = env + "/appointments"
var reserveAppointments = env + "/appointments"
var constructionSite = env + "/construction-sites"
var lavoraConNoiApplication = env + "/applications"

export {
    requestInfo,
    newsletter,
    advSource,
    appartment,
    leads,
    searchAppointments,
    reserveAppointments,
    constructionSite,
    lavoraConNoiApplication
};