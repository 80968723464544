import homeStyle from '../Home/homeStyle';

import {
    garamond
  } from "../../style/style.jsx";

const PrimoPianoCarouselStyle = theme => ({
    ...homeStyle(theme),
    carouselContent: {
        paddingTop: '20px',
        marginLeft: '40px',
        marginRight: '40px',
        height: '30%',
        [theme.breakpoints.down('sm')] : {
            height: '50%',
            margin: '0px',
            padding: '15px',
            alignItems: 'center',
            textAlign: 'center'
        }
    },
    carouselImage: {
        margin: 'auto'
    },
    sliderContentCard: {
        border: '1px solid black',
        height: '750px',
        [theme.breakpoints.down('sm')] : {
            height: '700px'
        }
    },
    sliderContent: {
        paddingLeft: '15px',
        paddingRight: '15px',
        paddingBottom: '30px',
        border: 'none',
        outline: 'none',
        [theme.breakpoints.down('sm')] : {
            paddingLeft: '10px',
            paddingRight: '10px',
        }
    },
    sliderContentPreview: {
        margin: 'auto',
        marginTop: '30px'
    },
    sliderContentCloned:{
        paddingLeft: '15px',
        paddingRight: '15px',
        paddingBottom: '30px',
        border: 'none',
        outline: 'none',
        opacity : '0.6',
        [theme.breakpoints.down('sm')] : {
            paddingLeft: '10px',
            paddingRight: '10px',
        }
    },
    description: {
        ...garamond,
        margin: '5px',
        width: '80%',
        lineHeight: '1.4',
        [theme.breakpoints.down('sm')] : {
            width: '100%'
        }
    },
    primoPianoImg:{ 
        height:'70%',
        width: '100%',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        [theme.breakpoints.down('sm')] : {
            height: '50%'
        }
    },
    primoPianoCard: {
        width: '1000px',
        [theme.breakpoints.down('lg')] : {
            width: '900px'
        },
        [theme.breakpoints.down('md')] : {
            width: '700px'
        },
        [theme.breakpoints.down('sm')] : {
            width: '600px'
        },
        [theme.breakpoints.down('xs')] : {
            width: '325px'
        }
    },
    clickable: {
        cursor: 'pointer'
    }
});

export default PrimoPianoCarouselStyle;