import imageNO1 from "../images/cercazone_nordovest1.jpg"
import imageNO2 from "../images/cercazone_nordovest2.jpg"
import imageNO3 from "../images/cercazone_nordovest3.jpg"
import imageNO4 from "../images/cercazone_nordovest4.jpg"
import imageNO5 from "../images/cercazone_nordovest5.jpg"

import imageSO1 from "../images/cercazone_sudovest1.jpg"
import imageSO2 from "../images/cercazone_sudovest2.jpg"
import imageSO3 from "../images/cercazone_sudovest3.jpg"
import imageSO4 from "../images/cercazone_sudovest4.jpg"
import imageSO5 from "../images/cercazone_sudovest5.jpg"

import imageC1 from "../images/cercazone_centro1.jpg"
import imageC2 from "../images/cercazone_centro2.jpg"
import imageC3 from "../images/cercazone_centro3.jpg"
import imageC4 from "../images/cercazone_centro4.jpg"
import imageC5 from "../images/cercazone_centro5.jpg"

import imageNE1 from "../images/cercazone_nordest1.jpg"
import imageNE2 from "../images/cercazone_nordest2.jpg"
import imageNE3 from "../images/cercazone_nordest3.jpg"
import imageNE4 from "../images/cercazone_nordest4.jpg"
import imageNE5 from "../images/cercazone_nordest5.jpg"

import imageSE1 from "../images/cercazone_sudest1.jpg"
import imageSE2 from "../images/cercazone_sudest2.jpg"
import imageSE3 from "../images/cercazone_sudest3.jpg"
import imageSE4 from "../images/cercazone_sudest4.jpg"
import imageSE5 from "../images/cercazone_sudest5.jpg"

const zones = [
    {
        code: 'NO', 
        image: imageNO1,
        zone: ["7", "8", "9", "nord-ovest"],
        label: 'nord-ovest',
        title: 'LINFA VITALE',
        description: 'Una zona in piena trasformazione dopo Expo 2015 e con i nuovi progetti di rigenerazione dell’area Trotto - San Siro. Polo dinamico e di innovazione, pensata per abitare e vivere immersi nel verde, con numerosi servizi e collegamenti con il centro della città.',
        description2: 'Con oltre 250 mila metri quadrati si conferma come una delle realtà di verde urbano più grande della città. È un contenitore di grandi manufatti urbani, di poli accentratori di servizi, di simboli della storia di Milano dell’ultimo secolo. Un vero e proprio incubatore della Milano del futuro.',
        images: [
            {
                img: imageNO3
            },
            {
                img: imageNO2
            },
            {
                img: imageNO5
            },
            {
                img: imageNO4
            }
        ]
    },
    {
        code: 'SO',
        image: imageSO1,
        zone: ["5","6", "sud-ovest"],
        label: 'sud-ovest',
        title: 'ARTE, CULTURA E MOVIDA',
        description: 'Nuovo distretto culturale ed artistico, ha il suo cuore nella movida che anima la Darsena e il Naviglio. Per chi non rinuncia alla vecchia Milano con un tocco contemporaneo.',
        description2: 'L’unica piazza d’acqua in centro città. Una zona in cui esplorare i mille volti di Milano: passeggiare ammirando i vicoli suggestivi, visitare musei, fare jogging tra giochi di luce e acqua e scegliere dove rilassarsi tra i tanti locali della movida milanese.',
        images: [
            {
                img: imageSO2
            },
            {
                img: imageSO3
            },
            {
                img: imageSO4
            },
            {
                img: imageSO5
            }
        ]
    },
    {
        code: 'C',
        image: imageC1,
        zone: ["1", "centro"],
        label: 'centro',
        title: 'L’ESSENZA DI MILANO',
        description: 'Il Duomo, la Galleria, la Scala, il Quadrilatero della Moda, il Castello, la Pinacoteca di Brera… uno scrigno di inestimabile valore in cui pulsa il cuore più prezioso di Milano.',
        description2: 'Moda e internazionalità, eleganza e classe: immobili di assoluto prestigio per vivere da veri protagonisti lo spirito più autentico del centro storico di Milano.',
        images: [
            {
                img: imageC2
            },
            {
                img: imageC3
            },
            {
                img: imageC4
            },
            {
                img: imageC5
            }
        ]
    },
    {
        code: 'NE',
        image: imageNE1,
        zone: ["2","3", "nord-est"],
        label: 'nord-est',
        title: 'TRA MODERNITÀ E TRADIZIONE',
        description: 'Dove il sapore storico milanese incontra il gusto contemporaneo dei grandi progetti di riconversione urbana, tra cui la trasformazione degli scali ferroviari.',
        description2: 'Grattacieli in acciaio e vetro che caratterizzano il nuovo Skyline cittadino si fondono con i palazzi in stile milanese per dar vita al nuovo binomio dell’essenza meneghina.',
        images: [
            {
                img: imageNE2
            },
            {
                img: imageNE3
            },
            {
                img: imageNE4
            },
            {
                img: imageNE5
            }
        ]
    },
    {
        code: 'SE',
        image: imageSE1,
        zone: ["4", "sud-est"],
        label: 'sud-est',
        title: 'UNA NUOVA VITA',
        description: 'Una zona residenziale in ampliamento, con un processo di riconversione dei grandi isolati industriali nel segno del verde, dell’arte e dell’intrattenimento. Fulcro della vita di Atenei Universitari internazionali: un’area in continua evoluzione e fermento.',
        description2: 'Numerose occasioni di svago, icone architettoniche come la Torre della Fondazione Prada, le Terme di Milano tra suggestioni moderne e storiche, caratterizzano la zona, adatta sia per abitare che per vivere la città in tutte le sue sfaccettature.',
        images: [
            {
                img: imageSE2
            },
            {
                img: imageSE3
            },
            {
                img: imageSE4
            },
            {
                img: imageSE5
            }
        ]
    }
];

export default zones;