import React from 'react'

import { Grid } from '@material-ui/core'

import videoStyle from "./videoStyle.jsx"
import withStyles from "@material-ui/core/styles/withStyles"


const VideoPlayer = ({classes, title, src, article}) => {

  let videoUrl = src;

  if(src.includes('youtube')){
    videoUrl = src.replace('watch?v=', 'embed/').concat('?rel=0');
  }

  return (
    <Grid className={`${classes.iframeContainer} ${article && classes.articleVideo}`}>
      <iframe
        title={title}
        className={classes.videoContainerFrame} 
        src={videoUrl} 
        frameBorder="0" 
        allow="accelerometer; encrypted-media; gyroscope; picture-in-picture" 
        allowFullScreen></iframe>
    </Grid>
  )
}

export default withStyles(videoStyle)(VideoPlayer)
