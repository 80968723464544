import React from "react"
import { Helmet } from "react-helmet"

import LavoraConNoi from "../components/LavoraConNoi/LavoraConNoi"
import "../style/style.scss"
import "../style/font.css"

const LavoraConNoiPage = () => (
    <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>Lavora Con Noi - Abitare Co.</title>
            <meta name="description" content={"Nuove costruzioni a Milano - Abitare Co."} />
        </Helmet>
        <LavoraConNoi />
    </>
)

export default LavoraConNoiPage
