import React from 'react';
import SvgIcon from "@material-ui/core/SvgIcon";

const Youtube = (props) => (
  <SvgIcon {...props}>    
    <svg version="1.1" id="Livello_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
      viewBox="0 0 35.7 25" style={{enableBbackground:'new 0 0 35.7 25'}}>
    <g id="Livello_1_1_">
      <path class="st0" d="M35,3.9c-0.4-1.5-1.6-2.7-3.2-3.2C29,0,17.9,0,17.9,0S6.7,0,3.9,0.7C2.4,1.2,1.2,2.4,0.8,3.9
        C0,6.7,0,12.5,0,12.5s0,5.8,0.8,8.6c0.4,1.5,1.6,2.7,3.2,3.1C6.8,25,18,25,18,25s11.2,0,14-0.8c1.5-0.4,2.7-1.6,3.1-3.1
        c0.7-2.8,0.7-8.6,0.7-8.6S35.7,6.7,35,3.9z M14.3,17.9V7.1l9.3,5.4L14.3,17.9z"/>
    </g>
    </svg>
  </SvgIcon>
);

const YoutubeIcon = ({extraClasses}) => {
  
  return (
  <div>
    <Youtube className={extraClasses} />
  </div>
)};

export default YoutubeIcon;