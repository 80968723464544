import React from "react"
import { Helmet } from "react-helmet"
import NoteLegali from "../components/Static Pages/NoteLegali"
import "../style/style.scss"
import "../style/font.css"

const NoteLegaliPage = () => (
    <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>Abitare Co. - Note legali</title>
            <meta name="description" content={"Nuove costruzioni a Milano - Abitare Co."} />
        </Helmet>
        <NoteLegali />
    </>
)

export default NoteLegaliPage
