import colors from "../../style/colors.scss";


import {
    greyButton,
    haylardDisplay,
    greyButtonHover
} from "../../style/style.jsx";

const headerStyle = theme => ({

    greyButton : {
        ...greyButton,
        height: '35px',
        '&:hover': {
            ...greyButtonHover,
            height: '35px'
        }
    },
    appBarStyle : {
        background: "transparent",
        boxShadow: "none",
        borderBottom: "1px solid white",
        fontSize: "18px",
        transition: "all .5s ease-in-out",
    },
    appBarrStyleScroll : {
        boxShadow: "none",
        borderBottom: "1px solid black",
        fontSize: "18px",
        background: "white",
        color: colors.grey,
        transition: "all .5s ease-in-out"
    },
    headerUppercase : {
        textTransform : "uppercase",
        ...haylardDisplay,
        letterSpacing: '2px !important',
    },
    menu: {
        textAlign: 'center',
        fontSize: '14px'
    },
    linkStyle : {
        color: 'inherit',
        textDecoration: 'none',
        textAlign: 'center',
        lineHeight: "inherit !important",
        fontSize: 'inherit',
        [theme.breakpoints.down('md')] : {
            fontSize: '14px',
            letterSpacing: '1px'
        }
    },
    linkStyleBorder: {
        '&:hover': {
            borderBottom: '1px solid black',
            color: 'black'
        },
    },
    linkStyleBorderWhite: {
        '&:hover': {
            borderBottom: '1px solid white',
            color: 'white'
        },
    },
    linkSelected : {
        borderBottom: '1px solid black',
        color: 'black'
    },
    logo : {
        height: "30px",
        marginTop: "auto",
        marginBottom: "auto"
    },
    mobileHidden : {
        [theme.breakpoints.down('sm')] : {
            display: 'none !important'
        }
    },
    mobileShow:{
        display: 'none !important',
        [theme.breakpoints.down('sm')] : {
            display: 'flex !important' 
        }
    },
    blackTitle: {
        color:'black'
    },
    menuStyle: {
        borderRadius: '0px',
        textTransform : "uppercase"
    },
    noSpacing: {
        letterSpacing: '0px'
    }
});

export default headerStyle;