import React from "react"
import Arrow from '../../images/icons/Arrow'

const CarouselArrow = ({className, arrowStyle, onClick, color, direction}) => {

    return (
      <button type="button"
        style={{ 
          zIndex: '1000',
          position: 'absolute',
          display: 'block',
          padding: '0',
          cursor: 'pointer',
          color: 'transparent',
          border: 'none',
          outline: 'none',
          background: 'transparent',
          ...arrowStyle
      }}
        onClick={onClick}
      >      
        <Arrow direction={direction} color={color} dimension='35px'/>
      </button>
    
    );
};

export default CarouselArrow;  