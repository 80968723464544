import React from 'react'

import Grid from '@material-ui/core/Grid'
import mapStyle from './mapStyle'
import withStyles from "@material-ui/core/styles/withStyles"

import CercaMappa from '../../../images/icons/CercaMappa'

const MapCardSelect = ({classes, item, selectedTab}) => (

    <Grid container item justify='center' style={{padding: '0px 20px'}}>
      <Grid item xs={3}>
        <CercaMappa black={true} dimension='50px' zoneCode={item.zoneCode}/>
      </Grid>
      <Grid container item xs={9} direction='column' alignItems='center' justify='center' className={classes.selectCta}>
        {item.cta}
      </Grid>
    </Grid>
);

export default withStyles(mapStyle)(MapCardSelect)
