import React, {useState, useRef} from 'react'

import useSupercluster from "use-supercluster"
import GoogleMapReact from 'google-map-react'

import MapMarker from './MapMarker'
import MapMarkerCluster from './MapMarkerCluster'

import mapStyle from './mapStyle'
import withStyles from "@material-ui/core/styles/withStyles"
import gMapStyle from '../../../style/gMapStyle'

const Map = ({classes, places}) => {

  const isClient = typeof window !== 'undefined';

  const mapRef = useRef();

  const [selectedPlace, setSelectedPlace] = useState('');
  const [bounds, setBounds] = useState(null);
  const [zoom, setZoom] = useState(12);

  const calculateLocation = (placeList) => {
    const latitude = [];
    const longitude = [];
    for(const place in placeList){
      latitude.push(placeList[place].acfResidenza.address.latitude);
      longitude.push(placeList[place].acfResidenza.address.longitude);
    }

    const lat = latitude.reduce((a,b) => a + b, 0) / latitude.length;
    const lng = longitude.reduce((a,b) => a + b, 0) / longitude.length;		

    if(selectedPlace){
      const placeToFocus = placeList.find((item) => item.id === selectedPlace);
      const placeToFocusLat = placeToFocus.acfResidenza.address.latitude;
      const placeToFocusLng = placeToFocus.acfResidenza.address.longitude;

      mapRef.current.panTo({ lat: placeToFocusLat, lng: placeToFocusLng });
    }
    return {lat, lng};
  }

  const location = calculateLocation(places);

  const points = places.map((place, idx) => ({
    type: "Feature",
    properties: { cluster: false, placeId: place.id, category: 'boh' },
    geometry: {
      type: "Point",
      coordinates: [
        parseFloat(place.acfResidenza.address.longitude),
        parseFloat(place.acfResidenza.address.latitude)
      ]
    },
    item: place
  }));

  const { clusters, supercluster } = useSupercluster({
    points,
    bounds,
    zoom,
    options: { radius: 75, maxZoom: 20 }
  });

  const onMapLoaded = ({map, maps}) => {
    mapRef.current = map;
    const bounds = new window.google.maps.LatLngBounds();
  
    places
      .filter(p => p.acfResidenza.address.latitude !== undefined && p.acfResidenza.address.longitude !== undefined)
      .forEach(p => {
        bounds.extend(new window.google.maps.LatLng(p.acfResidenza.address.latitude, p.acfResidenza.address.longitude));
      });

    // Don't zoom in too far on only one marker
    if (bounds.getNorthEast().equals(bounds.getSouthWest())) {
      var extendPoint1 = new window.google.maps.LatLng(bounds.getNorthEast().lat() + 0.01, bounds.getNorthEast().lng() + 0.01);
      var extendPoint2 = new window.google.maps.LatLng(bounds.getNorthEast().lat() - 0.01, bounds.getNorthEast().lng() - 0.01);
      bounds.extend(extendPoint1);
      bounds.extend(extendPoint2);
    }

    map.fitBounds(bounds);
    map.setOptions({
      clickableIcons: false,
      clickableLabels: false
    });
  };

  return (
  <div style={{width: '100%', height: '100%'}}>
    {isClient && (
    <GoogleMapReact
      yesIWantToUseGoogleMapApiInternals
      onGoogleApiLoaded={onMapLoaded}
      bootstrapURLKeys={{ key: 'AIzaSyAk3K2wgZc3M7n3RWSqWnIv0u5fM1n7HWc' }}
      defaultCenter={location}
      defaultZoom={12}
      options={{  
        styles: gMapStyle
      }}
      onChange={({ zoom, bounds }) => {
        setZoom(zoom);
        setBounds([
          bounds.nw.lng,
          bounds.se.lat,
          bounds.se.lng,
          bounds.nw.lat
        ]);
      }}
    >
      {clusters.map(cluster => {
        const [longitude, latitude] = cluster.geometry.coordinates;
        const {
          cluster: isCluster,
          point_count: pointCount
        } = cluster.properties;

        if (isCluster) {
          return (
            <MapMarkerCluster
              key={`cluster-${cluster.id}`}
              lat={latitude}
              lng={longitude }
              pointCount={pointCount}
              length={points.length}
              mapRef={mapRef}
              cluster={cluster}
              supercluster={supercluster}
            />
          );
        }

        return (
          <MapMarker 
            item={cluster.item} 
            key={`place-${cluster.properties.placeId}`}
            lat={latitude}
            lng={longitude}
            isSelected={selectedPlace === cluster.item.id}
            setSelectedPlace={(value) => setSelectedPlace(value)}/>
        );
      })}
    </GoogleMapReact>)
  }
  </div>
  )
};

export default withStyles(mapStyle)(Map)