import React from "react"
import Grid from '@material-ui/core/Grid'
import NumberIconListStyle from './NumberIconListStyle.jsx'
import withStyles from "@material-ui/core/styles/withStyles"

const NumberIconListItem = ({classes, showLine, data, index, otherClasses}) => {

    let verticalLine = "";
    let verticalLineMobile = "";
    if(showLine) {
        verticalLine = (
            <div className={classes.verticalLineDesktop}>

            </div>
        );

        verticalLineMobile = (
            <div className={classes.verticalLineMobile}>

            </div>
        );
    }

  return (
    <div className={classes.singleElementContainer}>
        <Grid item xs={12} md={2} className={classes.numberBlockContainer}>
            <div className={classes.iconContainer}>
                
                <div className={classes.titleContainerMobile}>
                    {data.title}
                </div>

                <img alt={""} className={classes.imgIcon} src={require('./../../images/' + data.icon)}></img>
            </div>

            <div className={classes.numberContainer}>
                {(index).toLocaleString('it-IT', {minimumIntegerDigits: 2})}
            </div>

            {verticalLine}
        </Grid>

        <Grid item xs={12} md={8} className={classes.textContainer}>
            <div className={classes.titleContainer}>
                {data.title}
            </div>

            <div className={classes.subtitleContainer}>
                {data.subtitle}
            </div>

            <div className={classes.descriptionContainer}>
                {data.description}
            </div>
        </Grid>

        {verticalLineMobile}
    </div>
  )
}

export default withStyles(NumberIconListStyle)(NumberIconListItem)