import colors from "../../style/colors.scss";

const loaderStyle = theme => ({
    loader: {
        margin:'auto'
    },
    dark: {
        color: colors.darkGrey,
    },
    light: {
        color: colors.lightGrey3,
    }
});

export default loaderStyle;