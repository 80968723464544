import React from "react"
import { Grid, Select, MenuItem, RootRef } from '@material-ui/core'
import { navigate } from "gatsby"

import newsStyle from "./newsStyle.jsx"
import withStyles from "@material-ui/core/styles/withStyles"
import { StickyContainer, Sticky } from 'react-sticky'
import Pagination from '../Pagination/Pagination'

import articlesCat from '../../constants/ArticlesCategory'

const HeaderNews = ({ classes, activeTab, children, populatePage }) => {

  const headRef = React.useRef();
  return (
    <StickyContainer>
      <RootRef rootRef={headRef}>
        <Sticky topOffset={-65}>
          {({ style, isSticky }) => (
            <div style={{ ...style, top: '65px', zIndex: '1' }} id='section'>
              <Grid container item justify='center' className={`${classes.headerNews} ${isSticky && classes.sticky} ${classes.mobileHidden}`}>
                <Grid container item direction='row' justify='center' className={classes.headerUppercase}>
                  {articlesCat.map((item, idx) => (
                    <Grid key={idx} item onClick={() => navigate(`${item.path}#section`)} className={`${classes.headerText} ${idx < articlesCat.length -1 && classes.border} ${activeTab === item.code && classes.activeTab}`}>
                      <div dangerouslySetInnerHTML={{__html: item.labelH1}}/>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
              <Grid container item justify='center' className={`${classes.mobileShow}`}>
                <Select
                  id="subject"
                  key="subject"
                  name="subject"
                  labelId="subjectLabel"
                  value={activeTab}
                  className={classes.selectMap}
                  inputProps={{
                    classes: {
                      root: classes.inputContactPropsRoot
                    }
                  }}
                >
                  {articlesCat.map((item, idx) => (
                    <MenuItem key={idx} className={classes.inputContactPropsRoot} value={item.code} onClick={() => navigate(`${item.path}#section`)}>
                      <Grid item className={`${classes.headerTextSelect}`}>
                        <div dangerouslySetInnerHTML={{ __html: item.label }} />
                      </Grid>
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
            </div>
          )}
        </Sticky>
      </RootRef>
      <Grid container className={classes.articlesContainer}>
        {children && children.length > 0 ?
          <Pagination
            masonry={true}
            pagination={3}
            cta='Carica altri articoli'
            elementNumber={children.length}
            elements={children}
            populatePage={(visibleElements) => populatePage(visibleElements)} />
          :
          <Grid container alignContent='center' alignItems='center' justify='center' className={classes.subTitleNoRes}>
            Al momento non sono disponibili articoli appartenenti a questa sezione
        </Grid>
        }
      </Grid>
    </StickyContainer>
  );
}

export default withStyles(newsStyle)(HeaderNews)
