import React from "react"

const CardMosaico = ({classes, even, data, divClass, imgClass, otherClasses}) => {

    let c = [imgClass];
    if(even) {
        c.push("evenContainer");
    }

  return (
      <div className={c.join(" ")}>
        <img src={require('./../../images/' + data.full_image_url)} alt={""}></img>

        <div className={divClass}>
            <div className={"mosaicoTitle"}>
                <div className={"underlineTitle"}>
                    {data.title}
                </div>
            </div>

            <div className={"mosaicoDescription"}>
                {data.description}
            </div>
            
            <div className={"mosaicoSubtitle"}>
                {data.subtitle}
            </div>
        </div>
      </div>
  )
}

export default CardMosaico