import scopriAbitareCoHero from "../../images/scopriabitareco_hero.jpg"
import colors from "../../style/colors.scss"
import {
  cormorantGaramond,
  garamond,
  haylardDisplay,
  haylardDisplayBold,
} from "../../style/style.jsx"

const ScopriAbitareCoStyle = theme => ({
  hidden: {
    display: "none",
  },
  absolute: {
    position: "absolute",
  },
  fullSize: {
    width: "100%",
    height: "100%",
  },
  noMargin: {
    margin: "0!important",
  },
  underlineContent: {},
  title: {
    ...cormorantGaramond,
    "margin-top": "50px",
    "font-size": "30px",
    "line-height": "36px",
    "font-style": "italic",
    color: "black",
    [theme.breakpoints.down("sm")]: {
      fontSize: "24px",
    },
  },
  subtitle: {
    ...haylardDisplayBold,
    "text-transform": "uppercase",
    "font-size": "30px",
    "line-height": "36px",
    "text-decoration": "underline",
    "text-underline-position": "under",
    "max-width": "40%",
    [theme.breakpoints.down("md")]: {
      "max-width": "90%",
      "line-height": "38px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "20px",
    },
    "& h1": {
      ...haylardDisplayBold,
      "font-size": "inherit",
      fontSize: "inherit",
      lineHeight: "inherit",
      display: "contents",
    },
  },
  description1: {
    ...garamond,
    "margin-top": "55px",
    "max-width": "33.333%",
    [theme.breakpoints.down("md")]: {
      "max-width": "90%",
    },
    "& h2": {
      ...garamond,
      display: "contents",
      fontWeight: "inherit",
      fontSize: "inherit",
    },
  },
  description2: {
    ...garamond,
    "margin-top": "80px",
    "max-width": "33.333%",
    [theme.breakpoints.down("md")]: {
      "max-width": "90%",
    },
  },
  quote: {
    ...cormorantGaramond,
    "font-style": "italic",
    "margin-top": "80px",
    color: colors.grey,
    "font-size": "22px",
    "max-width": "33.333%",
    [theme.breakpoints.down("md")]: {
      "max-width": "90%",
    },
  },
  heroTextContainer: {
    width: "100%",
    display: "flex",
    "justify-content": "center",
    "flex-direction": "column",
    "align-items": "center",
    color: "black",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  firstTextBlock: {
    display: "flex",
    "justify-content": "center",
    "align-items": "center",
    "text-align": "center",
  },

  numberBlockContainer: {
    "margin-top": "80px",
    "padding-top": "80px",
    "background-color": colors.lightGrey2,
    "min-height": "400px",
    position: "relative",
    [theme.breakpoints.down("md")]: {
      "min-height": "unset",
    },
  },

  letterBackground: {
    display: "flex",
    alignItems: "flex-end",
    position: "absolute",
    width: "275px",
    "z-index": "0",

    "& > img": {
      margin: "0",
    },

    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },

  numberBlock: {
    "text-align": "center",
    display: "flex",
    "justify-content": "center",
    "align-items": "center",
    "flex-direction": "column",
    "padding-bottom": "80px",
    position: "relative",
    "z-index": 1,
    [theme.breakpoints.down("sm")]: {
      "padding-bottom": "5%",
    },
  },
  numeriTitle: {
    ...haylardDisplayBold,
    "margin-top": "5px",
    "text-transform": "uppercase",
    "font-size": "25px",
    "text-decoration": "underline",
    "text-underline-position": "under",
  },
  numeriSubtitle: {
    ...cormorantGaramond,
    "font-style": "italic",
    "margin-top": "20px",
    color: colors.grey,
    "margin-bottom": "40px",
    "letter-spacing": "normal",
    "font-size": "22px",
    "max-width": "60%",
    [theme.breakpoints.down("md")]: {
      "max-width": "100%",
    },
  },
  visibleDesktop: {
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  visibleMobile: {
    width: "100%",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  mobileShow: {
    display: "none !important",
    [theme.breakpoints.down("sm")]: {
      display: "flex !important",
    },
  },
  numberCarouselContainer: {
    width: "65%",
    display: "flex",
    "flex-wrap": "wrap",
    "box-sizing": "border-box",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      "& .slick-list": {
        padding: "0 60px",
      },
      "& .slick-slide": {
        padding: "0 4px",
        opacity: "0.6",
        "&.slick-current": {
          opacity: "1",
        },
      },
      "& ul.slick-dots": {
        bottom: "-25px",
        "& li": {
          "&>div": {
            border: "1px solid #353535",
          },
          "&.slick-active>div": {
            "border-color": "#353535",
            "background-color": "#353535",
          },
        },
      },
    },
  },
  numberInfoContainer: {
    display: "flex",
    "justify-content": "center",
    "align-items": "center",
    "flex-direction": "column",
    "margin-top": "80px",
    [theme.breakpoints.down("md")]: {
      width: "90%",
      "border-top": "1px solid black",
      "padding-top": "20px",
    },
    [theme.breakpoints.down("sm")]: {
      "margin-top": "0",
      "padding-top": "0",
      "border-top": "none",
    },
  },
  blocksContainer: {
    display: "flex",
    "justify-content": "center",
    width: "auto",
  },
  singleNumberBlock: {
    display: "inline-flex",
    "flex-direction": "column",
    "border-right": "1px solid black",
    "&:last-child": {
      "border-right": "none",
    },
    "padding-left": "20px",
    "padding-right": "20px",
    "padding-top": "20px",
    "padding-bottom": "20px",
    width: "auto",
    [theme.breakpoints.down("md")]: {
      margin: "10px",
      padding: "10px",
      border: "none",
      "background-color": "white",
    },
  },
  bigNumber: {
    ...garamond,
    display: "inline-flex",
    "font-size": "100px",
    "line-height": "100px",
    color: "black",
    [theme.breakpoints.down("md")]: {
      "font-size": "60px",
      "line-height": "60px",
    },
  },
  smallNumber: {
    ...garamond,
    display: "inline-flex",
    "font-size": "50px",
    "line-height": "50px",
    color: "black",
    [theme.breakpoints.down("md")]: {
      "font-size": "30px",
      "line-height": "30px",
    },
  },
  numberContainer: {},
  singleNumberBlockTitle: {
    ...haylardDisplay,
    "font-size": "16px",
    "letter-spacing": "normal",
    "text-transform": "uppercase",
    color: "black",
    "font-weight": "300",
    [theme.breakpoints.down("sm")]: {
      lineHeight: "1.2",
      "font-size": "11px",
    },
  },
  singleNumberBlockNumber: {},

  valoriContainer: {
    display: "flex",
    "justify-content": "center",
    "align-items": "center",
    "flex-direction": "column",
  },
  valoriTextContainer: {
    "text-align": "center",
    display: "flex",
    "justify-content": "center",
    "align-items": "center",
    "flex-direction": "column",
  },
  valoriCarouselContainer: {
    width: "65%",
    "margin-bottom": "100px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      "margin-bottom": "50px",
      "& .slick-list": {
        padding: "0 60px",
      },
      "& .slick-track": {},
      "& .slick-slide": {
        padding: "0 4px",
        opacity: "0.6",
        "&.slick-current": {
          opacity: "1",
        },
      },
      "& ul.slick-dots li": {
        "&>div": {
          border: "1px solid #353535",
        },
        "&.slick-active>div": {
          "border-color": "#353535",
          "background-color": "#353535",
        },
      },
    },
  },
  sectionTitle: {
    ...haylardDisplayBold,
    "margin-top": "100px",
    "text-transform": "uppercase",
    "font-size": "25px",
    "text-decoration": "underline",
    "text-underline-position": "under",
    [theme.breakpoints.down("md")]: {
      "margin-top": "50px",
    },
  },
  sectionSubtitle: {
    ...cormorantGaramond,
    "font-style": "italic",
    color: colors.grey,
    "font-size": "22px",
    "max-width": "60%",
    "margin-top": "20px",
    "margin-bottom": "40px",
    "letter-spacing": "normal",
    [theme.breakpoints.down("md")]: {
      "max-width": "90%",
    },
  },
  sectionDescription: {
    ...cormorantGaramond,
    "font-style": "italic",
    width: "60%",
    "font-size": "22px",
    "letter-spacing": "normal",
    "margin-top": "35px",
    "text-align": "center",
    [theme.breakpoints.down("md")]: {
      width: "90%",
    },
  },
  mosaicoBlock: {
    "background-color": colors.lightGrey3,
    display: "flex",
    "flex-direction": "column",
    "justify-content": "center",
    "align-items": "center",
  },
  mosaicoDescriptionContainer: {
    display: "flex",
    "flex-direction": "column",
    "justify-content": "center",
    "align-items": "center",
  },
  mosaicoContainer: {
    "margin-top": "50px",
    "margin-bottom": "80px",
    "& img": {
      width: "100%",
      height: "100%",
      margin: "0",
    },
    [theme.breakpoints.down("sm")]: {
      "margin-bottom": "0",
    },
  },
  mosaicoMarginBottom: {
    "margin-bottom": "1.45em",
    [theme.breakpoints.down("sm")]: {
      "margin-bottom": "0",
    },
  },
  mosaicoImgContainer: {
    // "display": "inline-flex",
    position: "relative",
  },
  mosaicoPaddingRight: {
    "padding-right": "1.45rem",
    [theme.breakpoints.down("sm")]: {
      "padding-right": "0",
    },
  },
  mosaicoPaddingLeft: {
    "padding-left": "1.45rem",
    [theme.breakpoints.down("sm")]: {
      "padding-left": "0",
    },
  },
  mosaicoMaxHeigh: {
    height: "500px",
    [theme.breakpoints.down("sm")]: {
      height: "auto",
    },
  },
  mosaicoMaxHeighSingleImage: {
    height: "calc(500px + 1.45rem)",
    [theme.breakpoints.down("sm")]: {
      height: "auto",
    },
  },
  mosaicoHalfMaxHeigh: {
    height: "250px",
    [theme.breakpoints.down("sm")]: {
      height: "auto",
      display: "flex",
    },
  },
  heroImg: {
    "margin-top": "80px",
    [theme.breakpoints.down("sm")]: {
      "margin-top": "0",
    },
  },

  businessUnitContainer: {
    "align-items": "center",
    "background-color": colors.lightGrey3,
    position: "relative",
  },

  titleSubDescContainer: {
    display: "flex",
    "flex-direction": "column",
    "justify-content": "center",
    "align-items": "center",
  },
  mosaicoImg: {
    height: "100%",
    cursor: "pointer",
    "&>img": {
      "object-position": "50% 50%",
      "object-fit": "cover",
    },
    "&.evenContainer": {
      [theme.breakpoints.down("sm")]: {
        "&>img": {
          order: "1",
        },
        "&>div": {
          "background-color": "white",
        },
      },
    },
    "&:hover div": {
      [theme.breakpoints.up("sm")]: {
        opacity: "1",
      },
    },
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      "&>img": {
        width: "30%",
        height: "auto",
        display: "inline-flex",
      },
    },
  },
  mosaicoTextContainer: {
    [theme.breakpoints.up("sm")]: {
      position: "absolute",
      top: "0",
      left: "0",
      opacity: "0",
      transition: "all .2s ease-in-out",
      width: "100%",
      height: "100%",
      "background-color": "rgba(0, 0, 0, 0.8)",
      display: "flex",
      "flex-direction": "column",
      "text-align": "center",
      "align-items": "center",
    },
    [theme.breakpoints.down("sm")]: {
      display: "inline-flex",
      opacity: "1",
      "flex-direction": "column",
      "text-align": "center",
      "align-items": "center",
      position: "static",
      width: "100%",
      height: "100%",
      "background-color": colors.lightGrey3,
      "justify-content": "center",
      "padding-top": "20px",
      "padding-bottom": "20px",
    },
    "& .underlineTitle": {
      "padding-bottom": "1px",
      //"border-bottom": "white 1px solid",
      [theme.breakpoints.down("sm")]: {
        //"border-bottom": "black 2px solid"
      },
    },
    "& .mosaicoTitle": {
      ...haylardDisplayBold,
      color: "white",
      "text-transform": "uppercase",
      display: "flex",
      "justify-content": "center",
      "flex-grow": "1",
      "align-items": "flex-end",
      "max-width": "90%",
      [theme.breakpoints.down("sm")]: {
        color: "black",
        order: "1",
        "flex-grow": "0",
        "max-width": "80%",
      },
    },
    "& .mosaicoSubtitle": {
      ...garamond,
      "font-style": "italic",
      color: "white",
      "flex-grow": "1",
      display: "flex",
      "justify-content": "center",
      "align-items": "flex-end",
      "margin-bottom": "20px",
      "font-size": "14px",
      "line-height": "16px",
      [theme.breakpoints.down("sm")]: {
        color: "black",
        order: "0",
        "margin-bottom": "0",
        "flex-grow": "0",
        "max-width": "80%",
      },
    },
    "& .mosaicoDescription": {
      ...garamond,
      "flex-grow": "1",
      color: "white",
      "max-width": "60%",
      "padding-top": "20px",
      "font-size": "16px",
      "line-height": "16px",
      [theme.breakpoints.down("sm")]: {
        color: "black",
        order: "3",
        "flex-grow": "0",
        "max-width": "80%",
      },
    },
  },
  heroContainer: {
    height: "calc(100% - 240px)",
    width: "100%",
    overflow: "hidden",
    marginTop: "64px",
  },
  hero: {
    width: "100%",
    height: "100%",
    backgroundImage: `url(${scopriAbitareCoHero})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    transition: "transform .5s ease",
    display: "block",
    "&:hover": {
      transform: "scale(1.05)",
    },
  },
  backgroundImage: {
    height: "700px",
    width: "100%",
    backgroundSize: "cover",
    backgroundPosition: "center",
    [theme.breakpoints.down("sm")]: {
      height: "50%",
    },
  },
  buCompanyProfileContainer: {
    "margin-bottom": "50px",
    [theme.breakpoints.down("sm")]: {
      "margin-top": "50px",
    },
  },
  buttonDownload: {
    cursor: "pointer",
  },
  buIconContainer: {
    width: "auto",
    height: "auto",
    display: "flex",
  },
  buTextContainer: {
    ...haylardDisplayBold,
    display: "flex",
    "align-items": "start",
    "justify-content": "center",
    "margin-left": "30px",
    "flex-direction": "column",
    textTransform: "uppercase",
    "font-size": "16px",
    [theme.breakpoints.down("sm")]: {
      "margin-left": "20px",
      "font-size": "15px",
    },
  },
  svgDownload: {
    height: "50px",
    width: "50px",
    margin: "0",
    [theme.breakpoints.down("sm")]: {
      height: "40px",
      width: "40px",
    },
  },
  halfBorder: {
    width: "60%",
    height: "1px",
    margin: 0,
    "background-color": "black",
    "margin-bottom": "20px",
  },
  divider: {
    backgroundColor: "#bbbbbb",
    width: "100%",
    height: "2px",
  },
})

export default ScopriAbitareCoStyle
