import { Button, Checkbox, FormControl, TextField,Link} from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import withStyles from "@material-ui/core/styles/withStyles"
import { navigate } from "gatsby"
import React, { useState } from "react"
import copy from '../../constants/copy.json'
import { newsletter } from "../../constants/endpoints.jsx"
import CheckboxIcon from '../../images/icons/Checkbox'
import FacebookIcon from "../../images/icons/FacebookIcon"
import InstagramIcon from "../../images/icons/InstagramIcon"
import LinkedinIcon from "../../images/icons/LinkedinIcon"
import YoutubeIcon from "../../images/icons/YoutubeIcon"
import whiteLogo from "../../images/svg/logo_abitareco_bianco.svg"
import Loader from '../Loader/Loader'
import footerStyle from "./footerStyle.jsx"
import './instagram-widget'
import { InformativaClientiPdf, PrivacyPdf } from '../../constants/links'

const Footer = ({ classes, contactRef }) => {

  const goToUrl = (url) => {
    navigate(url);
  };

  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const [email, setEmail] = useState('');
  const [flag1, setFlag1] = useState(false);
  const [flag2, setFlag2] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    setError(false);
    setSuccess(false);
    setLoading(true);

    const body = {
      "email": email,
      "dataCollectionFlag": true,
      "marketingFlag": flag1,
      "profilazioneFlag": flag2
    };

    fetch(newsletter, {
      method: "post",
      headers: {
        'Accept': '*/*',
        "Content-Type": "application/json"
      },
      body: JSON.stringify(body)
    })
      .then(results => {
        return results.json();
      })
      .then(response => {
        var responseCode = response.statusCode;
        if (responseCode !== 200 && responseCode !== 401) {
          console.error(
            "Error calling " + newsletter + ": " + response.error.type + "\n\n" + response.error.description
          );
          setError(true);
          setLoading(false);
        } else {
          setEmail('');
          setFlag1(false);
          setFlag2(false);
          setSuccess(true);
          setLoading(false);
        }
      });
  };

  return (
    <Grid container justify='center' alignItems='center' className={classes.footerContainer}>
      <Grid container item justify='center' className={classes.logoContainer}>
        <img className={classes.logo} src={whiteLogo} alt="" />
      </Grid>
      <Grid container style={{ marginTop: '30px' }}>
        <Grid container item xs={12} md={8} direction='row-reverse'>
          <Grid container item xs={12} md={6} direction="column" justify="flex-start" alignItems="center" className={classes.mobileMargin}>
            <Grid className={classes.subTitleFooter}>
              {copy.home.hero.subTitle}
            </Grid>
            <Grid style={{ width: '80px', borderBottom: '2px solid white', marginTop: '10px', marginBottom: '10px' }} />
            <Grid className={classes.title}>
              {copy.home.hero.title}
            </Grid>
            <Grid item className={classes.subTitle} style={{ marginTop: '60px', width: '55%', textAlign: 'center' }}>
              {copy.home.footer.subtitle}
            </Grid>
            {loading && <Loader color='light' />}
            {error && !loading &&
              <Grid item className={classes.subTitle + ' ' + classes.error}>
                {copy.home.form.error}
              </Grid>}
            {success && !loading &&
              <Grid container className={classes.subTitle} direction='row' justify='center' style={{ marginTop: '30px' }}>
                <Grid item>
                  <CheckboxIcon dimension='30px' color='white' />
                </Grid>
                <Grid item style={{ alignSelf: 'flex-end', fontSize: '26px', fontWeight: 'bold' }}>
                  {copy.home.form.success}
                </Grid>
              </Grid>}
            {!loading &&
              <form onSubmit={handleSubmit}>
                <Grid item className={classes.formContainer}>
                  <FormControl classes={{ root: classes.formControlRoot }}>
                    <TextField
                      required
                      type='email'
                      onChange={(e) => setEmail(e.target.value)}
                      value={email}
                      variant="outlined"
                      placeholder='Inserisci il tuo indirizzo e-mail'
                      classes={{ root: classes.textFieldRoot }}
                      InputProps={{
                        classes: {
                          root: classes.inputPropsRoot,
                          notchedOutline: classes.textFieldFocused
                        }
                      }} />
                    <Grid container >
                      <Grid item xs={12} className={classes.checkBoxLabel}>
                        {copy.home.newsLetter.privacy} <a className={classes.checkBoxLabel + ' ' + classes.checkBoxLabelLink} href={PrivacyPdf} target="_blank" rel="noreferrer">{copy.home.newsLetter.privacyLink}</a>
                      </Grid>
                      <Grid item xs={2} sm={1}>
                        <Checkbox
                          required={true}
                          name="flag1"
                          onChange={(e) => setFlag1(e.target.checked)}
                          value={flag1}
                          checkedIcon={<div className={classes.checkedIconLight}>
                            <CheckboxIcon dimension='13px' top='-7px' color='#353535' />
                          </div>}
                          icon={<div className={classes.iconLight} />}
                        />
                      </Grid>
                      <Grid item xs={10} sm={11} className={classes.checkBoxLabel}>
                        {copy.home.newsLetter.check1}
                        &nbsp;
                        <a className={classes.checkBoxLabel + ' ' + classes.checkBoxLabelLink} href={PrivacyPdf} target="_blank" rel="noreferrer">
                          {copy.home.newsLetter.check1Link}
                        </a>
                      </Grid>
                    </Grid>
                    <Grid container >
                      <Grid item xs={2} sm={1}>
                        <Checkbox
                          name="flag2"
                          onChange={(e) => setFlag2(e.target.checked)}
                          value={flag2}
                          checkedIcon={<div className={classes.checkedIconLight}>
                            <CheckboxIcon dimension='13px' top='-7px' color='#353535' />
                          </div>}
                          icon={<div className={classes.iconLight} />}
                        />
                      </Grid>
                      <Grid item xs={10} sm={11} className={classes.checkBoxLabel}>
                        {copy.home.newsLetter.check2}
                      </Grid>
                    </Grid>
                  </FormControl>
                </Grid>
                <Grid container item justify='center'>
                  <Button type='submit' id="gatsby-newsLetter" classes={{ root: classes.whiteButton }}>
                    <span id="gatsby-newsLetter-label">{copy.home.footer.submit}</span>
                  </Button>
                </Grid>
              </form>}
          </Grid>
          <Grid container item xs={12} md={6} justify="center" className={classes.mobileMargin}>
            <Grid item xs={6} md={8} className={classes.linkSection}>
              <Grid className={classes.subTitleFooter}>{copy.home.footer.siteMap.title}</Grid>
              {copy.home.footer.siteMap.link.map((item, key) => {
                return (
                  <Grid className={classes.link} key={key} onClick={() => {
                    goToUrl(item.url);
                  }}>
                    - {item.label}
                  </Grid>
                );
              })}
            </Grid>
            <Grid item xs={6} md={8} className={classes.linkSection}>
              <Grid className={classes.subTitleFooter}>{copy.home.footer.cerca.title}</Grid>
              {copy.home.footer.cerca.link.map((item, key) => {
                return (
                  <Grid className={classes.link} key={key} onClick={() => goToUrl(item.url)}>
                    - {item.label}
                  </Grid>
                );
              })}
            </Grid>
            <Grid container item xs={12} md={8} className={classes.linkSection}>
              <Grid item xs={12} className={`${classes.link} iubenda-white no-brand iubenda-noiframe iubenda-embed iubenda-noiframe `}
                title="Cookie Policy "
                onClick={() => goToUrl("https://www.iubenda.com/privacy-policy/32703398/cookie-policy")}
              >
                - Cookie Policy
              </Grid>
              <Grid item xs={12}>
                <Link className={classes.link} href={InformativaClientiPdf} target="_blank" rel="noreferrer"> - Informativa Utente</Link>
              </Grid>
              <Grid item xs={12}>
                <Link className={classes.link} href={PrivacyPdf} target="_blank" rel="noreferrer"> - Privacy Policy</Link>
              </Grid>
              {copy.home.footer.extra.map((item, key) =>
                (
                  <Grid item xs={6} md={12} className={classes.link} key={key} onClick={() => {
                    if (item.extUrl) {
                      window.open(item.extUrl, '_blank');
                    } else {
                      goToUrl(item.url);
                    }
                  }}>
                    - {item.label}
                  </Grid>
                )
              )}
            </Grid>
            <Grid item xs={12} md={8}>
              <Grid className={classes.link} onClick={() => goToUrl(copy.home.footer.workWithUs.url)}>
                - {copy.home.footer.workWithUs.label}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container item xs={12} md={4} direction="column" justify="center" alignItems='center'>
          <Grid container className={classes.instagramContainer + ' ' + classes.titleIg} justify='flex-end'>
            <Grid container>
              <instagram-widget username="@abitareco" grid="3x3" border-corners='0' mouse-hover='type1' />
            </Grid>
            <Grid item style={{ marginRight: '10px' }}>
              @abitareco
            </Grid>
          </Grid>
          <Grid item className={classes.subTitleFooter}>
            {copy.home.newsLetter.follow}
          </Grid>
          <Grid container alignItems='center' justify='center'>
            <Grid onClick={() => window.open("https://www.facebook.com/abitareco/?fref=ts", '_blank')}>
              <FacebookIcon extraClasses={classes.socialIconWithHover + ' ' + classes.facebookHover} />
            </Grid>
            <Grid onClick={() => window.open("https://www.youtube.com/user/AbitareCoMilano", '_blank')}>
              <YoutubeIcon extraClasses={classes.socialIconWithHover + ' ' + classes.youtubeHover} />
            </Grid>
            <Grid onClick={() => window.open("https://www.instagram.com/abitareco/", '_blank')}>
              <InstagramIcon extraClasses={classes.socialIconWithHover + ' ' + classes.instagramHover} />
            </Grid>
            <Grid onClick={() => window.open("https://www.linkedin.com/company/abitare-co./", '_blank')}>
              <LinkedinIcon extraClasses={classes.socialIconWithHover + ' ' + classes.linkedinHover} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container item alignItems='center' justify='center' className={classes.footerCompanyData + ' ' + classes.titleLight}>
        {copy.home.footer.companyData}
      </Grid>
    </Grid>
  )
}

export default withStyles(footerStyle)(Footer)
