import React from 'react'
import lavoraConNoiStyle from "./LavoraConNoiStyle.jsx"
import Header from "../Header/Header"
import withStyles from "@material-ui/core/styles/withStyles"
import Grid from '@material-ui/core/Grid'
import Contact from '../Contact/Contact'
import copy from '../../constants/copy.json'
import Footer from '../Footer/Footer'
import ContactLavoraConNoi from "../Contact/ContactLavoraConNoi"

function formatNumber(classes, e) {
    if(e.numberSmall) {
        return (
            <div className={classes.numberContainer}>
                <div className={classes.bigNumber}>
                    {e.numberBig}
                </div>
                <div className={classes.smallNumber}>
                    .{e.numberSmall} {e.append}
                </div>
            </div>
        );
    }
    else {
        return (
            <div className={classes.numberContainer}>
                <div className={classes.bigNumber}>
                    {e.numberBig}
                </div>
                <div className={classes.smallNumber}>
                    {e.append}
                </div>
            </div>
        );
    }
}

const LavoraConNoi = ({classes}) => {

  return (
    <div style={{height: "100%"}}>
        <Header home={false} />
        <div className={classes.heroContainer}>
            <div className={classes.hero}/>
        </div>

        <div className={classes.firstTextBlock}>
            <div className={classes.heroTextContainer}>
                <div className={`${classes.title} ${classes.hiddenMobile}`}>
                    {copy.lavoraConNoi.title}
                </div>

                <div className={classes.subtitle}>
                    <span>
                        <span className={classes.underlineContent}>
                            {copy.lavoraConNoi.subtitle}
                        </span>
                    </span>
                </div>

                <div className={classes.description1}>
                    {copy.lavoraConNoi.description}
                </div>
            </div>
        </div>

        

        <Grid container className={classes.numberBlockContainer}>
            <Grid container  className={classes.titleNumberSection}>
                {copy.lavoraConNoi.numberBlock.title}
            </Grid>
            
            <Grid container className={classes.blocksContainer}>
                {copy.lavoraConNoi.numberBlock.blocks.map((e, i) => (
                    <Grid item key={i} className={classes.singleNumberBlock} xs={i === 0 ? 12 : 6} md={3} >

                        <div className={classes.singleNumberBlockNumber}>
                            {formatNumber(classes, e)}
                        </div>
                        
                        <div className={classes.singleNumberBlockTitle} dangerouslySetInnerHTML={{__html: e.title.concat(``)}}>
                        </div>
                    </Grid>
                ))}
            </Grid>

            <Grid container className={classes.description2}>
                {copy.lavoraConNoi.numberBlock.description}
            </Grid>
        </Grid>

        <ContactLavoraConNoi></ContactLavoraConNoi>

        <Contact />        
        
        <Footer/>
    </div>
  )
}

export default withStyles(lavoraConNoiStyle)(LavoraConNoi)
