import React from 'react';
import SvgIcon from "@material-ui/core/SvgIcon";

const Linkedin = (props) => (
  <SvgIcon {...props}>    
    <svg version="1.1" id="Livello_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
      viewBox="0 0 35.2 30" style={{enableBackground: 'new 0 0 35.2 30'}}>
    <path id="Path_2525" class="st0" d="M33.5,28.1c0.3,0,0.6-0.2,0.6-0.5c0,0,0,0,0,0l0,0c0-0.4-0.3-0.6-0.8-0.6h-0.8v2.2h0.3v-1h0.4
      l0,0l0.6,0.9h0.3L33.5,28.1L33.5,28.1z M33.1,27.9h-0.4v-0.7h0.5c0.2,0,0.5,0,0.5,0.4S33.5,27.9,33.1,27.9"/>
    <path id="Path_2520" class="st0" d="M25.4,25.4H21v-6.9c0-1.6,0-3.8-2.3-3.8S16,16.5,16,18.4v7h-4.4V11.2h4.2v1.9h0.1
      c0.9-1.5,2.5-2.4,4.2-2.3c4.5,0,5.3,2.9,5.3,6.8L25.4,25.4z M6.6,9.2c-1.4,0-2.6-1.1-2.6-2.6s1.1-2.6,2.6-2.6s2.6,1.1,2.6,2.6l0,0
      C9.2,8.1,8.1,9.2,6.6,9.2C6.6,9.2,6.6,9.2,6.6,9.2L6.6,9.2 M8.8,25.4H4.4V11.2h4.4V25.4z M27.6,0H2.2C1,0,0,0.9,0,2.2v25.5
      c0,1.2,1,2.2,2.2,2.1h25.4c1.2,0,2.2-0.9,2.2-2.1V2.2C29.8,0.9,28.8,0,27.6,0C27.6,0,27.6,0,27.6,0"/>
    <path id="Path_2526" class="st0" d="M33.2,25.9c-1.1,0-2,0.9-2,2s0.9,2,2,2s2-0.9,2-2S34.3,25.9,33.2,25.9L33.2,25.9 M33.2,29.8
      c-1,0-1.8-0.8-1.8-1.8c0,0,0,0,0,0c0-1,0.8-1.8,1.8-1.8c1,0,1.8,0.8,1.8,1.8l0,0C35,29,34.2,29.8,33.2,29.8
      C33.2,29.8,33.2,29.8,33.2,29.8L33.2,29.8"/>
    </svg>
  </SvgIcon>
);

const LinkedinIcon = ({extraClasses}) => {
  
  return (
  <div>
    <Linkedin className={extraClasses} />
  </div>
)};

export default LinkedinIcon;